import cx from 'classnames';
import { capitalize } from 'lodash';

import type { ChatFeedbackType } from 'frontend/api/generated';
import { EmojiPlus, TextCapitalize } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';

import styles from './FeedbackSentiment.scss';

const FeedbackSentiment = ({
  data,
  className,
  showFreeformText = true,
}: {
  data: ChatFeedbackType | ChatFeedbackType[];
  className?: string;
  showFreeformText?: boolean;
}) => {
  const renderFeedback = () => (
    <div className={cx(styles.feedbackBadge, styles.feedbackBadgeExtraRightPadding)}>
      <Icon component={EmojiPlus} width={18} height={18} />
      {Array.isArray(data)
        ? data.map(({ sentiment }) => capitalize(sentiment as string)).join(' & ')
        : capitalize(data.sentiment || '')}
    </div>
  );
  const hasFreeformText = Array.isArray(data) ? data?.some(({ freeformText }) => freeformText) : data.freeformText;

  return (
    <div className={cx(styles.feedbackWrapper, className)}>
      {renderFeedback()}
      {hasFreeformText && showFreeformText && (
        <div className={styles.feedbackBadge}>
          <Icon color="darkGray" component={TextCapitalize} width={18} height={18} />
        </div>
      )}
    </div>
  );
};

export default FeedbackSentiment;
