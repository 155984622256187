import { useDispatch } from 'react-redux';

import { useToast } from 'frontend/hooks/index';
import { getAccessToken } from 'frontend/state/dux/auth';
import { upload } from 'frontend/utils';

export default function useUpload(uploadPath, extra = {}) {
  const dispatch = useDispatch();
  const toast = useToast();

  const handleUpload = async (file, fileExtra?) => {
    if (!file) {
      return;
    }

    // @ts-expect-error: FIXME: type dispatch stuff correctly
    const accessToken: string = await dispatch(getAccessToken());
    const response = await upload(uploadPath, accessToken, file, { ...extra, ...fileExtra });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
  };

  return async (source, fileExtra?) => {
    try {
      await handleUpload(source, fileExtra);
    } catch (err) {
      toast.error(`Could not upload file. ${err ? err.toString() : ''}`);
    }
  };
}
