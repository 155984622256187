import React from 'react';

import { SortAscending, SortDescending } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { setInsightsConversationsSort } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import styles from './SortChats.scss';

const SortChats = () => {
  const { sort } = useAppSelector((state) => state.insights);
  const dispatch = useAppDispatch();

  const icon = sort === 'ASC' ? SortAscending : SortDescending;

  return (
    <div className={styles.insightsSortChats}>
      <Icon
        width={20}
        height={20}
        hoverColor="darken"
        color="darkGray"
        title={sort === 'ASC' ? 'Sort by oldest' : 'Sort by newest'}
        component={icon}
        onClick={() => dispatch(setInsightsConversationsSort())}
      />
    </div>
  );
};

export default SortChats;
