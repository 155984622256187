import cx from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Close, Eye, Search } from 'frontend/assets/icons';
import { Button, Dropdown, Icon, Input } from 'frontend/components';
import Rolling from 'frontend/components/Loader/Rolling';
import { FIELD_COLOR } from 'frontend/constants';
import { useTabTitle, useUrlSearchParams } from 'frontend/hooks';
import { setInsightsSelectedChat } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import styles from './Conversations.scss';
import Filters from '../../../../components/NewFilters/Filters';
import { MINIMUM_CHARACTERS_BEFORE_SEARCH } from '../../utils/constants';
import ChatContainer from '../ChatContainer/ChatContainer';
import ConversationList from '../ConversationList/ConversationList';
import SearchUIFilter from '../SearchUIFilter/SearchUIFilter';
import SortChats from '../SortChats/SortChats';

// Define the debounced function outside the component
const debouncedFunction = debounce((callback) => callback(), 700);

const Conversations = () => {
  useTabTitle('Insights - Conversations');
  const [params, setParams, unsetParams] = useUrlSearchParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const dispatch = useAppDispatch();
  const { chatsLoading } = useAppSelector((state) => state.insights);

  const debouncedSearch = useCallback(
    (e, value) => {
      debouncedFunction(() => {
        if (e?.target?.value || value) {
          if (value && inputRef.current) {
            inputRef.current.value = value;
          }
          const currentHash = window.location.hash;
          setParams({ search: value || e.target.value });
          // If there is a hash, we need to set it back after setting the search param
          // since setParams removes the hash
          if (currentHash) {
            window.location.hash = currentHash;
          }
        } else {
          unsetParams(['search']);
        }
      });
    },
    [setParams, unsetParams],
  );

  const clearSearch = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      unsetParams(['search']);
    }
  }, [unsetParams]);

  useEffect(
    () => () => {
      dispatch(setInsightsSelectedChat(null));
    },
    [dispatch],
  );

  const onScrolled = useCallback((scrolled) => setHasScrolled(scrolled), []);

  return (
    <div className={styles.insightsWrapper}>
      <Filters path="INSIGHTS_CHATS" className={styles.insightsFilter} />
      <div className={styles.insightsConversationWrapper}>
        <div className={styles.insightsConversationListWrapper}>
          <div
            className={cx(styles.insightsConversationSearchWrapper, {
              [styles.insightsConversationSearchWrapperScrolled]: hasScrolled,
            })}
          >
            <div className={styles.insightsConversationSearchInput}>
              <Input
                fieldColor={FIELD_COLOR.MISCHKA}
                input={{ onChange: debouncedSearch, defaultValue: params.search }}
                className={styles.search}
                placeholder="Search"
                ref={inputRef}
                aria-label="Search chats"
                adornment={<Icon component={Search} />}
                adornmentPosition="left"
                autoFocus
              />
              {(params.search?.length < MINIMUM_CHARACTERS_BEFORE_SEARCH ||
                (params.search?.length >= MINIMUM_CHARACTERS_BEFORE_SEARCH && chatsLoading)) && (
                <div className={styles.searchLoader}>
                  <Rolling step={params.search.length} stroke="var(--grayscale60)" />
                </div>
              )}
              {params.search && (
                <Icon
                  className={styles.insightsConversationSearchInputClose}
                  component={Close}
                  onClick={() => {
                    clearSearch();
                  }}
                />
              )}
            </div>
            <SortChats />
            <Dropdown
              element={Button}
              data-testid="search-ui-elements"
              elementProps={{
                color: 'white',
                size: 'small',
                icon: Eye,
              }}
              triggerClassName={styles.trigger}
              overlayClassName={styles.dropdown}
              position="bottom-left"
              title="Filter search UI"
              outsideClick="pointerdown"
              overlay={<SearchUIFilter />}
            />
          </div>
          <ConversationList clearSearch={clearSearch} onScrolled={onScrolled} debouncedSearch={debouncedSearch} />
        </div>
        <div className={styles.insightsConversationInfoWrapper}>
          <ChatContainer />
        </div>
      </div>
    </div>
  );
};

export default Conversations;
