import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { EditorState, LexicalEditor } from 'lexical';
import { useEffect } from 'react';

interface Props {
  handleChange: (editorState: EditorState, editor: LexicalEditor) => void;
  componentRef: React.RefObject<HTMLDivElement>;
}

export default function OnChangePlugin({ handleChange, componentRef }: Props): null {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerUpdateListener(({ editorState }) => {
        // Don't fire updates if the editor is not mounted, e.g. when the component unmounts
        if (!componentRef?.current) return;

        editorState.read(() => {
          handleChange(editorState, editor);
        });
      }),
    [editor, componentRef, handleChange],
  );

  return null;
}
