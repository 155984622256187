import { identity } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import type { LibraryDialogue } from 'frontend/features/Library/components/DialogueItem/DialogueItem';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { sortDialogues } from '../../utils';
import DialogueItem from '../DialogueItem';

type Props = {
  dialogues: LibraryDialogue[];
  selectedLanguage: string;
  buildIdObject: BuildIdObject;
  skillId?: string;
  parentDisabled?: boolean;
  parentId?: string;
  sorting?: (dialogues: LibraryDialogue[], language: string) => LibraryDialogue[];
  indentationLevel: number;
  keyPostfix?: string;
  hideFollowups?: boolean;
};

function DialogueList({
  dialogues,
  selectedLanguage,
  buildIdObject,
  skillId,
  parentDisabled,
  parentId,
  indentationLevel,
  sorting = sortDialogues,
  keyPostfix = '',
  hideFollowups = false,
}: Props) {
  const sorted = useMemo(
    () => sorting(dialogues ?? [], selectedLanguage).filter(identity),
    [selectedLanguage, dialogues, sorting],
  );

  const { botId } = useParams();
  const { botSettings } = useBotSettings(botId);
  const intentEnabled = botSettings?.intentMatchEnabled;

  return sorted
    .filter((d) => (intentEnabled ? d.dialogueType !== 'SAMPLES' : d.dialogueType !== 'INTENT'))
    .filter(parentId === 'BOTTOM_LEVEL' ? Boolean : (d) => d.parentId === (parentId || null))
    .map((dialogue) => (
      <DialogueItem
        key={`library-${dialogue.dialogueType}-${dialogue.id}${keyPostfix}`}
        dialogue={dialogue}
        buildIdObject={buildIdObject}
        skillId={skillId}
        parentDisabled={parentDisabled}
        indentationLevel={indentationLevel}
        hideFollowups={hideFollowups}
      />
    ));
}

export default DialogueList;
