import cx from 'classnames';
import React from 'react';

import styles from './BuildItemTitle.scss';

interface BuildItemTitleProps {
  title: string;
  description?: string;
  titleTextSize?: 'small' | 'medium' | 'large';
  titleRightAdornment?: React.ReactNode;
  className?: string;
}

const BuildItemTitle = ({ title, description, titleTextSize, titleRightAdornment, className }: BuildItemTitleProps) => (
  <div className={cx(styles.buildItemDescriptionWrapper, className)}>
    <div
      className={cx(styles.title, {
        [styles.textSizeSmall]: titleTextSize === 'small',
        [styles.textSizeLarge]: titleTextSize === 'large',
      })}
    >
      {title}
      {titleRightAdornment && <div className={styles.rightAdornment}>{titleRightAdornment}</div>}
    </div>
    {description && <div className={styles.description}>{description}</div>}
  </div>
);

export default BuildItemTitle;
