import { useQuery } from '@apollo/client';
import React, { type Dispatch, type SetStateAction } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import type { SelectSearchOption } from 'react-select-search';

import { DialogueListDocument } from 'frontend/api/generated';
import imagePlaceholder from 'frontend/assets/images/image-placeholder.png';
import { FileUpload, Input, Select, SelectDialogue, SelectWithSearch, UrlInput } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { ModalForm } from 'frontend/features/Modals';
import { useNudges } from 'frontend/features/NudgeEditor/hooks';
import { chain, max, required } from 'frontend/form/validators';
import type { UploadFileType } from 'frontend/hooks/useOnSubmitWithUpload';
import type { PartialDialogue } from 'frontend/types/dialogue';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './GlobalIconModal.scss';

type setFileType = Dispatch<SetStateAction<UploadFileType>> | ((...args: any[]) => Promise<any>) | undefined;

type GlobalIconModalListType = {
  botId: string;
};

enum GlobalIconButtonTypeEnum {
  URL = 'URL',
  DIALOGUE = 'DIALOGUE',
  NUDGE = 'NUDGE',
}

const GlobalIconsModalNudgesList = ({ botId }: GlobalIconModalListType) => {
  const { nudges, currentLanguage } = useNudges(botId);
  return (
    <Field
      name="buttonAction"
      component={SelectWithSearch}
      className={styles.selectWithSearch}
      placeholder="Select nudge"
      required={required}
      options={
        nudges.map((nudge) => ({
          name: nudge.title[currentLanguage] || nudge.slug,
          value: nudge.slug,
        })) as SelectSearchOption[]
      }
    />
  );
};

const GlobalIconsModalDialoguesList = ({ botId }: GlobalIconModalListType) => {
  const { data, loading } = useQuery(DialogueListDocument, {
    variables: { botId, includeSubscribedSkills: true, excludeGreetings: true },
  });

  const dialogues: PartialDialogue[] = data?.dialogueList ?? [];
  return (
    <Field
      name="buttonAction"
      dialogues={dialogues}
      loading={loading}
      required={required}
      placeholder="Select dialogue"
      className={styles.selectWithSearch}
      component={SelectDialogue}
    />
  );
};

const GlobalIconModalContents = ({
  setFile,
  tempId,
  globalIconId,
  botId,
}: {
  setFile: setFileType;
  tempId: string;
  globalIconId?: string;
  botId: string;
}) => {
  const { change } = useForm();
  const { input: imgField } = useField('imageUrl');
  const { input: buttonTypeField } = useField('buttonType');
  const { input: buttonActionField } = useField('buttonAction');

  const handleChange = (url: string) => {
    const fakeEvent = {
      target: {
        name: 'buttonAction',
        value: url,
      },
    } as React.ChangeEvent<HTMLSelectElement>;

    change(fakeEvent.target.name, fakeEvent.target.value);
  };

  return (
    <>
      <Field
        label="Text label"
        inputLimit={20}
        validate={chain([required, max(20)])}
        name="title"
        type="text"
        placeholder="Give you icon a title"
        component={Input}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <div className={styles.globalIconModalImageWrapper}>
        <div className={styles.globalIconModalImageContainer}>
          <div className={styles.globalIconModalImage}>
            <img src={imgField.value || imagePlaceholder} alt="upload icon" />
          </div>
          <Field name="imageUrl" type="hidden" render={({ input }) => <input type="hidden" {...input} />} />
          <FileUpload
            color="white"
            buttonClassName={styles.globalIconModalBtn}
            text="Upload picture"
            accept="image/png,image/jpeg"
            onUpload={(data) => {
              if (data?.[0]) {
                const [{ source, file }] = data;
                change('imageUrl', source);
                setFile?.((current) => {
                  if (Array.isArray(current)) {
                    const index = current.findIndex((cur) => tempId === cur.extra.tempId);
                    if (index >= 0) {
                      current.splice(index, 1);
                    }
                  }
                  return [
                    ...((current as { extra: object; file: Blob }[]) || []),
                    { file, extra: { tempId, global_icon_id: globalIconId } },
                  ];
                });
              }
            }}
          />
        </div>
        <div className={styles.globalIconModalImageDescription}>
          Images will be cropped to a square 1:1 ratio. <br /> File size: max 2 MB. File type: .jpg, .jpeg, .png, .gif
        </div>
      </div>
      {imgField.value && (
        <Field
          label="Alt text"
          name="altText"
          type="text"
          placeholder="Describe the image (for screen readers)"
          component={Input}
          validate={required}
          fieldColor={FIELD_COLOR.MISCHKA}
        />
      )}
      <Field label="Button action" component={Select} name="buttonType" fieldColor={FIELD_COLOR.MISCHKA}>
        <Select.Option value={GlobalIconButtonTypeEnum.URL} label="External link" />
        <Select.Option value={GlobalIconButtonTypeEnum.DIALOGUE} label="Dialogue" />
        <Select.Option value={GlobalIconButtonTypeEnum.NUDGE} label="Nudge" />
      </Field>
      <div className={styles.globalIconBtnAction}>
        {buttonTypeField.value === GlobalIconButtonTypeEnum.URL && (
          <Field
            name="buttonAction"
            placeholder="website.com"
            validate={(val) => {
              const regex = /http?[s]:\/\//g;
              return required(val.replace(regex, ''));
            }}
            input={{
              value: buttonActionField.value,
              onChange: handleChange,
              onBlur: () => {},
              onFocus: () => {},
            }}
            component={UrlInput}
          />
        )}
        {buttonTypeField.value === GlobalIconButtonTypeEnum.DIALOGUE && <GlobalIconsModalDialoguesList botId={botId} />}
        {buttonTypeField.value === GlobalIconButtonTypeEnum.NUDGE && <GlobalIconsModalNudgesList botId={botId} />}
      </div>
    </>
  );
};

interface GlobalIconModalProps {
  args: {
    setFile: setFileType;
    onSubmit: (values: any) => void;
    botId: string;
    globalIconId?: string;
    initialValues: {
      id?: string;
      title?: string;
      altText?: string;
      tempId?: string;
      buttonAction?: string;
      buttonType?: string;
    };
  };
  hide: () => unknown;
}

const GlobalIconModal = ({
  args: { initialValues, onSubmit, setFile, botId, globalIconId },
  hide,
}: GlobalIconModalProps) => {
  const tempId = randomUUID();

  return (
    <ModalForm
      title="New icon"
      hide={hide}
      showFormErrors={false}
      initialValues={{
        tempId,
        buttonType: GlobalIconButtonTypeEnum.URL,
        buttonAction: 'https://',
        ...initialValues,
      }}
      onCancel={() => {
        setFile?.((current) => {
          if (Array.isArray(current)) {
            const index = current.findIndex((cur) => tempId === cur.extra.tempId);
            if (index >= 0) {
              current.splice(index, 1);
            }
          }
          return current;
        });
        hide();
      }}
      onSubmit={onSubmit}
    >
      <div className={styles.globalIconModalWrapper}>
        <GlobalIconModalContents globalIconId={globalIconId} tempId={tempId} setFile={setFile} botId={botId} />
      </div>
    </ModalForm>
  );
};

export default GlobalIconModal;
