import type { InitParams } from '@greatsumini/react-facebook-login';

import { addListItemByFragment, removeListItemById } from 'frontend/api/cacheHelpers';
import { type ConnectFacebookPageMutation, FacebookPageFragmentDoc } from 'frontend/api/generated';

export function addPageToCache(
  { cache },
  botId: string,
  facebookPage: ConnectFacebookPageMutation['connectFacebookPage'],
) {
  cache.modify({
    id: `BotType:${botId}`,
    fields: {
      facebookPages: addListItemByFragment(cache, FacebookPageFragmentDoc, facebookPage),
    },
  });
}

export function removePageFromCache({ cache }, botId: string, facebookPageId: string) {
  cache.modify({
    id: `BotType:${botId}`,
    fields: {
      facebookPages: removeListItemById(facebookPageId),
    },
  });
}

type MetaAPIVersion = NonNullable<InitParams['version']>;
const defaultAPIVersion = 'v20.0' as MetaAPIVersion;
export const META_GRAPH_API_VERSION: MetaAPIVersion =
  (window.env.META_GRAPH_API_VERSION as MetaAPIVersion) || defaultAPIVersion;
