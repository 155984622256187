import cx from 'classnames';

import DIALOGUE_TYPES, { type DialogueType } from 'frontend/constants/dialogueTypes';
import {
  DEFAULT_DIALOGUE_LABEL_COLOR,
  DIALOGUE_LABEL_COLORS,
  type LabelColor,
} from 'frontend/features/Library/constants';

import Annulus from './Annulus';
import Circle from './Circle';
import ConnectedCircles from './ConnectedCircles';
import styles from './DialogueIcon.scss';
import Diamond from './Diamond';
import FourCircles from './FourCircles';

interface Props {
  dialogueType?: DialogueType;
  colorLabel?: LabelColor;
  currentLanguage?: string;
  title?: string;
  className?: string;
}

const getIcon = (dialogueType) =>
  ({
    [DIALOGUE_TYPES.SAMPLES]: Circle,
    [DIALOGUE_TYPES.KEYWORDS]: Annulus,
    [DIALOGUE_TYPES.PATTERNS]: FourCircles,
    [DIALOGUE_TYPES.TRIGGER]: ConnectedCircles,
  })[dialogueType] || Diamond;

const getColor = (currentLanguage, colorLabel) =>
  DIALOGUE_LABEL_COLORS.get(colorLabel?.[currentLanguage]) ?? DEFAULT_DIALOGUE_LABEL_COLOR;

const DialogueIcon = ({ dialogueType, colorLabel, currentLanguage, title, className }: Props) => {
  const Icon = getIcon(dialogueType);
  const color = getColor(currentLanguage, colorLabel);

  return (
    <span className={cx(className, styles.wrapper)}>
      <Icon color={color} title={title} />
    </span>
  );
};
export default DialogueIcon;
