import { uniq } from 'lodash';
import React, { Fragment } from 'react';
import { useField, useForm } from 'react-final-form';

import { Checkbox, ToggleSwitch } from 'frontend/components';
import { useGroupedSourcesFilter } from 'frontend/hooks';

import styles from './SourcesFilter.scss';

interface SourcesFilterProps {
  sourceKey: string;
  shouldSubmitOnToggle: boolean;
}

const SourcesFilter = ({ sourceKey, shouldSubmitOnToggle }: SourcesFilterProps) => {
  const { change, submit } = useForm();

  const {
    input: { value: sourceFilters },
  } = useField(sourceKey);

  const sourceFiltersCopy = Array.isArray(sourceFilters) ? [...sourceFilters] : [];

  const { sourcesGroupChecked, setSourcesGroupChecked, manageSourceHeaders, channelsLoading, sourceData } =
    useGroupedSourcesFilter({
      sourceFilters: sourceFiltersCopy,
    });

  const updateFilterValue = (value) => {
    let valueCopy = sourceFiltersCopy;
    if (Array.isArray(value)) {
      if (!value.every(({ value: val }) => valueCopy.includes(val))) {
        valueCopy = uniq([...valueCopy, ...value.map(({ value: val }) => val)]);
      } else {
        value.forEach(({ value: val }) => {
          valueCopy.splice(valueCopy.indexOf(val), 1);
        });
      }
    } else if (valueCopy.includes(value)) {
      valueCopy.splice(valueCopy.indexOf(value), 1);
    } else {
      valueCopy.push(value);
    }

    change(sourceKey, valueCopy.length ? valueCopy : undefined);

    if (shouldSubmitOnToggle) {
      submit();
    }
  };

  const handleFilterChange = (value) => {
    updateFilterValue(value);
    manageSourceHeaders(value);
  };

  const handleChannelFilterCategoryClick = (source) => {
    setSourcesGroupChecked((current) => {
      if (Array.isArray(source.value)) {
        if (current.includes(source.label)) {
          return current.filter((currentSource) => currentSource !== source.label);
        }
      }

      return [...current, source.label];
    });

    updateFilterValue(source.value);
  };

  const renderChannel = () =>
    sourceData.map((source) => {
      if (Array.isArray(source.value)) {
        return (
          <Fragment key={source.label}>
            <Checkbox
              key={source.label}
              allowToggleOnWrapper
              className={styles.checkboxOption}
              input={{
                name: sourceKey,
                value: source.label,
                checked: sourcesGroupChecked.includes(source.label),
              }}
              onClick={() => handleChannelFilterCategoryClick(source)}
              label={source.label}
            />
            <div className={styles.categorySourceWrapper}>
              {source.value.map((subSource) => (
                <Checkbox
                  className={styles.checkboxSubOption}
                  allowToggleOnWrapper
                  key={subSource.value}
                  input={{
                    name: sourceKey,
                    value: subSource.value,
                    checked: sourceFiltersCopy.includes(subSource.value),
                  }}
                  onClick={() => handleFilterChange(subSource.value)}
                  label={subSource.label}
                />
              ))}
            </div>
          </Fragment>
        );
      }

      // Exception for 'test' source type, which is a ToggleSwitch.
      if (source.value === 'test') {
        return (
          <>
            <ToggleSwitch.Switch
              key={sourceKey}
              input={{ value: sourceFiltersCopy.includes(source.value), name: source.label }}
              onClick={() => handleFilterChange(source.value)}
              status="Include platform tests"
            />
            <p className={styles.platformTestExplanation}>
              Platform test is any session conducted within the Kindly platform, excluding the demo page
            </p>
          </>
        );
      }

      return (
        <Checkbox
          key={source.value}
          input={{
            name: sourceKey,
            checked: sourceFiltersCopy.includes(source.value),
            value: source.value,
          }}
          allowToggleOnWrapper
          className={styles.checkboxOption}
          label={source.label}
          id={source.label}
          onClick={handleFilterChange}
        />
      );
    });

  if (channelsLoading) return null;

  return renderChannel();
};

export default SourcesFilter;
