import type { MessageType } from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';

import { systemDialogueMessageTypes } from '../constants';

const { DELETE_CHAT, FALLBACK, REPLY, START } = systemDialogueMessageTypes;

export default ({ dialogueType, messageType }: { dialogueType: string; messageType?: MessageType | null }) =>
  dialogueType !== DIALOGUE_TYPES.SYSTEM_DIALOGUE ||
  !messageType ||
  !([FALLBACK, DELETE_CHAT, REPLY, START] as string[]).includes(messageType);
