import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  Aerial,
  Agent,
  Email,
  EmojiHappy,
  Labels,
  Nudge,
  Robot,
  SpeechBubble,
  TrendingUp,
  UserCheck,
} from 'frontend/assets/icons';
import SubNav from 'frontend/features/SubNav/SubNav';
import { useLinkTo } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './NewAnalytics.scss';
import AnalyticsNavLink from './components/AnalyticsNavLink';
import { EmbeddedAnalytics } from '../../components';
import { initialAnalyticsFilter } from '../../components/EmbeddedAnalytics/EmbeddedAnalytics';
import { LUZMO_DASHBOARDS } from '../../components/EmbeddedAnalytics/constants';

const NewAnalytics = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { botId } = useParams();

  const { isKindlyAdminManager } = useMyPermissions({ botId });

  const currentRouteSlug = pathname.split('/').pop();

  const [searchParams, setSearchParams] = useSearchParams();

  const areDashboardQueryParamsGroupEqual = (currentSlug: string, nextSlug: string): boolean => {
    let currSlugQueryGroup: unknown;
    let nextSlugQueryGroup: unknown;

    Object.values(LUZMO_DASHBOARDS).forEach((dashboard) => {
      if (dashboard.route === currentSlug) {
        currSlugQueryGroup = dashboard.queryParamsGroup;
      }
      if (dashboard.route === nextSlug) {
        nextSlugQueryGroup = dashboard.queryParamsGroup;
      }
    });

    // If at least one of the slugs is a dashboard, we compare the `queryParamsGroup`
    if (currSlugQueryGroup || nextSlugQueryGroup) {
      return currSlugQueryGroup === nextSlugQueryGroup;
    }

    return false;
  };

  useEffect(() => {
    // On analytics page we want to default the filters to initialAnalyticsFilter
    if (!searchParams.has('dateFilter')) {
      setSearchParams(
        Object.entries(initialAnalyticsFilter).reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {}),
      );
    }
  }, [searchParams, setSearchParams, currentRouteSlug]);

  const NEW_ANALYTICS_MENU_ITEMS: {
    name: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    slug: string;
    className?: string;
    hide?: boolean;
    keepQuery?: boolean;
    queryParams?: string;
  }[] = [
    {
      name: 'Bot Dashboard',
      icon: SpeechBubble,
      slug: LUZMO_DASHBOARDS.BOT_DASHBOARD.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.BOT_DASHBOARD.route) && {
        keepQuery: true,
      }),
    },
    {
      name: 'Containment rate',
      icon: Aerial,
      slug: LUZMO_DASHBOARDS.CONTAINMENT_RATE.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.CONTAINMENT_RATE.route) && {
        keepQuery: true,
      }),
    },
    {
      name: 'Behavior',
      icon: Agent,
      slug: LUZMO_DASHBOARDS.BEHAVIOR.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.BEHAVIOR.route) && { keepQuery: true }),
    },
    {
      name: 'Labels',
      icon: Labels,
      slug: LUZMO_DASHBOARDS.LABELS.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.LABELS.route) && { keepQuery: true }),
    },
    {
      name: 'Usage',
      icon: TrendingUp,
      slug: LUZMO_DASHBOARDS.USAGE.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.USAGE.route) && { keepQuery: true }),
    },
    {
      name: 'Kindly GPT',
      icon: Robot,
      slug: LUZMO_DASHBOARDS.KINDLY_GPT.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.KINDLY_GPT.route) && {
        keepQuery: true,
      }),
    },
    {
      name: 'Authenticated',
      icon: UserCheck,
      slug: LUZMO_DASHBOARDS.AUTHENTICATED.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.AUTHENTICATED.route) && {
        keepQuery: true,
      }),
    },
    {
      name: 'Bot feedback',
      icon: EmojiHappy,
      slug: LUZMO_DASHBOARDS.BOT_FEEDBACK.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.BOT_FEEDBACK.route) && {
        keepQuery: true,
      }),
    },
    {
      name: 'Handover feedback',
      icon: EmojiHappy,
      slug: LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.route,
      ...(areDashboardQueryParamsGroupEqual(currentRouteSlug!, LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.route) && {
        keepQuery: true,
      }),
    },

    {
      name: 'Email Overview',
      icon: Email,
      slug: LUZMO_DASHBOARDS.EMAIL_OVERVIEW.route,
      className: 'kindlyAdminManager',
      hide: !isKindlyAdminManager,
    },
    {
      name: 'Nudge Overview',
      icon: Nudge,
      slug: LUZMO_DASHBOARDS.NUDGE_OVERVIEW.route,
      className: 'kindlyAdminManager',
      hide: !isKindlyAdminManager,
    },
  ];

  const defaultTo = useLinkTo(NEW_ANALYTICS_MENU_ITEMS[0]?.slug, {
    keepQuery: !!NEW_ANALYTICS_MENU_ITEMS[0]?.keepQuery,
    queryParams: NEW_ANALYTICS_MENU_ITEMS[0]?.queryParams,
  });

  const handleNudgeRowClick = () => {
    navigate(LUZMO_DASHBOARDS.NUDGE_DETAILS.route);
  };

  const handleEmailRowClick = () => {
    navigate(LUZMO_DASHBOARDS.EMAIL_DETAILS.route);
  };

  return (
    <div className={styles.newAnalyticsWrapper}>
      <div className={styles.newAnalyticsMenu}>
        <SubNav>
          {NEW_ANALYTICS_MENU_ITEMS.map(
            ({ name, icon, slug, className, hide, keepQuery, queryParams }) =>
              !hide && (
                <AnalyticsNavLink
                  key={slug}
                  title={name}
                  icon={icon}
                  slug={slug}
                  className={className}
                  keepQuery={keepQuery}
                  queryParams={queryParams}
                />
              ),
          )}
        </SubNav>
      </div>
      <div className={styles.newAnalyticsContentWrapper}>
        <Routes>
          <Route
            path={LUZMO_DASHBOARDS.BOT_DASHBOARD.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.BOT_DASHBOARD.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.BOT_DASHBOARD.slug}
                dashboardId={LUZMO_DASHBOARDS.BOT_DASHBOARD.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.CONTAINMENT_RATE.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.CONTAINMENT_RATE.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.CONTAINMENT_RATE.slug}
                dashboardId={LUZMO_DASHBOARDS.CONTAINMENT_RATE.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.BEHAVIOR.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.BEHAVIOR.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.BEHAVIOR.slug}
                dashboardId={LUZMO_DASHBOARDS.BEHAVIOR.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.USAGE.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.USAGE.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.USAGE.slug}
                dashboardId={LUZMO_DASHBOARDS.USAGE.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.LABELS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.LABELS.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.LABELS.slug}
                dashboardId={LUZMO_DASHBOARDS.LABELS.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.KINDLY_GPT.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.KINDLY_GPT.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.KINDLY_GPT.slug}
                dashboardId={LUZMO_DASHBOARDS.KINDLY_GPT.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.AUTHENTICATED.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.AUTHENTICATED.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.AUTHENTICATED.slug}
                dashboardId={LUZMO_DASHBOARDS.AUTHENTICATED.luzmoId}
              />
            }
          />
          <Route
            key={LUZMO_DASHBOARDS.BOT_FEEDBACK.slug}
            path={LUZMO_DASHBOARDS.BOT_FEEDBACK.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.BOT_FEEDBACK.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.BOT_FEEDBACK.slug}
                dashboardId={LUZMO_DASHBOARDS.BOT_FEEDBACK.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.slug}
                integrationLevel="bot"
                dashboardSlug={LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.slug}
                dashboardId={LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.luzmoId}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.slug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.slug}
                dashboardId={LUZMO_DASHBOARDS.EMAIL_OVERVIEW.luzmoId}
                shouldDisplayFilters={false}
                customEvent={handleEmailRowClick}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.EMAIL_DETAILS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.EMAIL_DETAILS.slug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.EMAIL_DETAILS.slug}
                dashboardId={LUZMO_DASHBOARDS.EMAIL_DETAILS.luzmoId}
                shouldDisplayFilters={false}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.slug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.slug}
                dashboardId={LUZMO_DASHBOARDS.NUDGE_OVERVIEW.luzmoId}
                shouldDisplayFilters={false}
                customEvent={handleNudgeRowClick}
              />
            }
          />
          <Route
            path={LUZMO_DASHBOARDS.NUDGE_DETAILS.route}
            element={
              <EmbeddedAnalytics
                key={LUZMO_DASHBOARDS.NUDGE_DETAILS.slug}
                integrationLevel="engagement"
                dashboardSlug={LUZMO_DASHBOARDS.NUDGE_DETAILS.slug}
                dashboardId={LUZMO_DASHBOARDS.NUDGE_DETAILS.luzmoId}
                shouldDisplayFilters={false}
              />
            }
          />
          <Route path="/" element={<Navigate to={defaultTo} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default NewAnalytics;
