import type { DocumentNode } from 'graphql';

import updateItemInCache from './updateItemInCache';

export default ({
    fragment,
    typeName,
    mutationName,
    fragmentName,
  }: {
    fragment: DocumentNode;
    typeName: string;
    mutationName: string;
    fragmentName: string;
  }) =>
  (cache, result) => {
    const data = result?.data;
    const { id } = data[mutationName];
    const update = (dataInCache) => ({ ...dataInCache, id: null });

    updateItemInCache({ fragment, typeName, fragmentName, update, cache, id, variables: { id } });
  };
