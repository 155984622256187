import { useApolloClient, useMutation } from '@apollo/client';
import FacebookLogin, { type SuccessResponse } from '@greatsumini/react-facebook-login';
import { useCallback } from 'react';

import { DisconnectFacebookPageDocument, type FacebookPageType } from 'frontend/api/generated';
import {
  APP_ID,
  SCOPE,
} from 'frontend/features/Connect/ChatClients/components/FacebookMessenger/components/ConnectButton';
import { useToast } from 'frontend/hooks';

import LoadingButton from './LoadingButton';
import RetryButton from './RetryButton';
import { META_GRAPH_API_VERSION, removePageFromCache } from '../utils';

type Props = {
  setRetryDisconnect: React.Dispatch<React.SetStateAction<boolean>>;
  botId: string;
  facebookPage: Partial<FacebookPageType>;
};

function RetryDisconnect({ setRetryDisconnect, botId, facebookPage }: Props) {
  const client = useApolloClient();
  const toast = useToast();
  const [disconnect] = useMutation(DisconnectFacebookPageDocument);

  const retryDisconnect = useCallback(async () => {
    const { data } = await disconnect({ variables: { pageId: facebookPage.id!, botId } });

    const { error } = data?.disconnectFacebookPage as { error?: string };
    if (error) {
      toast.error(error);
    } else {
      removePageFromCache(client, botId, facebookPage.id!);
      toast.success('Facebook Messenger disconnected');
    }
    setRetryDisconnect(false);
  }, [botId, client, disconnect, facebookPage.id, setRetryDisconnect, toast]);

  const onSuccess = useCallback(
    async (_response: SuccessResponse) => {
      await retryDisconnect();
    },
    [retryDisconnect],
  );

  const isInitialized = Boolean(
    // @ts-expect-error: custom thing that doesn't exist on window type
    window.FB,
  );

  if (isInitialized) {
    return <RetryButton retryDisconnect={retryDisconnect} />;
  }

  return (
    <FacebookLogin
      appId={APP_ID!}
      scope={SCOPE}
      autoLoad
      onSuccess={onSuccess}
      render={LoadingButton}
      initParams={{
        version: META_GRAPH_API_VERSION,
      }}
    />
  );
}

export default RetryDisconnect;
