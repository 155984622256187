import { formatTime } from 'frontend/components/Stats/components/LineChart/utils';
import type { Granularity } from 'frontend/features/Analytics/types';

import styles from './Tooltip.scss';

function roundIndicatorStyle(color: string) {
  return {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: color,
    marginRight: '5px',
  };
}

interface TooltipProps {
  slice: { points: readonly any[] }; // TODO: figure out how this type is supposed to work
  granularity: Granularity;
  isPercentage?: boolean;
}

const Tooltip = ({ slice: { points }, granularity = 'day', isPercentage = false }: TooltipProps) => {
  const firstDate = points?.[0]?.data?.x;
  if (!firstDate) {
    return null;
  }
  const text = formatTime(firstDate, granularity);
  return (
    <div className={styles.toolTip}>
      <span className={styles.toolTipText}>{text}</span>
      {points.map(({ data: { y, color, label } }) => (
        <div key={label} className={styles.toolTipLabel}>
          <div style={roundIndicatorStyle(color)} />
          {isPercentage ? `${y}%` : y} {label}
        </div>
      ))}
    </div>
  );
};

export default Tooltip;
