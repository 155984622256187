import cx from 'classnames';
import React from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { ArrowLeft } from 'frontend/assets/icons';
import { Icon, Panel, Radio } from 'frontend/components';
import InputRHF from 'frontend/components/Input/InputRHF';
import SelectRHF from 'frontend/components/Select/SelectRHF';
import ToggleRHF from 'frontend/components/ToggleSwitch/ToggleRHF';

import FreeFormSection from './FreeFormSection';
import styles from './NewFeedbackForm.scss';
import PredefinedButtonsSection from './PredefinedButtonSection';
import RatingScaleSection from './RatingScaleSection';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import type { FeedbackForm, RHF_FORMS_NAME } from '../constants';
import shared from '../shared.scss';

interface Props {
  rhfFormPath: `${typeof RHF_FORMS_NAME}.${number}`;
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  setFormIndexToEdit: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function NewFeedbackForm({ rhfMethods, rhfFormPath, setFormIndexToEdit }: Props): React.JSX.Element {
  const { register, watch } = rhfMethods;

  const watchFeedbackType = watch(`${rhfFormPath}.feedbackType`) as FeedbackForm['feedbackType'];
  const watchFormTitle = watch(`${rhfFormPath}.formTitle`);
  const watchTriggerType = watch(`${rhfFormPath}.triggerType`);

  return (
    <article>
      <section>
        <div>
          <header className={styles.headingSection}>
            <Icon component={ArrowLeft} onClick={() => setFormIndexToEdit(null)} />
            <h3>{watchFormTitle}</h3>
          </header>

          <div className={shared.leftSideSection}>
            <div>
              <h4 className={shared.heading}>Form settings</h4>
              <p className="m-b-0">
                Tailor your feedback form to suit your specific requirements and audience by customizing the triggers,
                and segments.
              </p>
            </div>
            <div className={shared.maxWidth}>
              <SelectRHF
                label="Type"
                name={`${rhfFormPath}.feedbackType`}
                rhfMethods={rhfMethods}
                rhfOptions={{ required: true }}
                fieldColor="mischka"
              >
                <SelectRHF.Option value="widget_feedback" label="Chat widget feedback" />
                <SelectRHF.Option value="external_feedback" label="External link" />
              </SelectRHF>
            </div>
            <div className={shared.maxWidth}>
              <InputRHF
                name={`${rhfFormPath}.formTitle`}
                rhfMethods={rhfMethods}
                rhfOptions={{ required: true, maxLength: 100 }}
                label="Form title (for internal use only)"
                inputLimit={100}
                fieldColor="mischka"
              />
            </div>
            <div className={shared.maxWidth}>
              <label>Trigger on</label>
              <div className={styles.radioWrapper}>
                <Radio
                  input={{ name: `${rhfFormPath}.triggerType`, value: 'bot_conversation' }}
                  label="Bot conversation"
                  layout="inline"
                  rhf={{ register }}
                />
                <Radio
                  input={{ name: `${rhfFormPath}.triggerType`, value: 'handover_conversation' }}
                  label="Handover conversation"
                  layout="inline"
                  rhf={{ register }}
                />
              </div>
            </div>
            {watchTriggerType === 'bot_conversation' && (
              <div className={shared.maxWidth}>
                <ToggleRHF
                  rhfMethods={rhfMethods}
                  name={`${rhfFormPath}.allowBotFeedbackAfterHandoverFeedback`}
                  label="Allow bot feedback after handover feedback"
                  className={styles.toggleSwitch}
                />
                <small className={styles.smallText}>
                  When enabled, the bot conversation feedback can still be triggered at its due time, even if the user
                  has previously been presented with a handover conversation feedback during the same conversation.
                </small>
              </div>
            )}
            {watchFeedbackType === 'external_feedback' && (
              <div className={shared.maxWidth}>
                <InputRHF
                  name={`${rhfFormPath}.urlForFeedback`}
                  rhfMethods={rhfMethods}
                  rhfOptions={{ required: true, maxLength: 300 }}
                  label="URL"
                  inputLimit={300}
                  fieldColor="mischka"
                />
              </div>
            )}
          </div>

          <div className={cx(shared.leftSideSection, 'm-t-lg')}>
            <div>
              <h4 className={shared.heading}>General options</h4>
              <p className="m-b-0">Customize key elements such as button texts.</p>
            </div>
            {watchFeedbackType === 'widget_feedback' && (
              <>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.backButton`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 30 }}
                    label="Back button"
                    inputLimit={30}
                    fieldColor="mischka"
                  />
                </div>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.nextButton`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 30 }}
                    label="Next button"
                    inputLimit={30}
                    fieldColor="mischka"
                  />
                </div>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.sendButton`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 30 }}
                    label="Send button"
                    inputLimit={30}
                    fieldColor="mischka"
                  />
                </div>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.thanksText`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 300 }}
                    label="Thanks"
                    inputLimit={300}
                    fieldColor="mischka"
                  />
                </div>
              </>
            )}
            {watchFeedbackType === 'external_feedback' && (
              <>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.headline`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 50 }}
                    label="Headline"
                    inputLimit={50}
                    fieldColor="mischka"
                  />
                </div>
                <div className={shared.maxWidth}>
                  <InputRHF
                    name={`${rhfFormPath}.buttonLabel`}
                    rhfMethods={rhfMethods}
                    rhfOptions={{ required: true, maxLength: 20 }}
                    label="Button label"
                    inputLimit={20}
                    fieldColor="mischka"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {watchFeedbackType === 'widget_feedback' && (
        <>
          <Panel.Separator />

          <RatingScaleSection rhfMethods={rhfMethods} rhfFormPath={rhfFormPath} />

          <Panel.Separator />

          <PredefinedButtonsSection rhfMethods={rhfMethods} rhfFormPath={rhfFormPath} />

          <Panel.Separator />

          <FreeFormSection rhfMethods={rhfMethods} rhfFormPath={rhfFormPath} />
        </>
      )}
    </article>
  );
}
