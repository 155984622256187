import { capitalize, invert } from 'lodash';

import { FolderElement } from 'frontend/features/Build/components/DialoguePath/FolderElement';
import { ParentElement } from 'frontend/features/Build/components/DialoguePath/ParentElement';
import { SkillSubscriptionElements } from 'frontend/features/Build/components/DialoguePath/SkillSubscriptionElements';
import { type LibraryItemType, libraryItemTypes } from 'frontend/features/Build/components/DialoguePath/constants';
import type { BuildType } from 'frontend/propTypes/BuildIdObjectType';

const folderTypeValueToKey = invert(libraryItemTypes);

export function formatType(type: LibraryItemType): string {
  return capitalize(folderTypeValueToKey[type]!.toLowerCase().replace('_', ' '));
}

export function getParentUrl(buildId: string, buildType: BuildType, type: LibraryItemType, id: string): string {
  return `/${buildType}/${buildId}/build/${folderTypeValueToKey[type]!.replace('_', '-').toLowerCase()}/${id}`;
}

export function pathnameToSpecialFolderType({ pathname }: { pathname: string }): LibraryItemType | null {
  if (pathname.match('/greeting/')) {
    return libraryItemTypes.GREETINGS;
  }
  if (pathname.match('/fallback/')) {
    return libraryItemTypes.FALLBACKS;
  }
  if (pathname.match('/system-dialogue/')) {
    return libraryItemTypes.SYSTEM_DIALOGUES;
  }
  return null;
}

export function getElementType({ type }: { type: LibraryItemType }, subscribedSkillName?: string) {
  if (type === libraryItemTypes.FOLDER) {
    return FolderElement;
  }

  if (type === libraryItemTypes.SKILLS) {
    return SkillSubscriptionElements;
  }

  if (
    (
      [
        libraryItemTypes.DIALOGUE,
        libraryItemTypes.GREETING,
        libraryItemTypes.FALLBACK,
        libraryItemTypes.SYSTEM_DIALOGUE,
      ] as LibraryItemType[]
    ).includes(type)
  ) {
    return ParentElement;
  }

  if (subscribedSkillName) {
    return null; // We don't want to display 'Dialogues' for subscriptions
  }

  if (
    (
      [
        libraryItemTypes.DIALOGUES,
        libraryItemTypes.GREETINGS,
        libraryItemTypes.FALLBACKS,
        libraryItemTypes.SYSTEM_DIALOGUES,
      ] as LibraryItemType[]
    ).includes(type)
  ) {
    return FolderElement;
  }

  return null;
}
