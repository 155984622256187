import cx from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Breadcrumbs from 'frontend/components/Breadcrumbs/Breadcrumbs';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import { FolderElement } from 'frontend/features/Build/components/DialoguePath/FolderElement';
import { InputElement } from 'frontend/features/Build/components/DialoguePath/InputElement';
import { SkillSubscriptionElements } from 'frontend/features/Build/components/DialoguePath/SkillSubscriptionElements';
import { getElementType, pathnameToSpecialFolderType } from 'frontend/features/Build/components/DialoguePath/utils';
import type { LabelColor } from 'frontend/features/Library/constants';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import { selectCurrentBreadcrumbs } from 'frontend/state/dux/build';

import styles from './DialoguePath.scss';
import { libraryItemTypes } from './constants';

export default function DialoguePath({
  readOnly,
  subscribedSkillName,
  isActive,
  dialogueLabelColorData,
}: {
  readOnly: boolean;
  subscribedSkillName?: string;
  isActive: Record<string, boolean>;
  dialogueLabelColorData: { colorLabel: LabelColor; dialogueType: DialogueType };
}) {
  const location = useLocation();
  const currentBreadcrumbs = useSelector(selectCurrentBreadcrumbs);

  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const { buildType, buildId } = useBotOrSkill();

  const breadcrumbs = useMemo(() => cloneDeep(currentBreadcrumbs), [currentBreadcrumbs]);

  const MAX_BREADCRUMBS = 3;

  const numberOfFolderParents = breadcrumbs?.reduce((acc, crumb) => {
    if (crumb.type === libraryItemTypes.FOLDER) return acc + 1;
    return acc;
  }, 0);

  if (location.pathname.match('/new')) {
    return (
      <InputElement
        selectedLanguage={selectedLanguage}
        currentLanguage={currentLanguage}
        isActive={isActive}
        dialogueLabelColorData={dialogueLabelColorData}
      />
    );
  }

  const initialFolders: React.ReactElement[] = [];
  const folderType = pathnameToSpecialFolderType(location);
  if (folderType) {
    initialFolders.push(<FolderElement key={`folder-${folderType}`} type={folderType} />);
    (breadcrumbs || []).shift();
  }

  return (
    <div className={cx(styles.dialoguePath)}>
      <Breadcrumbs
        itemsAfterCollapse={1}
        itemsBeforeCollapse={1}
        maxItems={MAX_BREADCRUMBS}
        classNames={{
          container: styles.breadcrumbsContainer,
          li: styles.breadcrumbsLi,
          dropdownOverlay: styles.breadcrumbsDropdownOverlay,
          dropdownOverlayUl: styles.breadcrumbsDropdownOverlayUl,
          dropdownOverlayLi: styles.breadcrumbsDropdownOverlayLi,
        }}
        overlayPosition={{ position: 'custom' }}
      >
        {initialFolders}
        {(breadcrumbs || []).flatMap((breadcrumb) => {
          const { type, id } = breadcrumb;
          const Component = getElementType(breadcrumb, subscribedSkillName);

          const isInCollapsedWithFolderParent = breadcrumbs.length >= MAX_BREADCRUMBS && numberOfFolderParents > 0;

          if (Component == null) {
            return [];
          }

          if (type === libraryItemTypes.SKILLS && subscribedSkillName) {
            return SkillSubscriptionElements({ subscribedSkillName });
          }

          return (
            <Component
              key={`breadcrumb-${type}${id}`}
              {...breadcrumb}
              currentLanguage={currentLanguage}
              buildType={buildType}
              buildId={buildId}
              subscribedSkillName={subscribedSkillName}
              hasFolderParentInCollapsed={isInCollapsedWithFolderParent}
            />
          );
        })}
        <InputElement
          selectedLanguage={selectedLanguage}
          currentLanguage={currentLanguage}
          readOnly={readOnly}
          isActive={isActive}
          dialogueLabelColorData={dialogueLabelColorData}
        />
      </Breadcrumbs>
    </div>
  );
}
