import cx from 'classnames';
import { useCallback, useRef } from 'react';
import { Field } from 'react-final-form';
import InputAutosize from 'react-input-autosize/lib/AutosizeInput';

import type { DialogueType } from 'frontend/constants/dialogueTypes';
import styles from 'frontend/features/Build/components/DialoguePath/DialoguePath.scss';
import { DialogueIcon } from 'frontend/features/Library/components';
import type { LabelColor } from 'frontend/features/Library/constants';

export function InputElement({
  selectedLanguage,
  currentLanguage,
  dialogueLabelColorData,
  readOnly,
  isActive,
}: {
  selectedLanguage: string;
  currentLanguage: string;
  dialogueLabelColorData: { colorLabel: LabelColor; dialogueType: DialogueType };
  isActive: Record<string, boolean>;
  readOnly?: boolean;
}) {
  const inputRef = useRef<{
    blur: CallableFunction;
  }>(null);
  const onKeyDown = useCallback((e) => {
    if ((e.metaKey && e.key === 's') || e.key === 'Enter' || e.key === 'Escape') {
      inputRef.current?.blur();
    }
  }, []);
  const disabled = !isActive[selectedLanguage];
  const classNames = cx(styles.inputWrapper, { [styles.inputWrapperDisabled]: disabled });

  return (
    <div className={classNames} onKeyDown={onKeyDown} role="button" tabIndex={0}>
      <DialogueIcon currentLanguage={currentLanguage} className={styles.inputIcon} {...dialogueLabelColorData} />
      <Field name={`title.${currentLanguage}`}>
        {({ input: { value, onChange } }) => (
          <InputAutosize
            value={value}
            onChange={onChange}
            ref={inputRef}
            tabIndex={readOnly ? -1 : 0}
            placeholder="Dialogue title (click to edit)"
            readOnly={readOnly}
          />
        )}
      </Field>
    </div>
  );
}
