import cx from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { useMemo, useState } from 'react';
import { DateRangePicker, type InputRange, type Range } from 'react-date-range';

import Button from 'frontend/components/Button/Button';
import SelectWithSearch from 'frontend/components/SelectWithSearch/SelectWithSearch';
import type { WithRequired } from 'frontend/utils/TS';

import styles from './styles.scss';
import { getStaticRanges } from './utils/staticRanges';

const TODAY_DATE = new Date();
const RANGE_COLORS = ['rgba(166, 202, 253, 0.7)'];

const isValidDate = (date: unknown): boolean => date instanceof Date && !Number.isNaN(date.getTime());
const getSafeDate = <T,>(date: T): T | undefined => (isValidDate(date) ? date : undefined);

const createNewRanges = (range: WithRequired<Range, 'startDate' | 'endDate'>, name: string): Range[] => [
  { key: name, ...range, startDate: startOfDay(range.startDate), endDate: endOfDay(range.endDate) },
];
const createNewRangesFromStaticRanges = (
  range: () => WithRequired<Range, 'startDate' | 'endDate'>,
  name: string,
): Range[] => [{ key: name, ...range, startDate: startOfDay(range().startDate), endDate: endOfDay(range().endDate) }];

interface DatePickerProps {
  initialStartDate?: Date;
  initialEndDate?: Date;
  inputRanges?: InputRange[];
  showSelect?: boolean;
  onSave?(ranges: Range[]): void;
  onChange?(ranges: Range[]): void;
  maxDate?: Date;
  minDate?: Date;
  /** If defined, limit the pre-defined time-range options according to the `maxNumberOfDays`. */
  maxNumberOfDays?: number;
  hideCompare?: boolean;
  name?: string;
  contentDisplayVertical?: boolean;
  /** If true, show the pre-defined time ranges selectable by the user (e.g. this week, last month). */
  showDatepickerStaticRange?: boolean;
}

const DatePicker = ({
  minDate,
  maxDate,
  maxNumberOfDays,
  onSave,
  showSelect,
  inputRanges = [],
  hideCompare,
  contentDisplayVertical,
  name = 'range1',
  onChange,
  initialStartDate,
  initialEndDate,
  showDatepickerStaticRange,
}: DatePickerProps) => {
  const staticRanges = useMemo(() => getStaticRanges(maxNumberOfDays), [maxNumberOfDays]);
  const [activeStaticRange, setActiveStaticRange] = useState('');

  const [justClickedOnSelect, setJustClickedOnSelect] = useState(false);

  const [ranges, setRanges] = useState<Range[]>(
    createNewRanges(
      {
        startDate: initialStartDate || TODAY_DATE,
        endDate: initialEndDate || TODAY_DATE,
      },
      name,
    ),
  );

  const datepickerWrapperStyle = cx(styles.datepickerWrapper, {
    [styles.hideCompare]: hideCompare,
    [styles.contentDisplayVertical]: contentDisplayVertical,
    [styles.hideDatepickerStaticRange]: !showDatepickerStaticRange,
  });

  const onChangeHandler: (rangesByKey: { [key: string]: WithRequired<Range, 'startDate' | 'endDate'> }) => void = (
    vals,
  ) => {
    const { [name]: values } = vals;
    if (!values) return;

    const newRanges = createNewRanges(values, name);
    setRanges(newRanges);
    setActiveStaticRange('');
    if (onChange) onChange(newRanges);
  };

  const onStaticRangeChangeHandler = (label: string) => {
    const staticRange = staticRanges.find((range) => range.label === label);
    if (staticRange) {
      const staticRangeValues = staticRange.range;
      const newRanges = createNewRangesFromStaticRanges(staticRangeValues, name);

      setRanges(newRanges);
      setActiveStaticRange(label);

      // Workaround: issue with third party components DateRangePicker listening on mouseUp event and SelectSearch listening on
      // mouseDown event which makes clicks fall through the Select component to the DatePicker when components overlap
      setJustClickedOnSelect(true);
      setTimeout(() => setJustClickedOnSelect(false), 500);

      if (onChange) onChange(newRanges);
    }
  };
  return (
    <div className={datepickerWrapperStyle}>
      {showSelect && (
        <>
          <span className={styles.label}>Date period</span>
          <SelectWithSearch
            input={{
              name: 'static-range',
              onBlur: () => undefined,
              onFocus: () => undefined,
              value: activeStaticRange,
              onChange: onStaticRangeChangeHandler,
            }}
            meta={{}}
            onBlur={() => undefined}
            onFocus={() => undefined}
            placeholder={(!activeStaticRange && 'Customized') || ''}
            options={staticRanges.map((range) => ({ name: range.label, value: range.label }))}
          />
        </>
      )}
      <DateRangePicker
        className={showSelect && justClickedOnSelect ? styles.disableEvents : ''}
        locale={enGB.enGB} // enUS (the default) makes Sunday the first day of the week 🤦
        onChange={onChangeHandler}
        rangeColors={RANGE_COLORS}
        staticRanges={showDatepickerStaticRange ? staticRanges : []}
        inputRanges={inputRanges}
        ranges={ranges}
        maxDate={getSafeDate(maxDate)}
        minDate={getSafeDate(minDate)}
        showDateDisplay={false}
      />
      {onSave && (
        <div className={styles.buttons}>
          <Button color="primary" text="Save" onClick={() => onSave(ranges)} />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
