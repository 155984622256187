/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import React, { useRef } from 'react';

import styles from './Checkbox.scss';

type Input = {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
  value?: string;
};

interface CheckboxProps {
  label?: string | React.ReactNode;
  input: Input;
  className?: string;
  labelClassName?: string;
  hidden?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLDivElement>) => void;
  allowToggleOnWrapper?: boolean;
  title?: string;
}

const Checkbox = ({
  input: { name, onChange = () => {}, checked, onClick, value },
  label,
  className,
  labelClassName,
  hidden = false,
  disabled = false,
  id,
  onClick: onCheckboxClick,
  allowToggleOnWrapper,
  title,
}: CheckboxProps) => {
  const checkboxInputRef = useRef<HTMLInputElement | null>(null);
  const checkboxStyles = cx(styles.checkbox, className, {
    [styles.checkboxHidden]: hidden,
    [styles.checkboxRow]: !!allowToggleOnWrapper,
  });
  const labelStyles = cx(styles.checkLabel, labelClassName, {
    [styles.checkLabelHasLabel]: label,
    [styles.inputRowClick]: allowToggleOnWrapper,
  });

  const handleDivClick = () => {
    if (checkboxInputRef.current && !disabled) {
      checkboxInputRef.current.click();
    }
  };

  return (
    <div
      onClick={allowToggleOnWrapper ? handleDivClick : undefined}
      role={allowToggleOnWrapper ? 'button' : undefined}
      tabIndex={allowToggleOnWrapper ? 0 : undefined}
      title={title}
      onKeyDown={
        allowToggleOnWrapper
          ? (e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleDivClick();
              }
            }
          : undefined
      }
      className={checkboxStyles}
    >
      <input
        ref={checkboxInputRef}
        type="checkbox"
        className={styles.checkInput}
        id={id || name}
        name={name}
        onClick={(e) => {
          onClick?.(e);
          onCheckboxClick?.(e);
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <label className={labelStyles} htmlFor={id || name}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
