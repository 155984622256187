import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Send } from 'frontend/assets/icons';
import { Icon, Input, SelectDialogue } from 'frontend/components';
import type { PartialSampleCandidate } from 'frontend/features/BotImprovements/SampleCandidate/hooks/useSampleCandidates';
import { useMixpanel } from 'frontend/hooks';
import type { PartialDialogue } from 'frontend/types/dialogue';

import { CandidateActions } from '..';
import styles from './CandidateRow.scss';
import { SectionRow } from '../../../components';

interface Props {
  dialogues: PartialDialogue[];
  ignoredDialogues: Pick<PartialDialogue, 'id'>[];
  languageCode: string;
  sampleCandidate: PartialSampleCandidate;
  setIgnoredDialogues: React.Dispatch<React.SetStateAction<Pick<PartialDialogue, 'id'>[]>>;
}

const CandidateRow = ({ sampleCandidate, dialogues, languageCode, setIgnoredDialogues, ignoredDialogues }: Props) => {
  const topSuggestionDialogueId = sampleCandidate?.topSuggestion?.dialogue?.id;
  const sampleDialogueId = sampleCandidate?.sample?.dialogue?.id;

  const { botId } = useParams();
  const [currentDialogueId, setCurrentDialogueId] = useState<string>(sampleDialogueId ?? topSuggestionDialogueId);
  const [messageValue, setMessageValue] = useState<string>(sampleCandidate.text);
  const [completed, setCompleted] = useState<boolean>(Boolean(sampleCandidate.sample));
  const [archived, setArchived] = useState<boolean>(false);
  const { mixpanel } = useMixpanel();

  const editMessageInput = useMemo(
    () => ({ name: 'edit-message', value: messageValue, onChange: (e) => setMessageValue(e.target.value) }),
    [messageValue],
  );

  const handleDialogueInputChange = useCallback(
    (newDialogueId) => {
      mixpanel.track('Change sample candidate dialogue', { botId, currentDialogueId, newDialogueId });
      setCurrentDialogueId(newDialogueId);
    },
    [currentDialogueId, botId, mixpanel, setCurrentDialogueId],
  );

  const selectDialogueInput = useMemo(
    () => ({
      onChange: handleDialogueInputChange,
      name: 'currentDialogue',
      value: currentDialogueId,
      onBlur: () => undefined,
      onFocus: () => undefined,
    }),
    [handleDialogueInputChange, currentDialogueId],
  );

  if (archived || completed) {
    return null;
  }

  return (
    <SectionRow>
      <div className={styles.wrapper}>
        <div className={styles.dialogueRow}>
          <div className={styles.icon}>
            <Icon className={styles.iconItem} component={Send} />
          </div>
          <div className={styles.inputField}>
            <Input input={editMessageInput} className={styles.input} />
          </div>
        </div>
        <SelectDialogue
          dialogues={dialogues}
          input={selectDialogueInput}
          placeholder="Select dialogue"
          highlightedDialogueId={topSuggestionDialogueId}
          meta={{}}
        />
      </div>
      <CandidateActions
        text={messageValue}
        dialogueId={currentDialogueId}
        languageCode={languageCode}
        sampleCandidateId={sampleCandidate.id}
        completed={completed}
        setCompleted={setCompleted}
        setArchived={setArchived}
        setIgnoredDialogues={setIgnoredDialogues}
        ignoredDialogues={ignoredDialogues}
      />
    </SectionRow>
  );
};

export default CandidateRow;
