import { noop } from 'lodash';
import { type Dispatch, type SetStateAction, forwardRef } from 'react';

import { Loader } from 'frontend/components';
import ContextMenu from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay, { type MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import type { BreadCrumbCheck } from 'frontend/features/Library/utils/breadcrumbs';
import { useCurrentLanguage, useToggle } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import libraryStyles from '../../Library.scss';
import { BOTTOM_LEVEL } from '../../constants';
import { useBreadcrumbs, useIndentationStyle } from '../../hooks';
import { getFolderIconClassnames } from '../../utils';
import LibraryFolder from '../LibraryFolder';
import LibraryItemContents from '../LibraryItemContents';

type Props = {
  buildIdObject: BuildIdObject;
  skillId?: string;
  disabled?: boolean;
  dialogueType?: DialogueType;
  className?: string;
  name?: string;
  indentationLevel: number;
  actions: MenuOverlayOptions;
  contextMenuId: string;
  showLoader: boolean;
  setShowLoader: Dispatch<SetStateAction<boolean>>;
  checkBreadcrumbs: BreadCrumbCheck;
};
const BottomFolder = forwardRef(
  (
    {
      buildIdObject,
      skillId,
      disabled,
      dialogueType,
      className,
      name,
      indentationLevel = 1,
      actions,
      contextMenuId,
      showLoader,
      setShowLoader = noop,
      checkBreadcrumbs,
    }: Props,
    ref,
  ) => {
    const [{ selectedLanguage }] = useCurrentLanguage();
    const [open, toggleOpen] = useToggle();

    const { isInBreadcrumbs } = useBreadcrumbs({ open, toggleOpen, checkBreadcrumbs, autoOpen: false });
    const indentationStyle = useIndentationStyle(indentationLevel);

    const active = isInBreadcrumbs && !open;
    const titleClassName = getFolderIconClassnames(open);

    return (
      <>
        <ContextMenu id={contextMenuId} disabled={actions.length === 0} overlay={<MenuOverlay options={actions} />}>
          <LibraryFolder ref={ref} toggleOpen={toggleOpen} className={className} disabled={disabled} active={active}>
            <span style={indentationStyle} className={titleClassName}>
              {name}
              {showLoader && <Loader className={libraryStyles.libraryLoader} />}
            </span>
          </LibraryFolder>
        </ContextMenu>

        {open && (
          <LibraryItemContents
            selectedLanguage={selectedLanguage}
            buildIdObject={buildIdObject}
            skillId={skillId}
            parentDisabled={disabled}
            dialogueType={dialogueType}
            folderId={BOTTOM_LEVEL}
            dialogueId={BOTTOM_LEVEL}
            setShowLoader={setShowLoader}
            indentationLevel={indentationLevel + 1}
          />
        )}
      </>
    );
  },
);

BottomFolder.displayName = 'BottomFolder';

export default BottomFolder;
