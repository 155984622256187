import PropTypes from 'prop-types';

export default PropTypes.shape({
  submitFailed: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
});

export interface InputMeta {
  submitFailed: boolean;
  error: string | object;
}
