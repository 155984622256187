import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Field } from 'react-final-form';

import { ConnectFacebookPageDocument, FacebookProfileDocument } from 'frontend/api/generated';
import { LoaderSwitch, Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

import { addPageToCache } from '../utils';

type Props = {
  hide: () => void;
  args: { botId: string; facebookPages: any[] };
};

/**
 * @param facebookPages is the list of pages that the bot is already connected to
 */
export function SelectFacebookPageModal({ hide, args: { botId, facebookPages } }: Props) {
  const toast = useToast();
  const client = useApolloClient();

  const [connect] = useMutation(ConnectFacebookPageDocument);
  const { data: profileData, loading } = useQuery(FacebookProfileDocument, { fetchPolicy: 'network-only' });
  const pages = profileData?.me.facebookProfile.accounts || [];
  console.info('FacebookPages: ', pages);

  const onSubmit = async ({ pageId }) => {
    const page = pages.find(({ id }) => id === pageId);
    console.info('Selected page: ', page);
    if (!page) {
      console.info('Page not found');
      return;
    }

    const { data } = await connect({ variables: { botId, pageId: page.id, pageName: page.name } });
    console.info('ConnectFacebookPage: ', data);

    if (!data || !data.connectFacebookPage) {
      console.info('Failed to connect Facebook Messenger');
      return;
    }

    addPageToCache(client, botId, data.connectFacebookPage!);
    toast.success('Facebook Messenger connected');
  };

  return (
    <ModalForm hide={hide} onSubmit={onSubmit} title="Select Facebook page">
      <LoaderSwitch loading={loading} size="large" noMargin>
        <Field component={Select} name="pageId">
          <Select.Option value="" label="Select a facebook page" />
          {pages.map((page) => {
            let label = page.name;
            if ((facebookPages || []).some((connectedPage) => connectedPage.name === page.name)) {
              label = `${page.name} (connected)`;
            }
            return <Select.Option key={page.id} value={page.id} label={label} />;
          })}
        </Field>
      </LoaderSwitch>
    </ModalForm>
  );
}
