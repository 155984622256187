import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { LibraryDialoguesQuery } from 'frontend/api/generated';
import {
  Check,
  DoubleChevronLeft,
  Duplicate,
  FollowUp,
  Forbid,
  KindlyBlob,
  Refresh,
  Trash,
} from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES, isRegularDialogueType } from 'frontend/constants';
import UpdateColorLabel from 'frontend/features/Library/components/UpdateColorLabel';
import { CACHE_TYPES } from 'frontend/features/Library/constants';
import {
  useDeleteDialogue,
  useDuplicateDialogue,
  useEnableDialogue,
  useEnableDialogueMod,
} from 'frontend/features/Library/hooks';
import { ResetSkillDialogue } from 'frontend/features/Library/modals';
import TransferDialogue from 'frontend/features/Library/modals/TransferDialogue/TransferDialogue';
import { dialogueCanHaveFollowups, getBuildUrl, getDialogueTypeName } from 'frontend/features/Library/utils';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useLanguages, useToast } from 'frontend/hooks';

function hasColorLabel(item) {
  return [CACHE_TYPES.DIALOGUE, CACHE_TYPES.SUGGESTION_DIALOGUE].includes(item.__typename);
}

type Props = {
  dialogue: LibraryDialoguesQuery['dialogues'][0];
  selectedLanguage: string;
  disabled: boolean;
  parentDisabled: boolean;
  skillId?: string;
  isSubscription: boolean;
};

type Return = {
  actions: MenuOverlayOptions;
  contextMenuId: string;
};

export function useDialogueContextMenu({
  dialogue,
  selectedLanguage,
  disabled,
  parentDisabled,
  skillId,
  isSubscription,
}: Props): Return {
  const navigate = useNavigate();
  const client = useApolloClient();
  const toast = useToast();
  const botOrSkillParams = useBotOrSkill();

  const { buildIdObject } = botOrSkillParams;
  const dialogueTypeName = getDialogueTypeName(dialogue.dialogueType);

  const { languageNameMapVariants } = useLanguages(botOrSkillParams);
  const enableMod = useEnableDialogueMod({
    dialogue,
    selectedLanguage,
    skillId,
    disabled,
    client,
    buildIdObject,
  });
  const enable = useEnableDialogue({
    dialogue,
    disabled,
    selectedLanguage,
    buildIdObject,
    languageNameMapVariants,
    client,
    toast,
  });
  const duplicate = useDuplicateDialogue({ dialogue, buildIdObject, toast });
  const deleteFunction = useDeleteDialogue({
    dialogue,
    selectedLanguage,
    botOrSkillParams,
    languageNameMapVariants,
    client,
    toast,
  });
  const [showTransferDialogueModal] = useModal(TransferDialogue);
  const [showResetSkillDialogue] = useModal(ResetSkillDialogue);
  const contextMenuId = `library-context-menu`;

  const actions = useMemo(() => {
    const { id, topicId, dialogueType, mod } = dialogue;

    const parentIdSegment = `${isSubscription ? 'dialogueModParentId' : 'parentId'}=${mod?.id ?? id}`;
    const regular = isRegularDialogueType(dialogueType);
    const { buildType, buildId } = botOrSkillParams;

    const actionsArray: MenuOverlayOptions = [
      {
        text: `${disabled ? 'Enable' : 'Disable'} ${dialogueTypeName}`,
        icon: disabled ? Check : Forbid,
        onClick: isSubscription ? enableMod : enable,
        disabled: Boolean(disabled && parentDisabled),
      },
    ];

    if (dialogueCanHaveFollowups(dialogue)) {
      actionsArray.unshift({
        text: 'New follow-up',
        icon: FollowUp,
        onClick: async () =>
          navigate(getBuildUrl({ buildType, buildId, dialogueType: 'dialogue', target: `new?${parentIdSegment}` })),
      });
    }

    if (isSubscription) {
      if (mod) {
        actionsArray.splice(1, 0, {
          text: 'Reset skill dialogue',
          icon: DoubleChevronLeft,
          onClick: () => showResetSkillDialogue({ ...buildIdObject, skillId, dialogueId: dialogue.id }),
        });
      }

      return actionsArray;
    }

    if (dialogueType !== DIALOGUE_TYPES.SYSTEM_DIALOGUE) {
      actionsArray.splice(1, 0, {
        text: `New ${dialogueTypeName}`,
        icon: KindlyBlob,
        onClick: () => {
          const topicQuery = topicId ? `?topicId=${topicId}` : '';
          navigate(
            getBuildUrl({
              buildType,
              buildId,
              dialogueType: dialogueTypeName,
              target: `new${topicQuery}`,
            }),
          );
        },
      });
      actionsArray.splice(2, 0, 'separator');
      actionsArray.splice(4, 0, {
        text: `Delete ${dialogueTypeName}`,
        icon: Trash,
        onClick: deleteFunction,
        color: 'red',
      });
    }

    if (regular && !dialogue.parentId) {
      actionsArray.splice(2, 0, { text: 'Duplicate dialogue', icon: Duplicate, onClick: duplicate });
      actionsArray.splice(4, 0, {
        text: 'Transfer dialogue',
        icon: Refresh,
        onClick: () => showTransferDialogueModal({ dialogue, botOrSkillParams, selectedLanguage }),
      });
    }

    if (hasColorLabel(dialogue)) {
      actionsArray.push(
        'separator',
        <UpdateColorLabel dialogue={dialogue} isSubscription={isSubscription} skillId={skillId} />,
      );
    }

    return actionsArray;
  }, [
    dialogue,
    isSubscription,
    botOrSkillParams,
    disabled,
    dialogueTypeName,
    enableMod,
    enable,
    parentDisabled,
    navigate,
    showResetSkillDialogue,
    buildIdObject,
    skillId,
    deleteFunction,
    duplicate,
    showTransferDialogueModal,
    selectedLanguage,
  ]);

  return { actions, contextMenuId };
}
