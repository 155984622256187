import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { type DialogueType, type MutationTransferDialogueArgs, TransferDialogueDocument } from 'frontend/api/generated';
import { dragAndDropTypes } from 'frontend/constants';
import { updateDialogueParentOnRemove } from 'frontend/features/Library/cacheHelpers';
import { useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import TransferForm, { NONE_SELECTED } from '../TransferForm';

const TransferDialogue = ({
  hide,
  args,
}: {
  hide: (...args: unknown[]) => void;
  args: {
    dialogue: DialogueType;
    buildIdObject: BuildIdObject;
    selectedLanguage: string;
    botOrSkillParams: BotOrSkill;
  };
}) => {
  const toast = useToast();
  const { dialogue, buildIdObject, selectedLanguage, botOrSkillParams } = args;
  const [transfer] = useMutation(TransferDialogueDocument);
  const client = useApolloClient();

  const onSubmit = useCallback(
    async (values: Pick<NonNullable<MutationTransferDialogueArgs>, 'deleteOriginal' | 'botId' | 'skillId'>) => {
      const variables: Pick<
        NonNullable<MutationTransferDialogueArgs>,
        'deleteOriginal' | 'transferLabels' | 'id' | 'languageCode' | 'botId' | 'skillId'
      > = {
        id: dialogue.id,
        languageCode: selectedLanguage,
        botId: values.botId === NONE_SELECTED ? undefined : values.botId,
        skillId: values.skillId === NONE_SELECTED ? undefined : values.skillId,
        deleteOriginal: values.deleteOriginal,
        transferLabels: true, // TODO: make some UI for the user to pick this
      };
      const { data } = await transfer({ variables });

      toast.success('The dialogue is being transferred. This may take a moment...');
      if (data?.transferDialogue?.deletedOriginal) {
        updateDialogueParentOnRemove({ client, buildIdObject, dialogue });
      }
    },
    [buildIdObject, client, selectedLanguage, dialogue, toast, transfer],
  );

  const initialValues = useMemo(
    () => ({
      title: dialogue.title.default,
      deleteOriginal: false,
      botId: NONE_SELECTED,
      skillId: NONE_SELECTED,
    }),
    [dialogue.title.default],
  );

  return (
    <TransferForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={initialValues}
      type={dragAndDropTypes.DIALOGUE}
      botOrSkillParams={botOrSkillParams}
    />
  );
};

export default TransferDialogue;
