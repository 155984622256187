import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import invalidateCacheDialogues from 'frontend/api/cacheHelpers/invalidateCacheDialogues';
import { DropDialogueInDialogueDocument, LibraryDialoguesDocument } from 'frontend/api/generated';
import { dragAndDropTypes } from 'frontend/constants';
import { dropDialogue, dropSamples } from 'frontend/features/Library/components/DialogueItem/utils';
import { dropSamplesInDialogue } from 'frontend/features/Library/mutations';
import { useBotOrSkill, useToast } from 'frontend/hooks';

type Props = {
  id: string;
  setShowLoader: (showLoader: boolean) => void;
  skillId?: string;
  currentTitle: string;
  currentLanguage: string;
};

type Item = { type: string; id: string };
type Hook = (item: Item) => Promise<void>;

export function useDropInDialogue({ id, setShowLoader, skillId, currentTitle, currentLanguage }: Props): Hook {
  const client = useApolloClient();
  const toast = useToast();
  const { buildIdObject } = useBotOrSkill();

  const [dialogueInDialogue] = useMutation(DropDialogueInDialogueDocument, {
    update(cache, { data }) {
      const dialogueMoved = data?.dropDialogueInDialogue;

      if (!dialogueMoved) {
        return;
      }

      /* We invalidate the cache for the queries related to the connected dialogues of the moved dialogue (at starting point). */
      const connectedDialoguesAtStart = cache.readQuery({
        query: LibraryDialoguesDocument,
        variables: { ...buildIdObject, parentId: dialogueMoved.id, regular: true, endToEnd: true },
      });

      if (!connectedDialoguesAtStart) {
        return;
      }

      const connectedDialoguesIdsAtStart =
        (connectedDialoguesAtStart.dialogues as { id: string }[]).map(({ id: dialogueId }) => dialogueId) || [];
      invalidateCacheDialogues(connectedDialoguesIdsAtStart, buildIdObject!, cache);

      /* We invalidate the cache for all the queries related to the connected dialogues of the moved dialogue (at arriving point). */
      const connectedDialoguesIdsAtArrival = (
        dialogueMoved.breadcrumbs as {
          type: string;
          id: string;
        }[]
      ).flatMap(({ type, id: dialogueId }) => {
        if (type === 'dialogue') {
          return dialogueId;
        }
        return [];
      });
      invalidateCacheDialogues(connectedDialoguesIdsAtArrival, buildIdObject!, cache);

      cache.gc();
    },
  });
  const [samplesInDialogue] = useMutation(dropSamplesInDialogue);

  return useCallback(
    async (item: Item): Promise<void> => {
      try {
        setShowLoader(true);
        if (item.type === dragAndDropTypes.DIALOGUE) {
          await dropDialogue({
            id,
            client,
            toast,
            buildIdObject,
            skillId,
            dialogueInDialogue,
            item,
            currentTitle,
            currentLanguage,
          });
        } else if (item.type === dragAndDropTypes.SAMPLES) {
          await dropSamples({ id, client, toast, buildIdObject, samplesInDialogue, item });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [
      buildIdObject,
      client,
      currentLanguage,
      currentTitle,
      dialogueInDialogue,
      id,
      samplesInDialogue,
      setShowLoader,
      skillId,
      toast,
    ],
  );
}
