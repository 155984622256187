import type { ButtonType } from 'frontend/api/generated';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import type {
  ButtonClicksSeriesData,
  ChatLabelsData,
  ChatLabelsTotalData,
  ChatbubblePagesData,
  EngagementSeriesData,
  EngagementTotalData,
  FallbackSeriesData,
  FallbackTotalData,
  FrequentDialoguesData,
  HandoverDurationData,
  HandoverTimeSeriesData,
  HandoverWaitTimeData,
  MessagesData,
  MostClickedButtonsData,
  SageData,
  SagePath,
  SessionsData,
  SessionsTotalData,
} from 'frontend/features/Analytics/utils/sageData';
import type { LabelColor } from 'frontend/features/Library/constants';

export type GraphqlKeys = 'graphql:button_clicks' | 'graphql:frequent_dialogues' | 'graphql:chat_labels';

export type ButtonClicksGraphqlData = Partial<ButtonType>[];
export type FrequentDialoguesGraphqlData = {
  id: string;
  title: Record<string, string>;
  url: string;
  dialogueType: DialogueType;
  colorLabel: LabelColor;
  found: boolean;
}[];
export type ChatLabelsGraphqlData = { id: number; label: string; color: string; type: string }[];
export type GraphqlData = ButtonClicksGraphqlData | FrequentDialoguesGraphqlData | ChatLabelsGraphqlData;

const MockDataRecord: Partial<Record<SagePath | GraphqlKeys, () => SageData | GraphqlData>> = {
  '/buttons/series': (): ButtonClicksSeriesData => [
    {
      count: 3693,
      date: '2022-05-04T00:00:00.000000',
      rate: 0.07539492580181906,
    },
    {
      count: 3429,
      date: '2022-05-05T00:00:00.000000',
      rate: 0.07208264850903968,
    },
    {
      count: 1768,
      date: '2022-05-06T00:00:00.000000',
      rate: 0.07501933488012374,
    },
    {
      count: 834,
      date: '2022-05-07T00:00:00.000000',
      rate: 0.09564750134336378,
    },
    {
      count: 5116,
      date: '2022-05-08T00:00:00.000000',
      rate: 0.10465116279069768,
    },
    {
      count: 3838,
      date: '2022-05-09T00:00:00.000000',
      rate: 0.07079318013343217,
    },
    {
      count: 3580,
      date: '2022-05-10T00:00:00.000000',
      rate: 0.07632140317610808,
    },
    {
      count: 113,
      date: '2022-05-11T00:00:00.000000',
      rate: 0.07230255839822025,
    },
  ],
  '/buttons/most_clicked': (): MostClickedButtonsData => [
    {
      count: 2341,
      button_id: '15d40d69-7848-4bde-87af-b0189a03d101',
      dialogue_id: 'b761624b-ab86-45a2-8816-e58840f29bab',
      ratio: 0.12467380305693135,
    },
    {
      count: 1752,
      button_id: '7f2b63c1-74b8-4683-9ee9-e3d5cbc90bbe',
      dialogue_id: 'b761624b-ab86-45a2-8816-e58840f29bab',
      ratio: 0.09330563987857485,
    },
    {
      count: 1327,
      button_id: 'c84ea0b4-014c-428f-8960-6341fdcd74ac',
      dialogue_id: '9887d99e-1f43-4ea6-8f01-06e65e6f94e6',
      ratio: 0.07067156627789317,
    },
    {
      count: 948,
      button_id: 'a22b64cd-c7e9-4dd9-a184-23fb4380c7d0',
      dialogue_id: 'b761624b-ab86-45a2-8816-e58840f29bab',
      ratio: 0.05048729829046174,
    },
    {
      count: 603,
      button_id: '61bd1aa9-5fa1-49b5-862b-d5571a34b9f4',
      dialogue_id: 'fdc7be7a-474d-4aec-b8c9-d469a4a266ea',
      ratio: 0.032113756191084836,
    },
  ],
  '/fallbacks/series': (): FallbackSeriesData => [
    {
      count: 315,
      date: '2022-05-04T00:00:00.000000',
      rate: 0.07539492580181906,
    },
    {
      count: 307,
      date: '2022-05-05T00:00:00.000000',
      rate: 0.07208264850903968,
    },
    {
      count: 291,
      date: '2022-05-06T00:00:00.000000',
      rate: 0.07501933488012374,
    },
    {
      count: 178,
      date: '2022-05-07T00:00:00.000000',
      rate: 0.09564750134336378,
    },
    {
      count: 81,
      date: '2022-05-08T00:00:00.000000',
      rate: 0.10465116279069768,
    },
    {
      count: 382,
      date: '2022-05-09T00:00:00.000000',
      rate: 0.07079318013343217,
    },
    {
      count: 322,
      date: '2022-05-10T00:00:00.000000',
      rate: 0.07632140317610808,
    },
    {
      count: 130,
      date: '2022-05-11T00:00:00.000000',
      rate: 0.07230255839822025,
    },
  ],
  '/fallbacks/total': (): FallbackTotalData => ({
    count: 2303,
    rate: 0.07595896962300867,
  }),
  '/chatbubble/pages': (): ChatbubblePagesData => [
    {
      messages: 7264,
      sessions: 1506,
      web_host: 'www.example.com',
      web_path: '/kundeservice/kontakt-oss',
    },
    {
      messages: 7868,
      sessions: 1482,
      web_host: 'www.example.com',
      web_path: '/',
    },
    {
      messages: 2242,
      sessions: 486,
      web_host: 'www.example.com',
      web_path: '/kundeservice',
    },
    {
      messages: 1467,
      sessions: 268,
      web_host: 'www.example.com',
      web_path: '/account/orders',
    },
    {
      messages: 456,
      sessions: 131,
      web_host: 'www.example.com',
      web_path: '/account',
    },
  ],
  '/chatlabels/added': (): ChatLabelsData => [
    {
      label_id: 1851,
      label_text: 'Delivery',
      count: 1851,
    },
    {
      label_id: 1495,
      label_text: 'Keywords',
      count: 1495,
    },
    {
      label_id: 1428,
      label_text: 'Service & support',
      count: 1428,
    },
    {
      label_id: 829,
      label_text: 'Payment & order information',
      count: 829,
    },
    {
      label_id: 762,
      label_text: 'General / CCC',
      count: 762,
    },
  ],
  '/chatlabels/added/total': (): ChatLabelsTotalData => ({
    count: 9822,
  }),
  '/sessions': (): SessionsData => [
    {
      count: 1018,
      date: '2022-05-04T00:00:00.000000',
    },
    {
      count: 1042,
      date: '2022-05-05T00:00:00.000000',
    },
    {
      count: 913,
      date: '2022-05-06T00:00:00.000000',
    },
    {
      count: 519,
      date: '2022-05-07T00:00:00.000000',
    },
    {
      count: 217,
      date: '2022-05-08T00:00:00.000000',
    },
    {
      count: 1298,
      date: '2022-05-09T00:00:00.000000',
    },
    {
      count: 1058,
      date: '2022-05-10T00:00:00.000000',
    },
    {
      count: 466,
      date: '2022-05-11T00:00:00.000000',
    },
  ],
  '/messages': (): MessagesData => [
    {
      count: 5372,
      date: '2022-05-04T00:00:00.000000',
    },
    {
      count: 5263,
      date: '2022-05-05T00:00:00.000000',
    },
    {
      count: 4698,
      date: '2022-05-06T00:00:00.000000',
    },
    {
      count: 701,
      date: '2022-05-07T00:00:00.000000',
    },
    {
      count: 286,
      date: '2022-05-08T00:00:00.000000',
    },
    {
      count: 6918,
      date: '2022-05-09T00:00:00.000000',
    },
    {
      count: 5706,
      date: '2022-05-10T00:00:00.000000',
    },
    {
      count: 2435,
      date: '2022-05-11T00:00:00.000000',
    },
  ],
  '/dialogues/frequently_triggered': (): FrequentDialoguesData => [
    {
      count: 4379,
      ratio: 0.18186726472298365,
      dialogue_id: '88305666-5e3f-41a8-bacc-4e0ef2531da8',
    },
    {
      count: 2477,
      ratio: 0.10287399285654955,
      dialogue_id: '5daf43a2-1956-4cff-9c5b-c20c6f8c3f92',
    },
    {
      count: 2246,
      ratio: 0.0932801727718249,
      dialogue_id: 'fdc7be7a-474d-4aec-b8c9-d469a4a266ea',
    },
    {
      count: 1710,
      ratio: 0.07101918764016944,
      dialogue_id: '88e4808e-2484-470b-9825-7ce9d7bb1082',
    },
    {
      count: 995,
      ratio: 0.04132403023506936,
      dialogue_id: '7c661f2f-e831-492c-a0c6-76133ffe8b26',
    },
  ],
  '/takeovers/series': (): HandoverTimeSeriesData => [
    {
      date: '2022-06-16T00:00:00.000000',
      ended: 763,
      exited: 20,
      requests: 784,
      requests_while_closed: 0,
      started: 764,
    },
    {
      date: '2022-06-17T00:00:00.000000',
      ended: 694,
      exited: 34,
      requests: 730,
      requests_while_closed: 0,
      started: 696,
    },
    {
      date: '2022-06-18T00:00:00.000000',
      ended: 0,
      exited: 0,
      requests: 0,
      requests_while_closed: 0,
      started: 0,
    },
    {
      date: '2022-06-19T00:00:00.000000',
      ended: 0,
      exited: 0,
      requests: 0,
      requests_while_closed: 0,
      started: 0,
    },
    {
      date: '2022-06-20T00:00:00.000000',
      ended: 1005,
      exited: 57,
      requests: 1061,
      requests_while_closed: 0,
      started: 1005,
    },
    {
      date: '2022-06-21T00:00:00.000000',
      ended: 779,
      exited: 38,
      requests: 816,
      requests_while_closed: 0,
      started: 779,
    },
    {
      date: '2022-06-22T00:00:00.000000',
      ended: 718,
      exited: 30,
      requests: 748,
      requests_while_closed: 0,
      started: 718,
    },
    {
      date: '2022-06-23T00:00:00.000000',
      ended: 0,
      exited: 0,
      requests: 0,
      requests_while_closed: 0,
      started: 0,
    },
  ],
  '/takeovers/duration': (): HandoverDurationData => ({
    mean: 590.7855519070472,
    median: 480,
    n: 3959,
    stddev: 3506.944491947748,
  }),
  '/takeovers/wait_time': (): HandoverWaitTimeData => ({
    mean: 190.1943953547084,
    median: 60,
    n: 3961,
    stddev: 610.1536469907425,
  }),
  '/sessions/total': (): SessionsTotalData => ({
    count: 7359,
  }),
  '/sessions/engagement': (): EngagementSeriesData => [
    {
      date: '2022-06-16T00:00:00.000000',
      missed: 486,
      rate: 0.7141176470588235,
    },
    {
      date: '2022-06-17T00:00:00.000000',
      missed: 421,
      rate: 0.7316762268961122,
    },
    {
      date: '2022-06-18T00:00:00.000000',
      missed: 164,
      rate: 0.801452784503632,
    },
    {
      date: '2022-06-19T00:00:00.000000',
      missed: 146,
      rate: 0.6926315789473684,
    },
    {
      date: '2022-06-20T00:00:00.000000',
      missed: 653,
      rate: 0.7088720463664735,
    },
    {
      date: '2022-06-21T00:00:00.000000',
      missed: 443,
      rate: 0.7329716696805304,
    },
    {
      date: '2022-06-22T00:00:00.000000',
      missed: 513,
      rate: 0.6940966010733453,
    },
    {
      date: '2022-06-23T00:00:00.000000',
      missed: 162,
      rate: 0.697196261682243,
    },
  ],
  '/sessions/engagement/total': (): EngagementTotalData => ({
    missed: 2988,
    rate: 0.7203294646199925,
  }),
  'graphql:button_clicks': (): ButtonClicksGraphqlData => [
    {
      id: '15d40d69-7848-4bde-87af-b0189a03d101',
      label: 'I have bought a product',
      buttonType: 'dialogue_trigger',
    },
    {
      id: '7f2b63c1-74b8-4683-9ee9-e3d5cbc90bbe',
      label: 'Other questions',
      buttonType: 'dialogue_trigger',
    },
    {
      id: 'c84ea0b4-014c-428f-8960-6341fdcd74ac',
      label: 'Transfer me to a human',
      buttonType: 'dialogue_trigger',
    },
    {
      id: 'a22b64cd-c7e9-4dd9-a184-23fb4380c7d0',
      label: 'Would like to buy a product',
      buttonType: 'dialogue_trigger',
    },
    {
      id: '61bd1aa9-5fa1-49b5-862b-d5571a34b9f4',
      label: 'I have not received the item',
      buttonType: 'dialogue_trigger',
    },
  ],
  'graphql:frequent_dialogues': (): FrequentDialoguesGraphqlData => [
    {
      id: '88305666-5e3f-41a8-bacc-4e0ef2531da8',
      title: {
        default: 'I want to talk to a human ',
      },
      url: '/bot/2107/build/dialogue/88305666-5e3f-41a8-bacc-4e0ef2531da8',
      dialogueType: 'SAMPLES',
      colorLabel: {},
      found: true,
    },
    {
      id: '5daf43a2-1956-4cff-9c5b-c20c6f8c3f92',
      title: {
        default: 'Pattern: takeover_email',
      },
      url: '/bot/2107/build/dialogue/5daf43a2-1956-4cff-9c5b-c20c6f8c3f92',
      dialogueType: 'PATTERNS',
      colorLabel: {},
      found: true,
    },
    {
      id: 'fdc7be7a-474d-4aec-b8c9-d469a4a266ea',
      title: {
        default: 'Help with existing order (greeting button)',
      },
      url: '/bot/2107/build/dialogue/fdc7be7a-474d-4aec-b8c9-d469a4a266ea',
      dialogueType: 'SAMPLES',
      colorLabel: {},
      found: true,
    },
    {
      id: '88e4808e-2484-470b-9825-7ce9d7bb1082',
      title: {
        default: 'I have another question (greeting button)',
      },
      url: '/bot/2107/build/dialogue/88e4808e-2484-470b-9825-7ce9d7bb1082',
      dialogueType: 'SAMPLES',
      colorLabel: {},
      found: true,
    },
    {
      id: '7c661f2f-e831-492c-a0c6-76133ffe8b26',
      title: {
        default: 'Help with new order (greeting)',
      },
      url: '/bot/2107/build/dialogue/7c661f2f-e831-492c-a0c6-76133ffe8b26',
      dialogueType: 'KEYWORDS',
      colorLabel: {},
      found: true,
    },
  ],
  'graphql:chat_labels': (): ChatLabelsGraphqlData => [
    {
      id: 1851,
      label: 'Delivery',
      color: '#C93912',
      type: 'DEFAULT',
    },
    {
      id: 1495,
      label: 'Keywords',
      color: '#9A16B9',
      type: 'DEFAULT',
    },
    {
      id: 1428,
      label: 'Service & support',
      color: '#14766C',
      type: 'DEFAULT',
    },
    {
      id: 829,
      label: 'Payment & order information',
      color: '#167F06',
      type: 'DEFAULT',
    },
    {
      id: 762,
      label: 'General / CCC',
      color: '#015ADA',
      type: 'DEFAULT',
    },
  ],
};

export default MockDataRecord;
