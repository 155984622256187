import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import {
  BotSubscriptionsDocument,
  DialogueListDocument,
  SkillSubscriptionDocument,
  type SkillSubscriptionType,
} from 'frontend/api/generated';
import { getPrivacySettings, getSubscribedSkills } from 'frontend/api/queries';
import { DIALOGUE_TYPES, operations } from 'frontend/constants';
import fetchIfNecessary from 'frontend/utils/fetchIfNecessary';

type T = Pick<SkillSubscriptionType, 'id' | '__typename'>;
export default (
  operation: keyof typeof operations,
  subscriptionId: string,
  { client, botId, skillId }: { client: ApolloClient<NormalizedCacheObject>; botId: string; skillId: string },
) => {
  const newSubscription: T = {
    id: subscriptionId,
    __typename: 'SkillSubscriptionType',
  };
  const subscriptionUpdate: T | null = operation === operations.ADD ? newSubscription : null;

  client.writeQuery({
    query: SkillSubscriptionDocument,
    variables: { botId, skillId },
    data: { skillSubscription: subscriptionUpdate },
  });

  fetchIfNecessary({ client, query: getSubscribedSkills, variables: { botId } });
  fetchIfNecessary({ client, query: BotSubscriptionsDocument, variables: { botId } });
  fetchIfNecessary({ client, query: getPrivacySettings, variables: { botId } });
  fetchIfNecessary({ client, query: DialogueListDocument, variables: { botId, includeSubscribedSkills: true } });
  fetchIfNecessary({ client, query: DialogueListDocument, variables: { botId, dialogueType: DIALOGUE_TYPES.SAMPLES } });
};
