import cx from 'classnames';
import { Link } from 'react-router-dom';

import { intersperse } from 'frontend/utils';

import styles from './LinkList.scss';

const intersperseComma = intersperse(', ');

export default function LinkList({
  type,
  collection,
  prefix = '',
  size = '',
}: {
  type: string;
  collection: { id?: string; name?: string }[];
  prefix?: string;
  size?: string;
}) {
  if (!collection || !collection.length) {
    return '';
  }
  const classNames = cx(styles.link, {
    [styles.linkSmall]: size === 'small',
  });
  return (
    <div>
      {intersperseComma(
        collection.map(({ id, name }) => (
          <Link className={classNames} key={`link-to-${type}-${id}`} to={`${prefix}/${type}/${id}`}>
            <p>{name}</p>
          </Link>
        )),
      )}
    </div>
  );
}
