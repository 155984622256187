import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CreatePluginInstanceDocument, DeletePluginInstanceDocument } from 'frontend/api/generated';

export default (onSubmit) => {
  const [savePlugin] = useMutation(CreatePluginInstanceDocument);
  const [removePlugin] = useMutation(DeletePluginInstanceDocument);

  const onSubmitWithPlugin = useCallback(
    async (values, form) => {
      if (values.pluginTemp) {
        const { dialogueId, pluginId } = values.pluginTemp;
        // If the plugin object exists in values, either save or remove plugin
        if (values.pluginTemp.plugin) await savePlugin({ variables: { dialogueId, pluginId } });
        else await removePlugin({ variables: { id: pluginId } });
      }

      return onSubmit(values, form);
    },
    [onSubmit, removePlugin, savePlugin],
  );

  return onSubmitWithPlugin;
};
