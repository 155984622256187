import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { DialogueListDocument, SampleCandidatesDocument, type SampleCandidatesQuery } from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';
import type { PartialDialogue } from 'frontend/types/dialogue';

export type PartialSampleCandidate = NonNullable<SampleCandidatesQuery['sampleCandidates']>[0];

type ReturnType = {
  loading: boolean;
  sampleCandidates: (PartialSampleCandidate & { completed: boolean })[];
  dialogues: PartialDialogue[];
};

export default function useSampleCandidates(
  botId: string,
  filters?: {
    fromDate: string;
    toDate: string;
  },
): ReturnType {
  const { data: sampleCandidatesData, loading: sampleCandidatesLoading } = useQuery(SampleCandidatesDocument, {
    fetchPolicy: 'network-only',
    variables: {
      botId,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
    },
  });

  const { data: dialogueData, loading: dialoguesLoading } = useQuery(DialogueListDocument, {
    variables: { botId, dialogueType: DIALOGUE_TYPES.SAMPLES },
  });

  const sampleCandidates = useMemo(() => {
    if (sampleCandidatesLoading || !sampleCandidatesData?.sampleCandidates) {
      return [];
    }

    return sampleCandidatesData.sampleCandidates.map((candidate) => ({
      ...candidate,
      completed: Boolean(candidate.sample),
    }));
  }, [sampleCandidatesData, sampleCandidatesLoading]);

  const loading = dialoguesLoading || sampleCandidatesLoading;
  const dialogues = dialogueData?.dialogueList ?? [];

  return {
    loading,
    sampleCandidates,
    dialogues,
  };
}
