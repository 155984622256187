import { gql } from '@apollo/client';

import { sampleFragment } from 'frontend/api/fragments';
import ImageCarousel from 'frontend/api/fragments/ImageCarousel.fragment.graphql';
import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';
import PluginInstanceFragment from 'frontend/api/fragments/PluginInstance.fragment.graphql';
import { BuildForms } from 'frontend/features/Build/graphql/fragments/BuildForms';
import ButtonFragment from 'frontend/features/Build/graphql/fragments/Button.fragment.graphql';
import { DialogueModFragment } from 'frontend/features/Build/graphql/fragments/DialogueModFragment';
import { Reply } from 'frontend/features/Build/graphql/fragments/Reply';
import SmartReplyFragment from 'frontend/features/Build/graphql/fragments/SmartReply.fragment.graphql';
import { SuggestionDialogueFragment } from 'frontend/features/Build/graphql/fragments/SuggestionDialogueFragment';
import LibraryDialogueFragment from 'frontend/features/Library/fragments/LibraryDialogue.fragment.graphql';
import { DIALOGUE_OUTPUT_SLOT } from 'frontend/features/Slots/fragments';

export const DialogueFragment = gql`
  fragment Dialogue on BuildItemInterfaceType {
    ...LibraryDialogue
    ... on DialogueType {
      slug
      keywords
      buttonsDisplayOrientation
      context
      patterns
      patternKey
      webhookUrls
      urlTrigger
      urlTriggerType
      videoSources
      advancedOptions
      eventMessage
      breadcrumbs
      dialogueFallback
      subscribedSkillName(botId: $botId)
      isSubscriptionDialogue(botId: $botId)
      intent
      dialogueRules {
        id
        languageCode
        operator
        useUserTimezone
        conditions {
          ... on DateConditionAt {
            id
            category
            operator
            at
          }
          ... on DateConditionRange {
            id
            category
            operator
            start
            end
          }
          ... on TimeConditionAt {
            id
            category
            operator
            at
          }
          ... on TimeConditionRange {
            id
            category
            operator
            start
            end
          }
          ... on TimeConditionHandover {
            id
            category
            operator
            handoverStatus
          }
          ... on TimeConditionWeekday {
            id
            category
            operator
            weekdays
          }
          ... on ContextConditionContains {
            id
            category
            operator
            contextKey
            contextValue
          }
          ... on ContextConditionEquals {
            id
            category
            operator
            contextKey
            contextValue
          }
          ... on ContextConditionExists {
            id
            category
            operator
            contextKey
          }
          ... on ContextConditionNotExists {
            id
            category
            operator
            contextKey
          }
        }
        name
        order
      }
      mod(botId: $botId) {
        ...DialogueMod
      }
      buildForms {
        ...BuildForms
      }
      labels {
        ...Label
      }
      buttons {
        ...Button
      }
      replies {
        ...Reply
      }
      smartReplies {
        ...SmartReply
      }
      samples {
        ...Sample
      }
      plugininstance {
        ...PluginInstance
      }
      outputSlots {
        ...DialogueOutputSlot
      }
      imageCarousels {
        ...ImageCarousel
      }
    }
    ... on SuggestionDialogueType {
      ...SuggestionDialogue
    }
  }
  ${LibraryDialogueFragment}
  ${DialogueModFragment}
  ${LabelFragment}
  ${ButtonFragment}
  ${Reply}
  ${SmartReplyFragment}
  ${sampleFragment}
  ${PluginInstanceFragment}
  ${SuggestionDialogueFragment}
  ${DIALOGUE_OUTPUT_SLOT}
  ${ImageCarousel}
  ${BuildForms}
`;
