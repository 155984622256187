import type { MessageType } from 'frontend/api/generated';
import {
  Agent,
  Check,
  DownloadCloud,
  Email,
  ExitCircle,
  Handover,
  Link,
  LinkBreak,
  Phone,
  QuickReply,
  Send,
  SettingsSliders,
  Trash,
  Trigger,
  UploadCloud,
} from 'frontend/assets/icons';

export const dialogueFeatureTypes = Object.freeze({
  NONE: 'NONE',
  PLUGIN: 'PLUGIN',
  PLUGIN_DELETED: 'PLUGIN_DELETED',
  WEBHOOK: 'WEBHOOK',
});
export type DialogueFeature = keyof typeof dialogueFeatureTypes;

export const LINK_STATE = Object.freeze({
  OK: 'OK',
  BROKEN: 'BROKEN',
  UNKNOWN: 'UNKNOWN',
});

export const BUTTON_TYPES = Object.freeze({
  QUICK_REPLY: 'quick_reply',
  EXTERNAL_LINK: 'link',
  EMAIL: 'email',
  PHONE: 'phone',
  HANDOVER_REQUEST: 'takeover_request',
  PRIVACY_EXPORT: 'privacy_export',
  PRIVACY_DELETE: 'privacy_delete',
  DIALOGUE_TRIGGER: 'dialogue_trigger',
  CONTACT_DETAILS: 'contact_details',
  ABORT_FOLLOWUP: 'abort_followup',
  UPLOAD_ATTACHMENT: 'upload_attachment',
  SUGGESTION_DIALOGUE_TRIGGER: 'suggestion_dialogue_trigger',
  CHECKBOX: 'checkbox',
  SUBMIT: 'submit',
  SLIDER: 'slider',
});

export const NON_STANDARD_BUTTON_TYPES = [BUTTON_TYPES.SUGGESTION_DIALOGUE_TRIGGER];

export const URL_DIALOGUE_TYPES = Object.freeze({
  URL_PATH_MATCH: 'Url path match',
  EXACT_MATCH: 'Exact match',
});

export const BUTTON_CONTACT_DETAILS_TYPES = Object.freeze({
  EMAIL: 'email',
  PHONE: 'phone_number',
});

export const BUTTON_TYPE_NAMES = Object.freeze({
  [BUTTON_TYPES.QUICK_REPLY]: 'Quick reply',
  [BUTTON_TYPES.EXTERNAL_LINK]: 'External link',
  [LINK_STATE.BROKEN]: 'Broken link',
  [BUTTON_TYPES.EMAIL]: 'Email',
  [BUTTON_TYPES.PHONE]: 'Phone',
  [BUTTON_TYPES.HANDOVER_REQUEST]: 'Handover request',
  [BUTTON_TYPES.PRIVACY_EXPORT]: 'Export chat conversation',
  [BUTTON_TYPES.PRIVACY_DELETE]: 'Delete chat conversation',
  [BUTTON_TYPES.DIALOGUE_TRIGGER]: 'Trigger another dialogue',
  [BUTTON_TYPES.CONTACT_DETAILS]: 'Leave contact details',
  [BUTTON_TYPES.ABORT_FOLLOWUP]: 'Cancel followup',
  [BUTTON_TYPES.CHECKBOX]: 'Checkbox',
  [BUTTON_TYPES.SUBMIT]: 'Submit',
  [BUTTON_TYPES.UPLOAD_ATTACHMENT]: 'Upload attachment',
  [BUTTON_TYPES.SLIDER]: 'Slider',
});

export const TYPE_TO_ICON = Object.freeze({
  [BUTTON_TYPES.EXTERNAL_LINK]: Link,
  [LINK_STATE.BROKEN]: LinkBreak,
  [BUTTON_TYPES.DIALOGUE_TRIGGER]: Trigger,
  [BUTTON_TYPES.CONTACT_DETAILS]: Agent,
  [BUTTON_TYPES.QUICK_REPLY]: QuickReply,
  [BUTTON_TYPES.EMAIL]: Email,
  [BUTTON_TYPES.PHONE]: Phone,
  [BUTTON_TYPES.HANDOVER_REQUEST]: Handover,
  [BUTTON_TYPES.PRIVACY_EXPORT]: DownloadCloud,
  [BUTTON_TYPES.PRIVACY_DELETE]: Trash,
  [BUTTON_TYPES.ABORT_FOLLOWUP]: ExitCircle,
  [BUTTON_TYPES.CHECKBOX]: Check,
  [BUTTON_TYPES.SUBMIT]: Send,
  [BUTTON_TYPES.UPLOAD_ATTACHMENT]: UploadCloud,
  [BUTTON_TYPES.SLIDER]: SettingsSliders,
});

export const SLIDER_AFFIX_TYPES = Object.freeze({
  NONE: '',
  SUFFIX: 'SUFFIX',
  PREFIX: 'PREFIX',
});

export const DEFAULT_RULE_ID = 'defaultRule';
export const DEFAULT_RULE_OPERATOR = 'AND';

export const EVENT_MESSAGES_SYSTEM_TYPES: MessageType[] = [
  'REQUEST',
  'CANCEL',
  'CLOSED',
  'WAITING_FALLBACK',
  'START',
  'END',
  'USER_LEFT',
  'AGENT_ASSIGNED',
];
