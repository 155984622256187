import React from 'react';

import type { ChatFeedbackType } from 'frontend/api/generated';

import styles from './Feedback.scss';
import FeedbackSentiment from '../FeedbackSentiment/FeedbackSentiment';

const TRIGGER_TYPE_TO_LABEL = {
  bot_conversation: 'Bot conversation',
  handover_conversation: 'Handover conversation',
};

const Feedback = ({ feedback }: { feedback?: ChatFeedbackType }) => {
  if (!feedback) return null;

  const triggerTypeLabel = feedback.triggerType ? TRIGGER_TYPE_TO_LABEL[feedback.triggerType] : null;

  return (
    <div className={styles.feedbackWrapper}>
      <div className={styles.feedbackHeaderContainer}>
        <div className={styles.feedbackHeader}>
          <FeedbackSentiment data={feedback} showFreeformText={false} />
          <div className={styles.feedbackRating}>{feedback.rating}</div>
        </div>
        {!!feedback.buttonsChosen?.length && (
          <div className={styles.feedbackButtons}>
            {feedback.buttonsChosen?.map((text, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`feedback-button-${feedback.chatId}-${index}`} className={styles.feedbackButton}>
                {text}
              </div>
            ))}
          </div>
        )}

        {feedback.freeformText && <q className={styles.feedbackText}>{feedback.freeformText}</q>}
      </div>
      <div className={styles.feedbackFooterContainer}>
        <div className={styles.feedbackTriggered}>
          <div className={styles.feedbackTriggeredTitle}>Feedback triggered:</div>
          <div className={styles.feedbackTriggeredText}>{triggerTypeLabel}</div>
        </div>
        {feedback.formTitle && (
          <div className={styles.feedbackTriggered}>
            <div className={styles.feedbackTriggeredTitle}>Form triggered:</div>
            <div className={styles.feedbackTriggeredText}>{feedback.formTitle}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
