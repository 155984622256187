import { useQuery } from '@apollo/client';
import cx from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';

import { DialogueListDocument } from 'frontend/api/generated';
import { Close, Info, Settings } from 'frontend/assets/icons';
import { Button, Checkbox, Icon, Input, SelectDialogue } from 'frontend/components';
import Portal from 'frontend/components/Portal';
import { FIELD_COLOR } from 'frontend/constants';
import { Modal, useModal } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { useBotOrSkill } from 'frontend/hooks';
import type { PartialDialogue } from 'frontend/types/dialogue';

import styles from './FormSettings.scss';

interface FormSettings {
  onClose: () => void;
  onFormRemove: () => void;
  currentForm: string;
  currentRuleId: string;
  currentLanguage: string;
}

interface DescriptionModal {
  hide: () => Promise<void>;
  args: {
    title: string;
    description: string;
  };
}

const DescriptionModal = ({ hide, args: { title, description } }: DescriptionModal) => (
  <Modal
    footerClassName={styles.formModalFooter}
    headerClassName={styles.formModalHeader}
    hide={hide}
    title={title}
    footer
    hideCancel
  >
    <Modal.Content className={styles.formModalContent}>{description}</Modal.Content>
  </Modal>
);

// TODO maybe do a more generic component for the panel including the RightSidePanel component
const FormSettings = ({
  onClose,
  onFormRemove,
  currentForm,
  currentLanguage,
  currentRuleId,
}: FormSettings): React.JSX.Element => {
  const [showModal] = useModal(DescriptionModal);
  const { buildIdObject } = useBotOrSkill();
  const { data, loading } = useQuery(DialogueListDocument, {
    variables: { ...buildIdObject, includeSubscribedSkills: true, excludeGreetings: true },
    fetchPolicy: 'network-only',
  });

  const dialogues: PartialDialogue[] = data?.dialogueList ?? [];
  return (
    <Portal attachElementId="dialogue-form-contents" wrapperId="form-settings">
      <div className={styles.formSettingsWrapper}>
        <div className={styles.formSettingsHeader}>
          <h2 className={styles.heading}>
            <Icon component={Settings} /> Form settings
          </h2>
          <button type="button" className={styles.closeButton} onClick={onClose}>
            <Icon component={Close} />
          </button>
        </div>
        <div className={styles.formSettingsContent}>
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              type="text"
              label={<div className={styles.labelContainer}>Form title</div>}
              placeholder="Add form title"
              name={`${currentForm}.texts.title`}
              fieldColor={FIELD_COLOR.MISCHKA}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formSettingsInput}>
            <Field
              component={SelectDialogue}
              placeholder="Dialogue"
              label={
                <div className={styles.labelContainer}>
                  Form submission dialogue
                  <Icon
                    component={Info}
                    onClick={() =>
                      showModal({
                        title: 'Form submission dialogue',
                        description: 'Form submission dialogue is a dialogue that is triggered after a form',
                      })
                    }
                  />
                </div>
              }
              name={`${currentForm}.submitDialogueId`}
              dialogues={dialogues}
              loading={loading}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              type="text"
              label={<div className={styles.labelContainer}>Submit button text</div>}
              placeholder="Add button text"
              name={`${currentForm}.texts.submitButtonText`}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formHorizontalSeparator} />
          <div className={styles.formSettingsInput}>
            <Field
              component={SelectDialogue}
              placeholder="Dialogue"
              validate={required}
              label={
                <div className={styles.labelContainer}>
                  Abandoned form dialogue
                  <Icon
                    component={Info}
                    onClick={() =>
                      showModal({
                        title: 'Abandoned form dialogue',
                        description:
                          'Abandoned form dialogue is a dialogue to inform users that they are leaving the form and will have to fill in the form again if they do.',
                      })
                    }
                  />
                </div>
              }
              name={`${currentForm}.cancelDialogueId`}
              dialogues={dialogues ?? []}
              loading={loading}
              fieldColor={FIELD_COLOR.MISCHKA}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              type="text"
              label={<div className={styles.labelContainer}>Cancel button text</div>}
              placeholder="Add button text"
              name={`${currentForm}.texts.cancelButtonText`}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formHorizontalSeparator} />
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              multiline
              label={<div className={styles.labelContainer}>Unanswered text</div>}
              placeholder="Add form unanswered text"
              name={`${currentForm}.texts.unansweredText`}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              multiline
              label={<div className={styles.labelContainer}>Exited text</div>}
              placeholder="Add form exited text"
              name={`${currentForm}.texts.cancelText`}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formSettingsInput}>
            <Field
              component={Input}
              multiline
              label={<div className={styles.labelContainer}>Error text</div>}
              placeholder="Add form error text"
              name={`${currentForm}.texts.errorText`}
              fieldColor={FIELD_COLOR.MISCHKA}
              validate={required}
              errorPosition="bottom"
            />
          </div>
          <div className={styles.formHorizontalSeparator} />
          <div className={styles.formSettingsInput}>
            <Field
              component={Checkbox}
              label={
                <div className={cx(styles.labelContainer, styles.checkbox)}>Chat bubble: Disabled text input field</div>
              }
              name={`advancedOptions.${currentLanguage}.${currentRuleId}.chatbubbleHideInputField`}
              type="checkbox"
            />
            <div className={styles.formSettingsInputDescription}>
              This will disable the user&apos;s input field and their ability to write questions.
            </div>
          </div>
          <div className={styles.formSettingsAction}>
            <Button text="Delete form" onClick={onFormRemove} />
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default FormSettings;
