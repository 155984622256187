import React, { createContext, useMemo, useState } from 'react';

interface StepState {
  currentStep?: number;
  values: any[];
}

interface StepsState {
  [key: string]: StepState;
}

interface FilterContextValue {
  steps: StepsState | null;
  setSteps: React.Dispatch<React.SetStateAction<StepsState | null>>;
}

interface FilterContextProviderProps {
  children: React.ReactNode;
}

export const FilterContext = createContext<FilterContextValue>({
  steps: null,
  setSteps: () => {},
});

const FilterContextProvider = ({ children }: FilterContextProviderProps) => {
  const [steps, setSteps] = useState<StepsState | null>(null);

  const value = useMemo(() => ({ steps, setSteps }), [steps]);

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterContextProvider;
