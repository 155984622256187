import { type ReactNode, useLayoutEffect, useState } from 'react';

import { Alert, Close } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import { useModal } from 'frontend/features/Modals';

import DeprecationModal from './DeprecationModal';
import styles from './DeprecationWarningBanner.scss';

const TWO_WEEKS_MS = 14 * 24 * 60 * 60 * 1000;

interface Props {
  /** The text to display in the banner. */
  text: string;
  /** The key to use for Local Storage. */
  storageKey: string;
  /** The information to display in the modal. */
  modalInfo: {
    heading: string;
    text: ReactNode;
  };
}

export default function DeprecationWarningBanner({ text, storageKey, modalInfo }: Props): React.JSX.Element | null {
  const [isVisible, setIsVisible] = useState(false);
  const [showModal] = useModal(DeprecationModal);

  const bannerKey = `deprecation_banner_${storageKey}_hidden_until`;

  useLayoutEffect(() => {
    const hiddenUntil = localStorage.getItem(bannerKey);
    const shouldShow = !hiddenUntil || new Date(hiddenUntil) < new Date();
    setIsVisible(shouldShow);
  }, [bannerKey]);

  const handleClick = () => {
    showModal({
      heading: modalInfo.heading,
      text: modalInfo.text,
    });
  };

  const handleDismiss = () => {
    const expirationDate = new Date(Date.now() + TWO_WEEKS_MS).toISOString();
    localStorage.setItem(bannerKey, expirationDate);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: bannerKey,
        newValue: expirationDate,
      }),
    );
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.container}>
      <Icon component={Alert} className={styles.warningIcon} />
      <span className="font-weight-medium">{text}</span>
      <button className={styles.link} onClick={handleClick} type="button">
        Click here to read more
      </button>
      <button className={styles.closeButton} onClick={handleDismiss} type="button" aria-label="Dismiss warning">
        <Icon component={Close} />
      </button>
    </div>
  );
}
