import type { ReactNode } from 'react';

import { Alert } from 'frontend/assets/icons';

import styles from './DeprecationModal.scss';
import Modal from '../Modals/components/Modal/Modal';

interface Props {
  args: { text: ReactNode; heading: string; hide: () => Promise<void> };
  hide: () => Promise<void>;
}

export default function DeprecationModal({ args: { text, heading }, hide }: Props): React.JSX.Element {
  return (
    <Modal
      icon={Alert}
      headerClassName={styles.header}
      hide={hide}
      title={heading}
      footer
      hideCancel
      onOkText="Understood"
    >
      <Modal.Content>{text}</Modal.Content>
    </Modal>
  );
}
