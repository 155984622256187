export const LUZMO_DASHBOARDS = {
  AUTHENTICATED: {
    slug: 'authenticated',
    luzmoId: '24b04d9f-4e8b-47fe-ac04-b11acfeb38ca',
    route: 'authenticated',
    queryParamsGroup: 'A',
  },
  BEHAVIOR: {
    slug: 'behavior',
    luzmoId: 'e0c03516-76d7-4def-9b58-f1e3fb557189',
    route: 'behavior',
    queryParamsGroup: 'A',
  },
  BOT_DASHBOARD: {
    slug: 'botdashboard',
    luzmoId: 'ece68fb3-c7ce-4a0f-ae25-9af743e0d408',
    route: 'bot-dashboard',
    queryParamsGroup: 'A',
  },
  BOT_FEEDBACK: {
    slug: 'botfeedback',
    luzmoId: '82d23d1f-1421-464e-9fef-ed9362ba4861',
    route: 'bot-feedback',
    queryParamsGroup: 'A',
  },
  CONTAINMENT_RATE: {
    slug: 'containmentrate',
    luzmoId: 'f4e8d5cc-2f4e-4e15-9627-f4c1217c27ff',
    route: 'containment-rate',
    queryParamsGroup: 'A',
  },
  HANDOVER_FEEDBACK: {
    slug: 'handoverfeedback',
    luzmoId: '35fdacac-96c7-4040-8e11-ee2c083eb4a3',
    route: 'handover-feedback',
    queryParamsGroup: 'A',
  },
  KINDLY_GPT: {
    slug: 'kindlygpt',
    luzmoId: 'b308515e-9c98-428f-8f3f-11600e5010a3',
    route: 'kindly-gpt',
    queryParamsGroup: 'A',
  },
  LABELS: { slug: 'labels', luzmoId: 'c8bba153-577d-4db4-aee6-b1838b4b0998', route: 'labels', queryParamsGroup: 'A' },
  TOPICS: {
    slug: 'topicsoverview',
    luzmoId: 'f0139a9c-d48d-4475-bc77-f42559d55754',
    route: 'topics',
    queryParamsGroup: 'A',
  },
  USAGE: { slug: 'usage', luzmoId: '4e3d2dbf-1c7c-4b68-a88b-58343f982792', route: 'usage', queryParamsGroup: 'A' },
  ESTIMATED_COSTS: {
    slug: 'estimatedcosts',
    luzmoId: 'c0367d80-5053-41fc-9db0-0ecc864594a3',
    route: 'billing',
    queryParamsGroup: 'C',
  },
  EMAIL_DETAILS: {
    slug: 'emaildetails',
    luzmoId: '762cd16c-d6a9-4540-8271-dd10c16dceac',
    route: 'email-details',
    queryParamsGroup: 'B',
  },
  EMAIL_OVERVIEW: {
    slug: 'emailoverview',
    luzmoId: '3921e3e5-fd08-442d-b371-df578f9967c2',
    route: 'email-overview',
    queryParamsGroup: 'B',
  },
  NUDGE_DETAILS: {
    slug: 'nudgedetails',
    luzmoId: 'b3d14823-6bad-4010-8a7c-c33c82f04d67',
    route: 'nudge-details',
    queryParamsGroup: 'B',
  },
  NUDGE_OVERVIEW: {
    slug: 'nudgeoverview',
    luzmoId: '6edc48dc-3ef3-4a33-b17f-0cd55e603a0e',
    route: 'nudge-overview',
    queryParamsGroup: 'B',
  },
};
