import { curry } from 'lodash';

import { IsFolderInFolderDocument } from 'frontend/api/generated';
import { dragAndDropTypes, isRegularDialogueType } from 'frontend/constants';

import { updateDialogueParentOnRemove, updateLibraryDialogues, updateLibraryTopics } from '../../cacheHelpers';
import { getDialogueParentVariables } from '../../utils';

export const canDrop = curry(
  (
    { isSubscription, id },
    { dialogueType, type, id: dropId, topicId, parentTopicId, parentId, dialogueModParentId },
  ) => {
    if (isSubscription) return false;
    if (type === dragAndDropTypes.FOLDER) return ![dropId, parentTopicId].includes(id);
    if (type === dragAndDropTypes.DIALOGUE) {
      return (topicId !== id || parentId || dialogueModParentId) && isRegularDialogueType(dialogueType);
    }
    return false;
  },
);

export async function dropDialogue({ item, buildIdObject, client, id, dialogueInTopic, toast, name, currentLanguage }) {
  const dropVariables = { ...buildIdObject, sourceDialogueId: item.id, targetTopicId: id };

  let result;
  try {
    result = await dialogueInTopic({ variables: dropVariables });
  } catch (error) {
    toast.error(error.message);
    return;
  }
  const addedDialogue = result.data.dropDialogueInFolder;
  const variables = { ...buildIdObject, topicId: id, regular: true };

  // Update original parent of dropped dialogue
  updateDialogueParentOnRemove({ dialogue: item, buildIdObject, client });
  // Update dialogues in this topic
  updateLibraryDialogues({ variables, client, addedDialogue });

  toast.success(`Dialogue '${item.title[currentLanguage]}' was moved to '${name}'`);
}

export async function dropFolder({ topicInTopic, id, item, client, buildIdObject, toast, name }) {
  const { data } = await client.query({
    query: IsFolderInFolderDocument,
    variables: { ...buildIdObject, folderId: id, parentFolderId: item.id },
  });
  if (data.topic.isInFolder) {
    toast.error('Cannot drop folder in its own subfolder');
    return;
  }

  const result = await topicInTopic({ variables: { ...buildIdObject, sourceTopicId: item.id, targetTopicId: id } });
  const topic = result.data.dropTopicInTopic;

  // Update subtopics in this topic
  updateLibraryTopics({ variables: { ...buildIdObject, parentTopicId: id }, client, addedTopic: topic });

  // Update original parent of dropped topic
  const variables = getDialogueParentVariables(buildIdObject, item);
  updateLibraryTopics({ variables, client, removedTopic: topic });

  toast.success(`Folder '${item.name}' was moved to '${name}'`);
}
