import { gql } from '@apollo/client';

import SampleCandidate from 'frontend/features/BotImprovements/SampleCandidate/fragments/SampleCandidate.fragment.graphql';

export default gql`
  mutation UndoArchivedSampleCandidate($botId: ID!, $sampleCandidateId: UUID!) {
    undoArchivedSampleCandidate(botId: $botId, sampleCandidateId: $sampleCandidateId) {
      ...SampleCandidate
    }
  }
  ${SampleCandidate}
`;
