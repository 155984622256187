import type { ApolloClient } from '@apollo/client';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import {
  type DropDialogueInDialogueMutation,
  type DropDialogueInFolderMutation,
  LibraryDialoguesDocument,
} from 'frontend/api/generated';

type DroppedDialogue = NonNullable<
  DropDialogueInDialogueMutation['dropDialogueInDialogue'] | DropDialogueInFolderMutation['dropDialogueInFolder']
>;
type Props = {
  variables: Record<string, unknown>;
  client: ApolloClient<object>;
} & (
  | {
      addedDialogue: DroppedDialogue;
      removedDialogue?: never;
    }
  | {
      addedDialogue?: never;
      removedDialogue: DroppedDialogue;
    }
);
export default ({ variables, client, removedDialogue, addedDialogue }: Props) =>
  updateArrayInCache({
    variables,
    pathToArrayInCache: 'dialogues',
    query: LibraryDialoguesDocument,
    cache: client,
    arrayUpdate: (dialogues: DroppedDialogue[]) =>
      addedDialogue
        ? [...dialogues, addedDialogue]
        : dialogues.filter(({ id: dialogueId }) => dialogueId !== removedDialogue.id),
  });
