import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { softDeleteInCache } from 'frontend/api/cacheHelpers';
import { DeleteUserDocument } from 'frontend/api/generated';
import UserDetail from 'frontend/features/Admin/views/Users/fragments/UserDetail.fragment.graphql';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';

const update = softDeleteInCache({
  fragment: UserDetail,
  typeName: 'UserType',
  mutationName: 'deleteUser',
  fragmentName: 'UserDetail',
});

export default function DeleteUser({
  hide,
  args: { email, id, onDelete },
}: {
  hide: () => void;
  args: { email: string; id: string; onDelete?: () => void };
}) {
  const toast = useToast();
  const [remove] = useMutation(DeleteUserDocument, { variables: { userId: id }, update });
  const onSubmit = useCallback(async () => {
    await remove();
    onDelete?.();
    toast.success(`User ${email} was deleted!`);
  }, [email, onDelete, remove, toast]);

  return <DeleteModal name={`user ${email}`} titleName="user" onSubmit={onSubmit} hide={hide} />;
}
