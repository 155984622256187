import type { Mutator } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';

import { FormErrors, GuardUnsaved, Input, LoaderSwitch, PageBar, Panel, SelectLanguage } from 'frontend/components';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { chain, max, required } from 'frontend/form/validators';

import styles from './styles.scss';
import chatBubbleStyles from '../../styles.scss';

const SUBSCRIBE = {};

export default function TextContent() {
  const { onSubmit, initialValues, currentLanguage, loading, languagesLoading, languages } = useChatbubbleForm();

  return (
    <LoaderSwitch loading={loading || languagesLoading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={
          arrayMutators as unknown as {
            [key: string]: Mutator<any, { [x: string]: any; [x: number]: any; [x: symbol]: any }>;
          }
        }
        subscribe={SUBSCRIBE}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {languages.length > 1 && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <div className={styles.inputContainer}>
                    <h3 className="m-b-lg">Text content</h3>
                    <Field
                      component={Input}
                      id="Input-placeholder"
                      name={`placeholder.${currentLanguage}`}
                      label="Helper text for the user's text input field"
                      className="m-b-md m-t-sm"
                      placeholder="Ask me a question..."
                      inputLimit={25}
                      validate={chain([required, max(25)])}
                    />
                  </div>
                  <div className="buttons-container">
                    <p className={styles.paragraph}>
                      The chat bubble has its very own settings page with lots of useful buttons! Change the button
                      texts by editing the fields below.
                    </p>

                    <div className={styles.inputContainer}>
                      <Field
                        component={Input}
                        id="Input-change-language-button"
                        name={`changeLanguageButton.${currentLanguage}`}
                        placeholder="Change language"
                        inputLimit={30}
                        aria-label="Changing the language"
                        validate={chain([required, max(30)])}
                        label="Changing the language"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-start-over-button"
                        name={`startOverButton.${currentLanguage}`}
                        placeholder="Start over"
                        inputLimit={30}
                        aria-label="Resetting the conversation"
                        validate={chain([required, max(30)])}
                        label="Resetting the conversation"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-delete-button"
                        name={`deleteButton.${currentLanguage}`}
                        inputLimit={30}
                        aria-label="Deleting the conversation"
                        placeholder="Are you sure you want to delete the chat log?"
                        validate={chain([required, max(30)])}
                        label="Deleting the conversation"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-download-button"
                        name={`downloadButton.${currentLanguage}`}
                        placeholder="Download chat"
                        inputLimit={30}
                        aria-label="Downloading the conversation"
                        validate={chain([required, max(30)])}
                        label="Downloading the conversation"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-back-to-chat-button"
                        name={`backToChatButton.${currentLanguage}`}
                        placeholder="Return to chat"
                        inputLimit={30}
                        aria-label="Returning to chat"
                        validate={chain([required, max(30)])}
                        label="Returning to chat"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-cancel-chat-button"
                        name={`cancelChatButton.${currentLanguage}`}
                        placeholder="Cancel chat"
                        inputLimit={30}
                        aria-label="Confirming the ending of a conversation"
                        validate={chain([required, max(30)])}
                        label="Confirming the ending of a conversation"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-confirm-action-button"
                        name={`confirmActionButton.${currentLanguage}`}
                        placeholder="Confirm"
                        inputLimit={30}
                        aria-label="Confirming action on settings page"
                        validate={chain([required, max(30)])}
                        label="Confirming action on settings page"
                        className="m-b-3"
                      />
                    </div>
                  </div>
                  <div className="text-container">
                    <p className={styles.paragraph}>
                      The text on the settings page can be customized to your taste! Just edit the fields below.
                    </p>
                    <div className={styles.inputContainer}>
                      <Field
                        component={Input}
                        id="Input-delete-text"
                        name={`deleteText.${currentLanguage}`}
                        placeholder="Delete chat log"
                        aria-label="Confirming chat deletion"
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Confirming chat deletion"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-change-language-text"
                        name={`changeLanguageText.${currentLanguage}`}
                        aria-label="Changing the language helper text"
                        placeholder="The chat will reset when you change the language"
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Changing the language helper text"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-download-format-text"
                        name={`downloadFormatText.${currentLanguage}`}
                        aria-label="Choosing chat log format"
                        placeholder="Select the format best suited for you. Not sure? Choose HTML."
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Choosing chat log format"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-cancel-chat-text"
                        name={`cancelChatText.${currentLanguage}`}
                        aria-label="Ending a conversation"
                        placeholder="Would you like to cancel the chat?"
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Ending a conversation"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-chat-deleted-header"
                        name={`chatDeletedHeader.${currentLanguage}`}
                        aria-label="Deleted chat header"
                        placeholder="Would you like to cancel the chat?"
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Deleted chat header"
                        className="m-b-3"
                      />
                      <Field
                        component={Input}
                        id="Input-chat-deleted-text"
                        name={`chatDeletedText.${currentLanguage}`}
                        aria-label="Deleted chat text"
                        placeholder="Would you like to cancel the chat?"
                        inputLimit={100}
                        validate={chain([required, max(100)])}
                        label="Deleted chat text"
                        className="m-b-3"
                      />
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-disabled-input"
                          name={`disabledInputText.${currentLanguage}`}
                          aria-label="Disabled input field"
                          placeholder="Select any of the buttons above"
                          inputLimit={35}
                          validate={chain([required, max(35)])}
                          label="Disabled input field"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-agent-joined"
                          name={`agentJoinedText.${currentLanguage}`}
                          aria-label="Agent joined field"
                          placeholder="has joined the conversation"
                          inputLimit={80}
                          validate={chain([required, max(80)])}
                          label="Agent joined text (will be preceded by the agents name)"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-agent-left"
                          name={`agentLeftText.${currentLanguage}`}
                          aria-label="Agent left field"
                          placeholder="has left the conversation"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="Agent left text (will be preceded by the agents name)"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-handover-queue"
                          name={`handoverQueueText.${currentLanguage}`}
                          aria-label="Handover queue field"
                          placeholder="Waiting for manual chat"
                          inputLimit={25}
                          validate={chain([required, max(25)])}
                          label="Handover queue text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-handover-queue-link"
                          name={`handoverQueueLink.${currentLanguage}`}
                          aria-label="Handover queue link field"
                          placeholder="Leave queue"
                          inputLimit={15}
                          validate={chain([required, max(15)])}
                          label="Handover queue link text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-leaving-queue"
                          name={`leaveQueueText.${currentLanguage}`}
                          aria-label="Leaving queue field"
                          placeholder="Leaving queue"
                          inputLimit={15}
                          validate={chain([required, max(15)])}
                          label="Leaving queue text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-leaving-queue-link"
                          name={`leaveQueueLink.${currentLanguage}`}
                          aria-label="Leaving queue link field"
                          placeholder="Stay in queue"
                          inputLimit={25}
                          validate={chain([required, max(25)])}
                          label="Leaving queue link text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-settings_button"
                          name={`settingsButton.${currentLanguage}`}
                          aria-label="Input settings button text"
                          placeholder="Settings button"
                          inputLimit={35}
                          validate={chain([required, max(35)])}
                          label="Settings button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-settings-header"
                          name={`settingsHeader.${currentLanguage}`}
                          aria-label="Input settings header text"
                          placeholder="Settings header"
                          inputLimit={50}
                          validate={chain([required, max(50)])}
                          label="Settings header text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-cancel-button"
                          name={`cancelButton.${currentLanguage}`}
                          aria-label="Input cancel button text"
                          placeholder="Cancel button"
                          inputLimit={35}
                          validate={chain([required, max(35)])}
                          label="Cancel button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-upload-file-text"
                          name={`uploadFileText.${currentLanguage}`}
                          aria-label="Upload file text input"
                          placeholder="Upload file"
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="Upload file text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-upload-file-subtext"
                          name={`uploadFileSubtext.${currentLanguage}`}
                          aria-label="Upload file subtext input"
                          placeholder="Upload file subtext"
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="Upload file subtext"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-upload-file-failed"
                          name={`uploadFileFailed.${currentLanguage}`}
                          aria-label="Upload file failed text input"
                          placeholder="Upload file failed"
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="Upload file failed text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-ios-file-selector-button"
                          name={`iosFileSelectorButton.${currentLanguage}`}
                          aria-label="iOS file selector button text input"
                          placeholder="iOS file selector button"
                          inputLimit={35}
                          validate={chain([required, max(35)])}
                          label="iOS file selecetor button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-ios-photo-selector-button"
                          name={`iosPhotoSelectorButton.${currentLanguage}`}
                          aria-label="iOS photo selector button text input"
                          placeholder="iOS photo selector button"
                          inputLimit={35}
                          validate={chain([required, max(35)])}
                          label="iOS photo selecetor button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-gpt-search-text"
                          name={`gptSearchText.${currentLanguage}`}
                          aria-label="GPT searching text input"
                          placeholder="GPT searching text"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="GPT searching text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-gpt-search-result-text"
                          name={`gptSearchResultText.${currentLanguage}`}
                          aria-label="GPT search result text input"
                          placeholder="GPT search result text"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="GPT search result text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-gpt-tooltip-disclaimer"
                          name={`gptTooltipDisclaimer.${currentLanguage}`}
                          aria-label="GPT tooltip disclaimer text input"
                          placeholder="GPT tooltip disclaimer"
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="GPT tooltip disclaimer"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-reference-list-title"
                          name={`referenceListTitle.${currentLanguage}`}
                          aria-label="Reference list title input"
                          placeholder="Reference list title"
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="Reference list title"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-read-more"
                          name={`readMoreText.${currentLanguage}`}
                          aria-label="read more text input"
                          placeholder="Read more button"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="Read more button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-file-limitation"
                          name={`fileLimitation.${currentLanguage}`}
                          aria-label="File and size limitations"
                          placeholder="File and size limitations"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="File input help text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-file-max-size"
                          name={`fileMaximumSize.${currentLanguage}`}
                          aria-label="Maximum file size text"
                          placeholder="Maximum file size text"
                          inputLimit={40}
                          validate={chain([required, max(40)])}
                          label="Maximum file size tooltip text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-queue-position"
                          name={`queuePositionText.${currentLanguage}`}
                          aria-label="queue position text input"
                          placeholder="Position in queue"
                          inputLimit={40}
                          validate={chain([required, max(100)])}
                          label="Queue position text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="Input-read-more"
                          name={`estimatedTimeText.${currentLanguage}`}
                          aria-label="estimated time text input"
                          placeholder="Estimated time"
                          inputLimit={40}
                          validate={chain([required, max(100)])}
                          label="Estimated time text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="input-chat-agent"
                          name={`chattingWithAgentText.${currentLanguage}`}
                          aria-label="chatting with agent text input"
                          placeholder="Chatting with"
                          inputLimit={25}
                          validate={chain([required, max(25)])}
                          label="Chatting with agent text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="input-user-end-handover-button-text"
                          name={`userEndHandoverButtonText.${currentLanguage}`}
                          aria-label="User end handover button text input"
                          placeholder="End human chat"
                          inputLimit={15}
                          validate={chain([required, max(15)])}
                          label="End handover button text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="input-user-end-handover-modal-title"
                          name={`userEndHandoverModalTitleText.${currentLanguage}`}
                          aria-label="User end handover modal title text input"
                          placeholder="Do you wish to end the human chat?"
                          inputLimit={50}
                          validate={chain([required, max(50)])}
                          label="End handover modal title text"
                          className="m-b-3"
                        />
                      </div>
                      <div className="text-container">
                        <Field
                          component={Input}
                          id="input-user-end-handover-modal-description-text"
                          name={`userEndHandoverModalDescriptionText.${currentLanguage}`}
                          aria-label="User end handover modal description text input"
                          placeholder="If you leave the chat you will be taken back to the chatbot."
                          inputLimit={100}
                          validate={chain([required, max(100)])}
                          label="End handover modal description text"
                          className="m-b-3"
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
}
