import cx from 'classnames';
import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Field, type FieldInputProps, useForm, useFormState } from 'react-final-form';
import InputAutosize from 'react-input-autosize';

import type { ConditionUnion, DialogueRuleType } from 'frontend/api/generated';
import { Directions, Plus } from 'frontend/assets/icons';
import { Button, Icon, Select, ToggleSwitch } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { useCurrentLanguage, useUrlSearchParams } from 'frontend/hooks';
import randomUUID from 'frontend/utils/randomUUID';

import styles from './AddRuleModal.scss';
import { type ConditionCategory, ConditionForm, formBuild } from './ConditionForm/ConditionForm';
import ConditionPicker from './ConditionPicker';

const DEFAULT_RULE_OPERATOR = 'AND';
const DEFAULT_RULE_USE_USER_TIMEZONE = false;

interface AddRuleModalProps {
  hide(): Promise<void>;
  args: {
    id?: string;
    modalType: 'create' | 'edit';
    dialogueRules: Partial<DialogueRuleType>[];
  };
}

export default function AddRuleModal({ hide, args: { id, modalType } }: AddRuleModalProps) {
  const isCreateModal = modalType === 'create';
  const [partialSubmitFailed, setPartialSubmitFailed] = useState(false);

  const [shouldShowConditionPicker, setShouldShowConditionPicker] = useState(isCreateModal);
  const form = useForm();
  const { values } = useFormState();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [, setParams] = useUrlSearchParams();

  let currentRuleIndex = (values.dialogueRules || []).findIndex((rule) => rule.id === id);
  if (currentRuleIndex === -1) {
    currentRuleIndex = (values.dialogueRules || []).length;
  }

  const currentRule: Partial<DialogueRuleType> = values.dialogueRules?.[currentRuleIndex] || {};
  const currentConditions: Array<Partial<ConditionUnion>> = currentRule?.conditions || [];

  const shouldDisplayTimezonePicker = currentConditions.some(
    (condition) => condition.category === 'TIME' || condition.category === 'DATE',
  );

  const updateConditionCategory = (ruleType: ConditionCategory) => {
    setShouldShowConditionPicker(false);
    const newConditions = [...currentConditions, { category: ruleType, id: randomUUID() }];
    form.change(`dialogueRules[${currentRuleIndex}].conditions`, newConditions);
  };

  async function handleOk() {
    setPartialSubmitFailed(true);
    const state = form.getState();
    const hasErrors = Object.keys(state.errors?.dialogueRules?.[currentRuleIndex] || {}).length > 0;
    const dialogueRule = {
      ...state.values.dialogueRules[currentRuleIndex],
      conditions: currentConditions,
    };
    const dialogueRuleId = dialogueRule.id || randomUUID();

    if (hasErrors) {
      return;
    }

    form.batch(() => {
      if (!dialogueRule.id) {
        form.change(`dialogueRules[${currentRuleIndex}].id`, dialogueRuleId);
      }
      if (!dialogueRule.languageCode) {
        form.change(`dialogueRules[${currentRuleIndex}].languageCode`, selectedLanguage);
      }
      // seems like `defaultValue` from React Final Form is buggy, so we need to set it manually in case is missing in some edge cases
      if (!dialogueRule.operator) {
        form.change(`dialogueRules[${currentRuleIndex}].operator`, DEFAULT_RULE_OPERATOR);
      }
      if (!('useUserTimezone' in dialogueRule)) {
        form.change(`dialogueRules[${currentRuleIndex}].useUserTimezone`, DEFAULT_RULE_USE_USER_TIMEZONE);
      }
      if (!dialogueRule.conditions.every((condition) => condition.operator)) {
        dialogueRule.conditions.forEach((condition) => {
          if (!condition.operator) {
            const conditionCategory = condition.category;
            const firstDefaultConditionOperator = formBuild[conditionCategory].inputs.find(
              (input) => input.name === 'operator',
            ).options[0].value;

            form.change(
              `dialogueRules[${currentRuleIndex}].conditions[${currentConditions.findIndex((c) => c.id === condition.id)}].operator`,
              firstDefaultConditionOperator,
            );
          }
        });
      }
      setParams({ rule: dialogueRuleId, formContent: 'output' });
      hide();
    });
  }

  async function handleCancel() {
    const initialRules = form.getState().initialValues.dialogueRules;
    const currentRules = form.getState().values.dialogueRules;

    // if rule existed in initial values, then reset that rule to initial value
    if (initialRules.some((initRule) => initRule.id === currentRule.id)) {
      form.change(
        `dialogueRules[${currentRuleIndex}]`,
        initialRules.find((initRule) => initRule.id === currentRule.id),
      );
    } else {
      // if rule is new, then remove it from the list
      const newRules = currentRules.slice(0, -1);
      form.change('dialogueRules', newRules);
    }

    hide();
  }

  return (
    <Modal
      hide={hide}
      closeOnSubmit={false}
      onCancel={() => handleCancel()}
      onOk={() => handleOk()}
      onOkText={isCreateModal ? 'Create' : 'Edit'}
      onCancelText="Discard"
      className={styles.modalContent}
      disabled={!currentConditions.length}
    >
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <Icon component={Directions} />
          <Field
            defaultValue={isCreateModal ? 'New rule' : undefined}
            name={`dialogueRules[${currentRuleIndex}].name`}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            component={TitleInput}
            validate={required}
            forceValidate={partialSubmitFailed}
            placeholder="Rule name"
          />
        </div>

        <span className={styles.ifText}>{isCreateModal ? 'Select trigger for this rule' : 'If'}</span>

        {currentConditions.map?.((condition, i) => (
          <React.Fragment key={condition.id}>
            <ConditionForm
              ruleIndex={currentRuleIndex}
              conditionIndex={i}
              partialSubmitFailed={partialSubmitFailed}
              condition={condition}
            />
            {i === 0 && (
              <Field
                defaultValue={DEFAULT_RULE_OPERATOR}
                name={`dialogueRules[${currentRuleIndex}].operator`}
                render={(props) => (
                  <Select
                    input={{
                      ...props.input,
                    }}
                    meta={{}}
                    wrapperClassName={cx(styles.operator, { [styles.visible]: currentConditions.length > 1 })}
                  >
                    <Select.Option
                      key={DEFAULT_RULE_OPERATOR}
                      value={DEFAULT_RULE_OPERATOR}
                      label={capitalize(DEFAULT_RULE_OPERATOR)}
                    />
                    <Select.Option key="OR" value="OR" label="Or" />
                  </Select>
                )}
              />
            )}
          </React.Fragment>
        ))}

        {currentConditions.length === 0 || shouldShowConditionPicker ? (
          <ConditionPicker onButtonClick={updateConditionCategory} />
        ) : (
          <Button
            icon={Plus}
            color="secondary"
            text="Add condition"
            onClick={() => setShouldShowConditionPicker(true)}
          />
        )}

        <div
          className={cx(styles.toggleSwitchContainer, {
            [styles.visible]: shouldDisplayTimezonePicker,
          })}
        >
          <ToggleSwitch
            defaultValue={DEFAULT_RULE_USE_USER_TIMEZONE}
            name={`dialogueRules[${currentRuleIndex}].useUserTimezone`}
            status={["User's local timezone"]}
          />
          <div className={styles.toggleSwitchDescription}>
            Selecting this option will configure this rule to follow the user&quot;s local timezone. If left unselected,
            it will adhere to the workspace timezone.
          </div>
        </div>
      </div>
    </Modal>
  );
}

function TitleInput({
  forceValidate,
  input,
  meta,
}: {
  input: FieldInputProps<string, HTMLInputElement>;
  meta: Record<string, unknown>;
  forceValidate?: boolean;
}): React.JSX.Element {
  const callbackRefForAutofocus = useCallback((node: HTMLInputElement) => {
    if (node) {
      setTimeout(() => node.focus(), 0);
    }
  }, []);

  return (
    <InputAutosize
      {...input}
      data-testid="rule-name-input"
      inputClassName={cx(styles.ruleTitle, { [styles.invalid]: (meta?.submitFailed || forceValidate) && meta.error })}
      ref={callbackRefForAutofocus}
    />
  );
}
