import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserSearchDocument, type UserSearchQuery } from 'frontend/api/generated';
import { useDebounce, useQueryParams } from 'frontend/hooks';

const FILTER_VARS = ['isAdmin'];

export default function useUsersList(pageSize: number = 25): {
  setFilters: (filters: { [k: string]: boolean }) => void;
  filters: { [k: string]: boolean };
  dirty: boolean;
  loading: boolean;
  noHits: boolean;
  onResetClick: () => void;
  users: UserSearchQuery['userSearch']['objects'];
  pagination: {
    currentPage: number;
    pages: number;
    setPage: (page: number) => void;
    summary: {
      totalCount: number;
      firstVisible: number;
      lastVisible: number;
    };
  };
  searchInput: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
} {
  const params = useQueryParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>(params.get('q') || '');
  const [filters, setFilters] = useState<{ [k: string]: boolean }>(
    Object.fromEntries(
      FILTER_VARS.map((key) => {
        const value = params.get(key);
        if (value === null) {
          return undefined;
        }
        return [value, Boolean(parseInt(value, 10))];
      }).filter((kv): kv is [string, boolean] => kv !== undefined),
    ),
  );
  const [page, setPage] = useState(parseInt(params.get('page') || '1', 10));
  const debouncedQuery = useDebounce(query, 300);

  const [runSearch, { data, loading, called }] = useLazyQuery(UserSearchDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const users = useMemo(() => data?.userSearch?.objects ?? [], [data]);
  const totalCount = useMemo(() => data?.userSearch?.hits ?? 0, [data]);

  useEffect(() => {
    if (debouncedQuery) {
      params.set('q', debouncedQuery);
    } else {
      params.delete('q');
    }

    FILTER_VARS.forEach((key) => {
      if (filters[key] === undefined) {
        params.delete(key);
      } else {
        params.set(key, filters[key] ? '1' : '0');
      }
    });

    if (page !== 1) {
      params.set('page', page.toString());
    } else {
      params.delete('page');
    }
    const prevSearch = window.location.search ? window.location.search.slice(1) : '';
    if (prevSearch !== params.toString()) {
      navigate({ search: params.toString() });
    }
  }, [filters, page, navigate, params, debouncedQuery]);

  useEffect(() => {
    runSearch({
      variables: {
        query: debouncedQuery,
        filters,
        first: pageSize,
        after: (page - 1) * pageSize,
      },
    });
  }, [pageSize, runSearch, debouncedQuery, filters, page]);

  const noHits = useMemo(() => !loading && users.length === 0 && called, [loading, users, called]);
  const dirty = useMemo(() => !isEmpty(filters) || Boolean(query) || page !== 1, [filters, query, page]);
  const pages = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

  const searchInput = useMemo(
    () => ({
      value: query,
      onChange: ({ target }) => {
        setQuery(target.value);
        setPage(1);
      },
    }),
    [setPage, setQuery, query],
  );
  const onResetClick = () => {
    setFilters({});
    setQuery('');
    setPage(1);
  };

  const pagination = useMemo(
    () => ({
      currentPage: page,
      pages,
      setPage,
      summary: {
        totalCount,
        firstVisible: (page - 1) * pageSize + 1,
        lastVisible: page === pages ? users.length : page * pageSize,
      },
    }),
    [users, page, pages, setPage, totalCount, pageSize],
  );

  return {
    setFilters,
    filters,
    dirty,
    loading,
    noHits,
    onResetClick,
    users,
    pagination,
    searchInput,
  };
}
