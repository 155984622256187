import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import invalidateCacheDialogues from 'frontend/api/cacheHelpers/invalidateCacheDialogues';
import {
  DropDialogueInFolderDocument,
  DropFolderInFolderDocument,
  type FolderType,
  LibraryDialoguesDocument,
} from 'frontend/api/generated';
import { Folder, FolderPerson, FolderPlus, KindlyBlob, Trash } from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import TransferFolder from 'frontend/features/Library/modals/TransferFolder/TransferFolder';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import { dropDialogue, dropFolder } from './utils';
import { CreateTopic, DeleteTopic, UpdateTopic } from '../../modals';
import { getBuildUrl } from '../../utils';

export const useDropInTopic = ({ setShowLoader, buildIdObject, id, name, currentLanguage }) => {
  const client = useApolloClient();
  const [dialogueInTopic] = useMutation<unknown, { sourceDialogueId: string }>(DropDialogueInFolderDocument, {
    update(cache, _, { variables }) {
      const dialogueId = variables?.sourceDialogueId;

      /* We invalidate the cache for the queries related to the connected dialogues of the moved dialogue (at starting point). */
      const connectedDialoguesAtStart: { dialogues: { id: string }[] } | null = cache.readQuery({
        query: LibraryDialoguesDocument,
        variables: { ...buildIdObject, parentId: dialogueId, regular: true, endToEnd: true },
      });
      const connectedDialoguesIdsAtStart = connectedDialoguesAtStart?.dialogues.map(({ id: dialId }) => dialId) || [];
      invalidateCacheDialogues(connectedDialoguesIdsAtStart, buildIdObject, cache);

      cache.gc();
    },
  });
  const [topicInTopic] = useMutation(DropFolderInFolderDocument);
  const toast = useToast();

  const drop = useCallback(
    async (item) => {
      setShowLoader(true);
      try {
        if (item.type === dragAndDropTypes.DIALOGUE) {
          await dropDialogue({ item, buildIdObject, client, id, dialogueInTopic, toast, name, currentLanguage });
        } else if (item.type === dragAndDropTypes.FOLDER) {
          await dropFolder({ topicInTopic, id, item, client, buildIdObject, toast, name });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [setShowLoader, buildIdObject, client, id, dialogueInTopic, toast, currentLanguage, name, topicInTopic],
  );

  return drop;
};

export const useTopicContextMenu = ({
  topic,
  botOrSkillParams,
}: {
  topic: Partial<FolderType>;
  botOrSkillParams: BotOrSkill;
}): {
  actions: MenuOverlayOptions;
  contextMenuId: string;
} => {
  const navigate = useNavigate();
  const { buildType, buildId } = useBotOrSkill();
  const [showTransferFolderModal] = useModal(TransferFolder);
  const [showCreateTopic] = useModal(CreateTopic);
  const [showUpdateTopic] = useModal(UpdateTopic);
  const [showDeleteTopic] = useModal(DeleteTopic);

  const actions = useMemo(() => {
    const target = `new?topicId=${topic.id}`;
    const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target });

    const actionsArray: MenuOverlayOptions = [
      {
        text: 'New dialogue',
        icon: KindlyBlob,
        onClick: () => navigate(newDialogueUrl),
      },
      {
        text: 'New folder',
        icon: FolderPlus,
        onClick: () => showCreateTopic({ parentTopicId: topic.id, buildIdObject: botOrSkillParams.buildIdObject }),
      },
      'separator',
      {
        text: 'Edit folder',
        icon: Folder,
        onClick: () => showUpdateTopic({ topic, buildIdObject: botOrSkillParams.buildIdObject }),
      },
      {
        text: 'Transfer folder',
        icon: FolderPerson,
        onClick: () => showTransferFolderModal({ topic, botOrSkillParams }),
      },
      'separator',
      { text: 'Delete folder', icon: Trash, onClick: () => showDeleteTopic({ topic, botOrSkillParams }), color: 'red' },
    ];

    return actionsArray;
  }, [
    topic,
    botOrSkillParams,
    buildType,
    buildId,
    navigate,
    showCreateTopic,
    showDeleteTopic,
    showUpdateTopic,
    showTransferFolderModal,
  ]);

  return { actions, contextMenuId: `library-context-menu` };
};
