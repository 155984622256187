export default [
  'advancedOptions',
  'buttonsDisplayOrientation',
  'dialogueFallback',
  'dialogueType',
  'eventMessage',
  'form',
  'intent',
  'isActive',
  'keywords',
  'patternKey',
  'patterns',
  'slug',
  'urlTrigger',
  'urlTriggerType',
  'videoSources',
  'webhookUrls',
];
