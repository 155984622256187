import cx from 'classnames';
import { Fragment, useEffect, useMemo } from 'react';

import { Loader, Pagination } from 'frontend/components';
import { usePrevious } from 'frontend/hooks';

import styles from './Table.scss';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableRow from './TableRow';

export type Row = Record<string, any> & {
  id?: string | number;
  onClick?(): void;
  onKeyDown?(): void;
  to?: string;
};

export interface CellProps {
  data: any;
  rowData?: Row;
  columnName?: string;
  columnData?: Column | undefined;
  rowIdx?: string | number;
}

export type RenderFunction = (props: CellProps) => React.JSX.Element | string | null;

export type Column = Record<string, any> & {
  id?: string;
  key: string;
  render?: RenderFunction;
  component?: React.ComponentType<CellProps>;
  data?: any;
  cellClassName?: string;
};

interface Summary {
  totalCount: number;
  firstVisible: number;
  lastVisible: number;
}

export interface TablePaginationProps {
  currentPage: number;
  pages: number;

  setPage(number): void;

  summary?: Summary;
}

const TablePagination = ({ currentPage, pages, setPage, summary }: TablePaginationProps) => (
  <div className={styles.paginationWrapper}>
    <Pagination currentPage={currentPage} pages={pages} setPage={setPage} />
    {summary && (
      <div className={styles.paginationSummary}>
        Showing {summary.firstVisible}–{summary.lastVisible} out of <strong>{summary.totalCount}</strong>
      </div>
    )}
  </div>
);

interface TableProps {
  columns: Column[];
  data: Row[]; // FIXME: Allow merging props
  className?: string;
  rowClassName?: string;
  nested?: boolean;
  loading?: boolean;
  pagination?: TablePaginationProps;

  compareFn?(a, b): number;

  rightAlignLastColumn?: boolean;
  showTableHeader?: boolean;
  getGridTemplateColumns?: (columns: Column[]) => string;
  tableHeadingComponent?: React.ReactElement | null;
  tableFooterComponent?: React.ReactElement | null;
}

const Table = ({
  columns = [],
  data = [],
  pagination,
  nested = false,
  className,
  rowClassName,
  loading,
  compareFn,
  rightAlignLastColumn,
  showTableHeader = true,
  getGridTemplateColumns,
  tableHeadingComponent,
  tableFooterComponent,
}: TableProps) => {
  const style = useMemo(
    () => ({
      gridTemplateColumns: getGridTemplateColumns ? getGridTemplateColumns(columns) : `repeat(${columns.length}, auto)`,
    }),
    [columns, getGridTemplateColumns],
  );
  const containerClassName = cx(styles.tableContainer, className, {
    [styles.tableContainerNested]: nested,
  });

  useEffect(() => {
    if (!pagination) {
      return;
    }
    if (pagination.pages > 0 && pagination.currentPage > 1 && pagination.currentPage > pagination.pages) {
      pagination.setPage(Math.max(pagination.pages, 1));
    }
  }, [pagination]);

  const prevData = usePrevious(data);

  return (
    <div className={containerClassName}>
      <div
        className={cx(styles.table, { [styles.rightAlignLastColumn]: rightAlignLastColumn })}
        style={style}
        role="table"
      >
        {showTableHeader && (
          <div className={cx(styles.row, styles.rowClassName, styles.rowHeader)} role="row">
            {columns.map((col, headerIdx) => (
              <TableHead key={`table-header-cell-${col.id || headerIdx}`} data={col} />
            ))}
          </div>
        )}
        {loading && <Loader type="table" options={{ columns: columns.length, rows: prevData?.length }} />}
        {tableHeadingComponent}
        {!loading &&
          [...data].sort(compareFn).map((rowData, rowIdx) => {
            const rowKey = rowData.id || rowIdx;
            const onClick = rowData.onClick;
            const onKeyDown = rowData.onKeyDown;
            const to = rowData.to;
            const individualRowClassName = rowData.className;

            return (
              <Fragment key={`row-${rowKey}`}>
                <div className={styles.rowBreak} style={{ gridColumn: `1 / ${columns.length + 1}` }} />
                <TableRow
                  className={cx(rowClassName, individualRowClassName)}
                  to={to}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                >
                  {columns.map((column, cellIdx) => {
                    const columnName = column.key;
                    const cellData = rowData[columnName];
                    const cellKey = cellData?.id || `idx-${cellIdx}`;
                    const cellClassName = column.cellClassName;
                    return (
                      <TableCell
                        key={`cell-${rowKey}-${columnName}-${cellKey}`}
                        data={cellData}
                        cellClassName={cellClassName}
                        render={column.render}
                        component={column.component}
                        columnName={columnName}
                        rowData={rowData}
                        columnData={column.data}
                        rowIdx={rowIdx}
                      />
                    );
                  })}
                </TableRow>
              </Fragment>
            );
          })}
        {tableFooterComponent && (
          <div className={styles.tableFooter} style={{ gridColumn: `1 / ${columns.length + 1}` }}>
            {tableFooterComponent}
          </div>
        )}
      </div>
      {pagination && (
        <TablePagination
          currentPage={pagination.currentPage}
          pages={pagination.pages}
          setPage={pagination.setPage}
          summary={pagination.summary}
        />
      )}
    </div>
  );
};

export default Table;
