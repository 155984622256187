import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { BotLanguageSettingsDocument, TranslateBotDocument } from 'frontend/api/generated';
import { Checkbox, Loader, Select } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { useAvailableLanguages, useToast } from 'frontend/hooks';
import type { Language } from 'frontend/types/language';

interface TranslateBotProps {
  hide(): Promise<void>;

  args: {
    botId: string;
  };
}

const TranslateBot = ({ hide, args: { botId } }: TranslateBotProps) => {
  const toast = useToast();
  const [mutate] = useMutation(TranslateBotDocument);

  const { data: botLanguagesData, loading: botLanguagesLoading } = useQuery(BotLanguageSettingsDocument, {
    variables: { botId },
  });
  const { languages: availableLanguages, loading: availableLanguagesLoading } = useAvailableLanguages(true);

  const {
    botLanguages,
    targetLanguages,
    loading,
  }: { botLanguages: Language[]; targetLanguages: Language[]; loading: boolean } = useMemo(() => {
    const languages = botLanguagesData?.bot?.languages ?? [];
    const languageCodes = languages.map(({ code }) => code);
    const targets = availableLanguages?.filter(
      ({ code }) => !(languageCodes.includes(code) || ['nn', 'se'].includes(code)),
    );
    return {
      botLanguages: languages,
      targetLanguages: targets,
      loading: botLanguagesLoading || availableLanguagesLoading,
    };
  }, [botLanguagesData, botLanguagesLoading, availableLanguages, availableLanguagesLoading]);

  const initialValues = useMemo(
    () => ({
      srcLanguageCode: botLanguages?.[0]?.code,
      targetLanguageCode: targetLanguages?.[0]?.code,
      clone: false,
    }),
    [botLanguages, targetLanguages],
  );

  const onSubmit = useCallback(
    async ({ srcLanguageCode, targetLanguageCode, clone }) => {
      await mutate({
        variables: {
          botId,
          srcLanguageCode,
          targetLanguageCode,
          cloneBot: clone,
        },
      });
      toast.success('Bot translation initiated. This might take a while!');
    },
    [botId, mutate, toast],
  );

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <ModalForm title="Translate bot" hide={hide} onSubmit={onSubmit} initialValues={initialValues} ignoreDirty>
      <label htmlFor="select-srcLanguageCode">From language</label>
      <Field component={Select} name="srcLanguageCode">
        {botLanguages?.map(({ code, name }) => <Select.Option key={code} value={code} label={name} />)}
      </Field>
      <br />
      <label htmlFor="select-targetLanguageCode">To language</label>
      <Field component={Select} name="targetLanguageCode">
        {targetLanguages?.map(({ code, name }) => <Select.Option key={code} value={code} label={name} />)}
      </Field>
      <Field name="clone" component={Checkbox} type="checkbox" label="Create new bot" />

      <p className="m-t-1">
        <strong>Note:</strong> The content of Smart Replies will not be translated.
      </p>
    </ModalForm>
  );
};

export default TranslateBot;
