import { format } from 'date-fns';

import { Search } from 'frontend/assets/icons';
import { Button, Icon, Input, Table } from 'frontend/components';
import type { Column, Row } from 'frontend/components/Table/Table';
import { ADMIN_GROUPS } from 'frontend/features/Admin/constants';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import Filters from './Filters';
import OrganizationList from './OrganizationList';
import styles from './UserList.scss';
import UserOptions from './UserOptions';
import useUsersList from './useUsersList';

const columns: Column[] = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'email',
  },
  {
    key: 'name',
    render: ({ rowData: user }) => user?.fullName,
  },
  {
    key: 'organizations',
    component: OrganizationList as Row['component'],
  },
  {
    key: 'lastLogin',
    title: 'Last active',
    render: ({ data: date }) => (date ? format(date, DATE_FORMAT_WITH_TIME) : ''),
  },
  {
    title: 'Joined',
    key: 'dateJoined',
    render: ({ data: date }) => format(date, DATE_FORMAT_WITH_TIME),
  },
  {
    title: 'Admin groups',
    key: 'admin',
    render: ({ rowData: user }) => user?.groups.filter((group) => ADMIN_GROUPS[group.name]).map((group) => group.label),
  },
  {
    key: 'options',
    title: '',
    component: UserOptions as Row['component'],
  },
];

export default function UserList() {
  const { setFilters, filters, dirty, loading, noHits, onResetClick, users, pagination, searchInput } = useUsersList();
  return (
    <div>
      <div className={styles.header}>
        <Input
          input={searchInput}
          className={styles.search}
          placeholder="Search members"
          aria-label="Search members"
          adornment={<Icon component={Search} />}
          adornmentPosition="left"
          autoFocus
        />
        {dirty && <Button flat onClick={onResetClick} text="Reset" />}
      </div>
      <Filters setFilters={setFilters} filters={filters} />
      <Table data={users} columns={columns} loading={loading} pagination={pagination} />
      {noHits && 'No users found'}
    </div>
  );
}
