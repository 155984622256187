import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';

import { DialogueListDocument } from 'frontend/api/generated';
import { LoaderSwitch, SelectDialogue } from 'frontend/components';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { ModalForm } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import type { PartialDialogue } from 'frontend/types/dialogue';

import { DUPLICATE_BUTTON } from '../../mutations';

const INITIAL_VALUES = { dialogueId: '' };

// TODO: Add preview of button

const DuplicateButton = ({ hide, args }) => {
  const toast = useToast();
  const { dialogueId, button, buildIdObject, refetchButtonsOverview } = args;
  const [duplicate] = useMutation(DUPLICATE_BUTTON, {
    update: (cache, { data }) => {
      const dialogue = data.duplicateButton.dialogue;
      const cacheKey = cache.identify(dialogue);
      if (cacheKey) {
        // Delete the dialogue's cache key so it will be refetched.
        // @ts-expect-error: TS doesn't believe that cache.data exists - why?
        cache.data.delete(cacheKey);
      }
    },
  });

  const { loading, data } = useQuery(DialogueListDocument, { variables: buildIdObject });
  const dialogues: PartialDialogue[] = (data?.dialogueList ?? []).filter(({ id }) => id !== dialogueId);

  const onSubmit = useCallback(
    async ({ dialogueId: id }) => {
      const variables = {
        ...buildIdObject,
        sourceButtonId: button.id,
        targetDialogueId: id,
      };
      const {
        data: {
          duplicateButton: { dialogue },
        },
      } = await duplicate({ variables });
      const to = getBuildUrl({
        buildIdObject,
        dialogueType: dialogue.dialogueType,
        target: dialogue.id,
      });
      toast.linkSuccess('Button added to dialogue! (Click to see dialogue)', to);
      if (refetchButtonsOverview) {
        // Refreshes the "DialogueWithButtons" query.
        refetchButtonsOverview();
      }
    },
    [buildIdObject, button, duplicate, toast, refetchButtonsOverview],
  );

  return (
    <ModalForm
      title="Add button to dialogue"
      onSubmit={onSubmit}
      hide={hide}
      initialValues={INITIAL_VALUES}
      onOkText="Add"
    >
      <LoaderSwitch loading={loading} size="large" noMargin>
        <Field name="dialogueId" component={SelectDialogue} dialogues={dialogues} />
      </LoaderSwitch>
    </ModalForm>
  );
};

DuplicateButton.propTypes = {
  args: PropTypes.shape({}).isRequired,
  hide: PropTypes.func.isRequired,
  refetchButtonsOverview: PropTypes.func,
};

export default DuplicateButton;
