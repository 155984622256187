import cx from 'classnames';
import { get } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import { Input, ToggleSwitch, UrlInput } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import type { InputMeta } from 'frontend/propTypes/InputMetaType';
import type { Input as TypeInput } from 'frontend/propTypes/InputType';

import styles from './ButtonField.scss';
import { BUTTON_TYPES } from '../../constants';

interface Props {
  input: TypeInput;
  meta: InputMeta;
  readOnly: boolean;
  ariaLabel: string;
  placeholder: string;
  label: string;
  onKeyDown: () => void;
  autoFocus: boolean;
  localErrors: Record<string, string>;
  localSubmitFailed: boolean;
  buttonType: (typeof BUTTON_TYPES)[keyof typeof BUTTON_TYPES];
  multiline: boolean;
  inputType: 'text' | 'number' | 'email' | 'url' | 'radio' | 'checkbox';
  openInNewTabName: string;
}

export default function ButtonField({
  input,
  meta,
  readOnly,
  ariaLabel,
  placeholder,
  autoFocus,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  buttonType,
  label,
  inputType = 'text',
  multiline = false,
  openInNewTabName,
}: Props) {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const fieldName = input.name.split('.').splice(-1)[0]!;
  const isExternalLink = fieldName === 'value' && buttonType === BUTTON_TYPES.EXTERNAL_LINK;

  const fieldMeta = useMemo(
    () => (localSubmitFailed ? { error: get(localErrors, fieldName), submitFailed: true } : meta),
    [fieldName, localErrors, localSubmitFailed, meta],
  );

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus();
  }, [autoFocus]);

  const Component = isExternalLink ? UrlInput : Input;

  return (
    <div className={cx({ [styles.urlInput]: isExternalLink })}>
      <Component
        readOnly={readOnly}
        aria-label={ariaLabel}
        placeholder={placeholder}
        autoFocus={autoFocus}
        input={input}
        meta={fieldMeta}
        onKeyDown={onKeyDown}
        ref={isExternalLink ? undefined : ref}
        label={label}
        inputType={inputType}
        multiline={multiline}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      {isExternalLink && openInNewTabName && <ToggleSwitch name={openInNewTabName} status={['Open in new tab']} />}
    </div>
  );
}
