import { useParams } from 'react-router-dom';

import { useModal } from 'frontend/features/Modals';

import type { CompleteChatMessageType } from '../../utils/types';
import ConfidenceScoreModal from '../ConfidenceScoreModal/ConfidenceScoreModal';
import MessageTag from '../MessageTag/MessageTag';
import Score from '../Score/Score';

const SlotPrompt = () => <span title="Prompted to fill slot">SLOT PROMPT</span>;

interface ConfidenceScoreProps {
  firstMessage: CompleteChatMessageType;
}

const ConfidenceScore = ({ firstMessage: { score, exchangeType, fromWebhook, handler } }: ConfidenceScoreProps) => {
  const [showConfidenceScoreModal] = useModal(ConfidenceScoreModal);
  const { botId } = useParams();

  const isKeywordMatch = handler && (handler.includes('KEYWORD_EXACT_MATCH') || handler.includes('KEYWORD_MENTION'));
  const isSlotPrompt = handler && handler.includes('SLOT_PROMPT');
  const isMimirAnswer = handler && handler.includes('MIMIR');
  const isIntentAnswer = handler && handler === 'INTENT_MATCH';
  const isIntentSimplifiedAnswer = handler && handler === 'INTENT_MATCH_SIMPLIFIED';
  const isAiSmalltalk = handler && handler === 'AI_SMALLTALK';
  const isEmailMatch = handler && handler === 'EMAIL_MATCH';

  if (isKeywordMatch) {
    return (
      <>
        <MessageTag>Keyword</MessageTag>
        {isSlotPrompt && <SlotPrompt />}
      </>
    );
  }

  if (isMimirAnswer) {
    return <MessageTag>AI generated</MessageTag>;
  }
  if (isIntentAnswer) {
    return <MessageTag>Intent match</MessageTag>;
  }
  if (isIntentSimplifiedAnswer) {
    return <MessageTag>Intent match simplfied</MessageTag>;
  }
  if (isAiSmalltalk) {
    return <MessageTag>AI smalltalk</MessageTag>;
  }
  if (isEmailMatch) {
    return <MessageTag>Email Match</MessageTag>;
  }

  if (score) {
    return (
      <>
        <Score title="Confidence score" onClick={() => showConfidenceScoreModal({ botId })}>
          {Math.round(score * 100)}%
        </Score>
        {isSlotPrompt && <SlotPrompt />}
      </>
    );
  }

  switch (exchangeType) {
    case 'greeting':
      return <MessageTag>Greeting</MessageTag>;
    case 'fallback':
      return <MessageTag>Fallback</MessageTag>;
    case 'system-dialogue':
      return <MessageTag>System dialogue</MessageTag>;
    case 'fallback_suggestion':
      return <MessageTag>Fallback suggestion</MessageTag>;
    default:
      if (fromWebhook) return <MessageTag>Webhook</MessageTag>;
      return null;
  }
};

export default ConfidenceScore;
