import cx from 'classnames';
import React, { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import { EmptyState, Loader } from 'frontend/components';
import { useAppSelector } from 'frontend/state/hooks';

import styles from './ChatMessages.scss';
import useChatMessages from '../../hooks/useChatMessages';
import { SYSTEM_MESSAGE_TYPE } from '../../utils/constants';
import type { CompleteChatMessageType } from '../../utils/types';
import MessageGroup from '../MessageGroup/MessageGroup';
import SystemMessage from '../SystemMessage/SystemMessage';

const ChatMessages = () => {
  const { selectedChat } = useAppSelector((state) => state.insights);
  const { data, loadMore, hasMore, loading } = useChatMessages(selectedChat);

  const [activeChatMessageId, setActiveChatMessageId] = useState<string>(window.location.hash?.replace('#', ''));
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.chatMessagesWrapper}>
      <div
        data-testid="insights-chat-messages-container"
        className={cx(styles.chatMessagesContainer, { [styles.chatMessagesEmpty]: !data?.length })}
      >
        {!loading && !data?.length && (
          <EmptyState
            iconWidth={124}
            color="pink"
            rotateRiveSources
            horizontal
            description="No chat messages found."
            title="No chat messages"
          />
        )}
        {data.map((messageGroup: CompleteChatMessageType[]) => (
          <Fragment key={messageGroup[0]?.id}>
            {messageGroup?.[0]?.__typename &&
            Object.values(SYSTEM_MESSAGE_TYPE).includes(messageGroup[0].__typename) ? (
              <SystemMessage
                created={messageGroup[0].created}
                type={messageGroup[0].__typename}
                message={messageGroup[0].message}
                statusCode={messageGroup[0].statusCode}
              />
            ) : (
              <MessageGroup
                active={messageGroup[0]?.id === activeChatMessageId}
                onClick={() => {
                  if (messageGroup[0]?.id) {
                    setActiveChatMessageId(messageGroup[0].id);
                    if (window.location.hash) {
                      let path = pathname;
                      if (search) {
                        path += search;
                      }
                      navigate(path);
                    }
                  }
                }}
                messageGroup={messageGroup}
                hash={window.location.hash?.replace('#', '')}
              />
            )}
          </Fragment>
        ))}
      </div>
      {!!data.length && !loading && hasMore && (
        <VisibilitySensor
          onChange={(visible) => {
            if (visible) {
              loadMore();
            }
          }}
        >
          {({ isVisible }) => (
            <div className="m-t-lg m-b-lg text-center">
              <Loader animate={isVisible} />
            </div>
          )}
        </VisibilitySensor>
      )}
    </div>
  );
};

export default ChatMessages;
