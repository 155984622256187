export const libraryItemTypes = Object.freeze({
  DIALOGUES: 'dialogues',
  FALLBACKS: 'fallbacks',
  GREETINGS: 'greetings',
  SYSTEM_DIALOGUES: 'systems',
  SYSTEM_DIALOGUE: 'system',
  FOLDER: 'folder',
  DIALOGUE: 'dialogue',
  GREETING: 'greeting',
  FALLBACK: 'fallback',
  SKILLS: 'skill',
});

export type LibraryItemType = (typeof libraryItemTypes)[keyof typeof libraryItemTypes];
