import { Link } from 'react-router-dom';

import type { UserSearchQuery } from 'frontend/api/generated';
import { Button } from 'frontend/components';

import UserActionsDropdown from './UserActionsDropdown';
import styles from './UserOptions.scss';

type User = UserSearchQuery['userSearch']['objects'][number];

export default function UserOptions({ rowData: user }: { rowData: User }) {
  return (
    <div className={styles.container}>
      <Link to={`/admin/user/${user.id}/`}>
        <Button size="small">View</Button>
      </Link>
      <UserActionsDropdown id={user.id} email={user.email} groups={user.groups} />
    </div>
  );
}
