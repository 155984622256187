import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { BotMetaDocument } from 'frontend/api/generated';
import { LoaderSwitch, PageContent } from 'frontend/components';
import DeprecationWarningBanner from 'frontend/features/DeprecationWarningBanner/DeprecationWarningBanner';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import {
  ActionsList,
  AlertsList,
  BotImprovementTasks,
  CannyHighlight,
  DashboardHeader,
  LatestFallbacks,
  NotificationsList,
} from '../components';

export const sampleBotsDeprecationText = (
  <>
    <p>
      Intent bots are the new way to create bots: it&apos;s easier, faster and more powerful, it simplifies maintenance
      and decreases fallback rate. For this reason, we are deprecating sample bots. You can read more about intent bots
      on our <HelpCenterLink path="/intents-kindly-plus">Help Center</HelpCenterLink>.
    </p>
    <p>Sample bots will no longer be available after June 30th, 2025.</p>
  </>
);

export const inboxDeprecationText = (
  <>
    <p>
      Insights is the way forward for analyzing and reviewing conversations. We are working on transferring notes from
      Inbox to Insights as well as some other functionalities. If you need a Inbox tool to handle manual chat, please
      read about our live chat tool on our <HelpCenterLink path="/new-kindly-inbox">Help Center</HelpCenterLink> or
      contact our <span className="font-weight-medium">customer success team</span>.
    </p>
    <p>Inbox will no longer be available after 31st of March 2025.</p>
  </>
);

export default function BotDashboard() {
  const { botId } = useParams();

  const { data: botMetaData, loading: botMetaLoading } = useQuery(BotMetaDocument, { variables: { id: botId } });
  const organizationId = botMetaData?.bot?.organization?.id;

  const { botPermissions } = useMyPermissions({ botId, organizationId });
  const canConnectBot = botPermissions.includes('connect_bot');
  const canViewConversations = botPermissions.includes('view_conversations');
  const canEditTemplates = botPermissions.includes('edit_templates');

  return (
    <>
      <DeprecationWarningBanner
        text="Inbox is deprecated and will be removed on March 31st, 2025."
        storageKey="inbox"
        modalInfo={{
          heading: 'Inbox is deprecated',
          text: inboxDeprecationText,
        }}
      />

      <PageContent>
        <DashboardHeader organizationId={organizationId} />
        <LoaderSwitch loading={botMetaLoading} size="large">
          <div>
            {canViewConversations && <ActionsList className="m-t-5" />}
            {canConnectBot && <AlertsList botId={botId} />}
            {canEditTemplates && <BotImprovementTasks />}
            <NotificationsList />
            {canViewConversations && <LatestFallbacks botId={botId} />}
          </div>
          <CannyHighlight />
        </LoaderSwitch>
      </PageContent>
    </>
  );
}
