import { gql } from '@apollo/client';

import SampleCandidate from 'frontend/features/BotImprovements/SampleCandidate/fragments/SampleCandidate.fragment.graphql';

export default gql`
  mutation RemoveSampleFromDialogue($botId: ID!, $dialogueId: UUID!, $sampleCandidateId: UUID!) {
    removeSampleFromDialogue(botId: $botId, dialogueId: $dialogueId, sampleCandidateId: $sampleCandidateId) {
      ...SampleCandidate
    }
  }
  ${SampleCandidate}
`;
