import cx from 'classnames';
import { get } from 'lodash';

import { DIALOGUE_TYPES } from 'frontend/constants';
import type { LibraryDialogue } from 'frontend/features/Library/components/DialogueItem/DialogueItem';
import { useCurrentLanguage } from 'frontend/hooks';
import { addObjects } from 'frontend/utils';

import styles from './DialogueItem.scss';

function countGetter(currentLanguage) {
  return (count) => Math.min(get(count, currentLanguage, 0), 999);
}

type Props = {
  dialogue: LibraryDialogue;
  active?: boolean;
};

function SampleCount({ dialogue, active }: Props) {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { dialogueType, samplesCount, expandedSampleCount } = dialogue;

  if (dialogueType !== DIALOGUE_TYPES.SAMPLES) return null;

  const modSamplesCount = get(dialogue, 'mod.modSamplesCount', 0);
  const expandedModSampleCount = get(dialogue, 'mod.expandedModSampleCount', 0);
  const getCount = countGetter(currentLanguage);
  const totalSamples = getCount(addObjects(samplesCount, modSamplesCount));
  const totalExpandedSamples = getCount(addObjects(expandedSampleCount, expandedModSampleCount));

  const className = cx(styles.sampleCount, { [styles.sampleCountActive]: active });

  return (
    <span title={`Expanded: ${totalExpandedSamples}`} className={className}>
      {totalSamples}
    </span>
  );
}

export default SampleCount;
