import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { FilterDialoguesDocument } from 'frontend/api/generated';
import { Checkbox, Input, Loader } from 'frontend/components';
import { useBotOrSkill, useLanguages } from 'frontend/hooks';

import styles from './DialougesFilter.scss';

const DialoguesFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => {
  const [searchDialogues, setSearchDialogues] = useState<{ id: string; title: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const { change, submit } = useForm();
  const buildIdObject = useBotOrSkill();
  const { primaryLanguage } = useLanguages(buildIdObject);

  const {
    input: { value },
  } = useField(filterKey);

  const { botId } = useParams();

  const { data: dialoguesToSearch } = useQuery(FilterDialoguesDocument, {
    variables: {
      botId: botId!,
    },
    onCompleted: ({ filterDialogues }) => {
      setSearchDialogues(filterDialogues);
      setLoading(false);
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.dialoguesSearch}>
        <Input
          className={styles.dialoguesSearchInput}
          input={{
            name: 'search-dialogues',
            onChange: (e) => {
              if (dialoguesToSearch?.filterDialogues) {
                setSearchDialogues(
                  dialoguesToSearch.filterDialogues?.filter(({ title }) =>
                    title[primaryLanguage]?.toLowerCase().includes(e.target.value?.toLowerCase()),
                  ),
                );
              }
            },
          }}
          placeholder="Search dialogues"
        />
      </div>
      {[...searchDialogues]
        .sort((a, b) => {
          if (value.includes(a.id) && !value.includes(b.id)) {
            return -1;
          }
          if (!value.includes(a.id) && value.includes(b.id)) {
            return 1;
          }
          return 0;
        })
        .map(({ title, id }) => (
          <Checkbox
            allowToggleOnWrapper
            key={id}
            title={title[primaryLanguage]}
            labelClassName={styles.dialogueLabel}
            input={{
              name: id,
              checked: value.includes(id),
              onChange: () => {},
            }}
            onClick={() => {
              const valueCopy = [...value];
              if (valueCopy.includes(id)) {
                valueCopy.splice(valueCopy.indexOf(id), 1);
              } else {
                valueCopy.push(id);
              }
              change(filterKey, valueCopy);
              if (shouldSubmitOnToggle) {
                submit();
              }
            }}
            label={title[primaryLanguage] || ''}
          />
        ))}
    </div>
  );
};

export default DialoguesFilter;
