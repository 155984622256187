import { useApolloClient } from '@apollo/client';
import { get } from 'lodash';
import { useEffect, useMemo } from 'react';

import { updateItemInCache } from 'frontend/api/cacheHelpers';
import removeFieldsContainingVariables from 'frontend/api/cacheHelpers/removeFieldsContainingVariables';
import removeFieldsFromKeyWithPotentialVariables from 'frontend/api/cacheHelpers/removeFieldsFromKeyWithPotentialVariables';
import {
  DialogueListDocument,
  FolderDocument,
  LibraryDialoguesDocument,
  LibraryFoldersDocument,
} from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { IMPORT_STATUSES } from 'frontend/features/BotSettings/constants';
import { GetDialogue } from 'frontend/features/Build/graphql';
import { ENTITY } from 'frontend/features/Entities/fragments';
import { ENTITIES, SAMPLES_WITH_ENTITY, SUBSCRIPTIONS_WITH_ENTITIES } from 'frontend/features/Entities/queries';
import {
  updateDialogueParentOnCreate,
  updateDialogueParentOnRemove,
  updateLibraryTopics,
} from 'frontend/features/Library/cacheHelpers';
import { getDialogueParentVariables } from 'frontend/features/Library/utils';
import { toastError, toastSuccess } from 'frontend/features/Toast';
import { useBotOrSkill } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { fetchIfNecessary } from 'frontend/utils';

const fetchPolicy = 'network-only';

const updateSkillsubscriptionDialogues = ({ client, skillId, buildIdObject, dialogue }) => {
  const variables = {
    ...getDialogueParentVariables(buildIdObject, dialogue),
    skillId,
  };
  fetchIfNecessary({ client, query: LibraryDialoguesDocument, variables });
};

const updateSkillsubscriptionTopics = ({ client, skillId, buildIdObject, parentTopicId }) => {
  const variables = { skillId, ...buildIdObject, parentTopicId };
  fetchIfNecessary({ client, query: LibraryFoldersDocument, variables });
};

const updateAllDialoguesQuery = ({ client, buildIdObject }) => {
  fetchIfNecessary({
    client,
    query: DialogueListDocument,
    variables: { ...buildIdObject, includeSubscribedSkills: true },
  });
  fetchIfNecessary({
    client,
    query: DialogueListDocument,
    variables: { ...buildIdObject, dialogueType: DIALOGUE_TYPES.SAMPLES },
  });
};

const dialogueUpdated =
  ({ buildIdObject, client, hasBuildPermission }) =>
  ({ id: dialogueId, skill }) => {
    if (!hasBuildPermission) {
      return;
    }
    client.query({
      query: GetDialogue,
      variables: { skillId: skill, ...buildIdObject, dialogueId },
      fetchPolicy,
    });
    updateAllDialoguesQuery({ client, buildIdObject });
  };

const dialogueCreated =
  ({ client, buildIdObject, hasBuildPermission }) =>
  async ({ id: dialogueId, skill }) => {
    if (!hasBuildPermission) {
      return;
    }

    const variables = { ...buildIdObject, dialogueId };
    const result = await client.query({ query: GetDialogue, variables, fetchPolicy });
    const dialogue = get(result, 'data.dialogue', {});

    if (skill) {
      updateSkillsubscriptionDialogues({ client, skillId: skill, buildIdObject, dialogue });
    } else {
      updateDialogueParentOnCreate({ buildIdObject, client, dialogue });
    }
    updateAllDialoguesQuery({ client, buildIdObject });
  };

const dialogueDeleted =
  ({ client, buildIdObject, hasBuildPermission }) =>
  async ({ skill, ...dialogue }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (skill) {
      updateSkillsubscriptionDialogues({ client, skillId: skill, buildIdObject });
    } else {
      updateDialogueParentOnRemove({ buildIdObject, client, dialogue });
    }
    updateAllDialoguesQuery({ client, buildIdObject });
  };

const topicCreated =
  ({ client, buildIdObject, hasBuildPermission }) =>
  async ({ skill, id: topicId, parentTopicId }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (skill) {
      updateSkillsubscriptionTopics({ client, skillId: skill, buildIdObject, parentTopicId });
    } else {
      const result = await client.query({
        query: FolderDocument,
        variables: { ...buildIdObject, topicId },
        fetchPolicy,
      });
      const topic = get(result, 'data.topic', {});
      updateLibraryTopics({
        variables: { ...buildIdObject, parentTopicId },
        client,
        addedTopic: topic,
      });
    }
  };

const topicUpdated =
  ({ client, buildIdObject, hasBuildPermission }) =>
  ({ id: topicId, skill: skillId }) => {
    if (!hasBuildPermission) {
      return;
    }
    client.query({
      query: FolderDocument,
      variables: { skillId, ...buildIdObject, topicId },
      fetchPolicy,
    });
  };

const topicDeleted =
  ({ client, buildIdObject, hasBuildPermission }) =>
  ({ skill, id, parentTopicId }) => {
    if (!hasBuildPermission) {
      return;
    }
    if (skill) {
      updateSkillsubscriptionTopics({ client, skillId: skill, buildIdObject, parentTopicId });
    } else {
      updateLibraryTopics({
        variables: { ...buildIdObject, parentTopicId },
        client,
        removedTopic: { id },
      });
    }
  };

const samplesDroppedInDialogue =
  ({ client, buildIdObject, hasBuildPermission }) =>
  async ({ source_dialogue: sourceDialogueId, target_dialogue: targetDialogueId }) => {
    if (!hasBuildPermission) {
      return;
    }

    await client.query({
      query: GetDialogue,
      variables: { ...buildIdObject, dialogueId: sourceDialogueId },
      fetchPolicy,
    });
    await client.query({
      query: GetDialogue,
      variables: { ...buildIdObject, dialogueId: targetDialogueId },
      fetchPolicy,
    });
  };

const entityCreated =
  ({ isBot, client, buildIdObject, hasBuildPermission }) =>
  (entity) => {
    if (!hasBuildPermission) {
      return;
    }
    fetchIfNecessary({ client, query: ENTITIES, variables: buildIdObject });
    if (isBot && entity.skill) {
      fetchIfNecessary({
        client,
        query: SUBSCRIPTIONS_WITH_ENTITIES,
        variables: buildIdObject,
      });
    }
  };

const entityUpdated =
  ({ client, buildIdObject, hasBuildPermission }) =>
  (entity) => {
    if (!hasBuildPermission) {
      return;
    }
    updateItemInCache({
      typeName: 'EntityType',
      id: entity.id,
      fragment: ENTITY,
      update: (existingEntity) => ({
        ...existingEntity,
        name: entity.name,
        items: entity.items,
      }),
      cache: client.cache,
    });
    fetchIfNecessary({
      query: SAMPLES_WITH_ENTITY,
      variables: { ...buildIdObject, entityId: entity.id },
      client,
    });
  };

const entityDeleted =
  ({ client, hasBuildPermission }) =>
  ({ id }) => {
    if (!hasBuildPermission) {
      return;
    }

    client.cache.modify({
      fields: {
        entities: (currentEntities, { readField }) =>
          currentEntities.filter((current) => readField('id', current) !== id),
      },
    });

    // FIXME: Find another way to do this https://stackoverflow.com/questions/65595903/apollo-3-deleting-nested-item-from-cache
    updateItemInCache({
      typeName: 'EntityType',
      id,
      fragment: ENTITY,
      update: (existingEntity) => ({ ...existingEntity, id: null }),
      cache: client.cache,
    });
  };

const buildItemTransferred =
  ({ client, buildIdObject, hasBuildPermission }) =>
  () => {
    if (!hasBuildPermission) {
      return;
    }
    fetchIfNecessary({ query: ENTITIES, variables: buildIdObject, client });
  };

const onWorkspaceImport =
  ({ client, buildIdObject, hasBuildPermission, userId }) =>
  ({ status, triggeredByUserId }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (status.toUpperCase() === IMPORT_STATUSES.ERROR && userId === triggeredByUserId) {
      toastError('Workspace import failed');
    }

    if (status.toUpperCase() !== IMPORT_STATUSES.IMPORTED) {
      return;
    }

    const fieldsToClear = [
      'dialogues',
      'dialogue',
      'numberOfPatternsInOtherDialogues',
      'buttons',
      'dialoguesWithWebhooks',
      'someDialogue',
      'buildStatistics',
    ];

    removeFieldsFromKeyWithPotentialVariables(client, fieldsToClear, buildIdObject);

    if (userId === triggeredByUserId) {
      toastSuccess('Workspace imported successfully');
    }
  };

export default (botLike) => {
  const client = useApolloClient();
  const { data } = useMe();
  const userId = data?.me?.id;
  const { buildIdObject, isBot, buildId } = useBotOrSkill({ ignoreNoBotOrSkill: true });
  const buildPermission = isBot ? 'view_templates' : 'read_skill_content';
  const hasBuildPermission = (botLike?.permissions ?? []).includes(buildPermission);

  const events = useMemo(
    () => ({
      'dialogue-created': dialogueCreated({ buildIdObject, client, hasBuildPermission }),
      'dialogue-updated': dialogueUpdated({ buildIdObject, client, hasBuildPermission }),
      'dialogue-deleted': dialogueDeleted({ buildIdObject, client, hasBuildPermission }),
      'topic-created': topicCreated({ buildIdObject, client, hasBuildPermission }),
      'topic-updated': topicUpdated({ buildIdObject, client, hasBuildPermission }),
      'topic-deleted': topicDeleted({ buildIdObject, client, hasBuildPermission }),
      'samples-dropped-in-dialogue': samplesDroppedInDialogue({ buildIdObject, client, hasBuildPermission }),
      'entity-created': entityCreated({ buildIdObject, client, hasBuildPermission, isBot }),
      'entity-updated': entityUpdated({ buildIdObject, client, hasBuildPermission }),
      'entity-deleted': entityDeleted({ client, hasBuildPermission }),
      'build-item-transferred': buildItemTransferred({ buildIdObject, client, hasBuildPermission }),
      'content-import': onWorkspaceImport({ buildIdObject, client, hasBuildPermission, userId }),
    }),
    [buildIdObject, client, hasBuildPermission, isBot, userId],
  );

  useEffect(
    () => () => {
      // Clear skill / bot field
      const field = isBot ? 'bot' : 'skill';
      client.cache.modify({
        fields: {
          [field]: () => undefined,
        },
        variables: { id: buildId },
      });

      // Clear other fields related to skill / bot
      removeFieldsContainingVariables(client, buildIdObject);
    },
    [buildIdObject, buildId, isBot, client],
  );

  return events;
};
