import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { identity, pickBy } from 'lodash';

import { FILTERS_LIST } from './config';
import { FILTER_TYPES } from '../../../features/Insights/utils/constants';
import type { FilterKeyType } from '../components/ButtonTitle/ButtonTitle';

export const AGENT_VIEW_FILTERS = {
  MY_TICKETS: 'MY_TICKETS',
  UNASSIGNED: 'UNASSIGNED',
};

export type DateFiltersType = {
  exclDateFilter: string[];
  dateFilter: string[];
};

export const sortFiltersList = (data) =>
  [...(data?.filtersList || [])].sort((a, b) => (a?.updatedAt < b?.updatedAt ? 1 : -1));

export const getFilterWithoutIndex = (filter: string) => filter.replace(/\{.*?\}/g, '');

export const getKeyIndex = (filterKey) =>
  parseInt(filterKey.substring(filterKey.indexOf('{') + 1, filterKey.lastIndexOf('}')), 10);

export const extractFilterKey = (paramFilterKey, index) => {
  const cleanKey = paramFilterKey.replace(/\{.*?\}/g, '');

  return `${cleanKey}{${index}}`;
};

const boolObjectToArray = (values = {}) => Object.keys(pickBy(values, identity));

export const convertFormValuesToQueryParams = (formValues, search?: string | null, savedFilter?: string | null) => {
  const params = new URLSearchParams();

  Object.entries(formValues)
    .sort(([keyA], [keyB]) => {
      const indexA = getKeyIndex(keyA);
      const indexB = getKeyIndex(keyB);

      return indexA - indexB;
    })
    .forEach(([filter, value]) => {
      const filterKey = getFilterWithoutIndex(filter);

      // when the form value is a non-empty object
      if (typeof value === 'object' && !Array.isArray(value) && value != null && Object.keys(value).length > 0) {
        const valuesArray = boolObjectToArray(value);

        if (valuesArray.length > 0) {
          params.append(filterKey, valuesArray.join(','));
        }
      }

      // when the form value is a non-empty string
      else if (typeof value === 'string' && value !== '0' && value) {
        params.append(filterKey, value);
      }

      // when the form value is a non-empty array
      else if (Array.isArray(value) && value.length > 0) {
        params.append(filterKey, value.join(','));
      }
    });

  if (search) {
    params.set('search', search);
  }

  if (savedFilter) {
    params.set('savedFilter', savedFilter);
  }

  return params.toString();
};

const getParamsToPersistedValues = (key, value) => {
  if (key === 'dateFilter') {
    return [
      value
        .split('|')
        .map((date, index) =>
          index
            ? formatISO(endOfDay(date), { representation: 'date' })
            : formatISO(startOfDay(date), { representation: 'date' }),
        )
        .join('|'),
    ];
  }
  return value.split(',');
};

export const convertQueryParamsToPersistedValues = (isInFilters = true) => {
  const queryParams = new URLSearchParams(window.location.search);

  return Array.from(queryParams.entries())
    .filter(([key]) => key !== 'search' && key !== 'savedFilter')
    .reduce((obj, [key, value]) => {
      const keyOccurrences = Object.keys(obj).length;

      const filterKeys = FILTERS_LIST.map(({ filterKey }) => filterKey);

      const objKey = `${key}${isInFilters && filterKeys.includes(key as FilterKeyType) ? `{${keyOccurrences}}` : ''}`;

      // Remove this when we remove the inbox,
      // We don't care about multiple filters due to that
      if (FILTER_TYPES.STRING.some((stringKey) => key?.toLowerCase().includes(stringKey.toLowerCase()))) {
        obj[objKey] = value;
      } else if (value && filterKeys.includes(key as FilterKeyType)) {
        if (!obj[objKey]) {
          obj[objKey] = getParamsToPersistedValues(key, value);
        } else {
          obj[objKey].push(...getParamsToPersistedValues(key, value));
        }
      }

      return obj;
    }, {} as DateFiltersType);
};
