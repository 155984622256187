import { BUTTON_TYPES } from '../constants';

export default {
  buttonType: BUTTON_TYPES.EXTERNAL_LINK,
  label: '',
  value: '',
  isActive: true,
  hideOutsideBusinessHours: false,
  openInNewTab: true,
};
