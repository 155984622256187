import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  CreateOrganizationMembershipDocument,
  type MeQuery,
  MyOrganizationMembershipCountDocument,
  type OrganizationMemberType,
  OrganizationMembersDocument,
  OrganizationMetaDocument,
} from 'frontend/api/generated';
import { LoaderSwitch, PageContent } from 'frontend/components';
import { inboxDeprecationText } from 'frontend/features/BotDashboard/views/BotDashboard';
import DeprecationWarningBanner from 'frontend/features/DeprecationWarningBanner/DeprecationWarningBanner';
import { useModal } from 'frontend/features/Modals';
import NotFound from 'frontend/features/NotFound/NotFound';
import { useTabTitle } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import Card from './components/Card/Card';
import WorkspaceList from './components/WorkspaceList/WorkspaceList';
import styles from './styles.scss';
import { MEMBERSHIP_TYPES } from '../../constants';
import { useOrganizationMembers } from '../../hooks';
import { RemoveOrganizationMember, RemoveOwnMembership, UpdateOrganizationMember } from '../../modals';

export default function Workspaces() {
  const { organizationId } = useParams();
  const me = useMe().data?.me;

  const {
    data,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(OrganizationMetaDocument, { variables: { id: organizationId! } });
  const { data: membershipsData } = useQuery(OrganizationMembersDocument, { variables: { id: organizationId! } });
  const { data: membershipCountData } = useQuery(MyOrganizationMembershipCountDocument);
  const [createOrganizationMembership, { loading: loadingCreateOrgMembership }] = useMutation(
    CreateOrganizationMembershipDocument,
    { refetchQueries: [OrganizationMembersDocument] },
  );

  const { members, loading: loadingUsers } = useOrganizationMembers();
  const loading = organizationLoading || loadingUsers;

  const { organizationPermissions, isKindlyAdminManager } = useMyPermissions({ organizationId });

  useTabTitle();

  const alreadyMember = ((membershipsData?.organization?.members ?? []) as OrganizationMemberType[]).some(
    ({ user }) => user && String(me?.id) === String(user.id),
  );
  const membershipCount = membershipCountData?.me?.organizationMembershipCount ?? 0;

  const [showRemoveMemberModal] = useModal(RemoveOrganizationMember);
  const [showUpdateMemberModal] = useModal(UpdateOrganizationMember);

  const [showUpdateOrganizationMemberModal] = useModal(UpdateOrganizationMember);
  const [showRemoveOwnMemberModal] = useModal(RemoveOwnMembership);

  const onManagePermissions = useCallback(async () => {
    if (!me) {
      return;
    }

    if (!alreadyMember) {
      // The membership cannot be created right before submit as is done with bots.
      // This because organization memberships work slightly differently.
      await createOrganizationMembership({
        variables: {
          organizationId: organizationId!,
          userId: me.id,
          roleIds: [],
        },
      });
    }

    showUpdateOrganizationMemberModal({ organizationId, user: me });
  }, [alreadyMember, createOrganizationMembership, me, organizationId, showUpdateOrganizationMemberModal]);

  const onRemoveMembership = useCallback(
    () => showRemoveOwnMemberModal({ membershipType: MEMBERSHIP_TYPES.ORGANIZATION, organizationId }),
    [organizationId, showRemoveOwnMemberModal],
  );

  const organizationMeta = data?.organization;

  if (!loading && (organizationError || !organizationMeta)) {
    return <NotFound text="Organization not found or missing permissions" />;
  }

  const adminRows = isKindlyAdminManager
    ? [
        {
          element: (
            <button
              type="button"
              className={cx(styles.adminButton, 'kindlyAdminManager')}
              onClick={onManagePermissions}
              disabled={loadingCreateOrgMembership}
            >
              Manage permissions
            </button>
          ),
        },
        ...(alreadyMember && membershipCount > 1
          ? [
              {
                element: (
                  <button
                    type="button"
                    className={cx(styles.adminButton, 'kindlyAdminManager')}
                    onClick={onRemoveMembership}
                  >
                    Leave organization
                  </button>
                ),
              },
            ]
          : []),
      ]
    : [];

  return (
    <LoaderSwitch loading={loading} size="large">
      <DeprecationWarningBanner
        text="Inbox is deprecated and will be removed on March 31st, 2025."
        storageKey="inbox"
        modalInfo={{
          heading: 'Inbox is deprecated',
          text: inboxDeprecationText,
        }}
      />
      {/* <DeprecationWarningBanner
        text="Sample bots are deprecated and will be removed on June 30th, 2025."
        storageKey="sample-bots"
        modalInfo={{
          heading: 'Sample bots are deprecated',
          text: sampleBotsDeprecationText,
        }}
      /> */}
      <PageContent>
        <div className={styles.container}>
          <div className={styles.sideMenu}>
            <Card
              key={organizationMeta?.id}
              title={{
                name: organizationMeta?.name,
                image: organizationMeta?.logo || undefined,
                fallbackDefaultImage: true,
              }}
              rows={[
                {
                  name: 'Workspaces',
                  rightElement: <div className={styles.number}>{organizationMeta?.botCount || '-'}</div>,
                },
                {
                  name: 'Members',
                  rightElement: <div className={styles.number}>{organizationMeta?.memberCount || '-'}</div>,
                },
                {
                  name: 'Skills',
                  rightElement: <div className={styles.number}>{organizationMeta?.skillCount || '-'}</div>,
                },
                ...adminRows,
              ]}
              loading={organizationLoading}
            />
            <Card
              title={{
                name: 'Members',
                rightElement: <div className={styles.number}>{members?.length || '-'}</div>,
              }}
              rows={members.map((member) => ({
                name: member.user.profile.fullName || member.user.profile.email || 'Anonymous',
                image: member.user.profile.avatarUrl,
                key: member.user.id,
                options: [
                  {
                    title: `Update organization membership`,
                    key: '1',
                    onClick: () => showUpdateMemberModal({ organizationId, user: member.user }),
                  },
                  {
                    title: `Remove organization membership`,
                    key: '2',
                    onClick: () => showRemoveMemberModal({ organizationId, user: member.user }),
                  },
                ],
                fallbackDefaultImage: true,
              }))}
              loading={loadingUsers}
              maximumRows={4}
              type="members"
            />
          </div>
          <WorkspaceList
            canCreateBots={organizationPermissions.includes('create_bots')}
            me={me as MeQuery['me']}
            botCount={organizationMeta?.botCount}
          />
        </div>
      </PageContent>
    </LoaderSwitch>
  );
}
