import { formatISO } from 'date-fns/formatISO';

import type { DateFiltersType } from '../../../components/NewFilters/utils/helpers';

const getDateFilterISO = (value) =>
  value.split(',').map((val) =>
    val
      .split('|')
      .map((date) => formatISO(date, { representation: 'date' }))
      .join('|'),
  );

export default (filters: DateFiltersType) => ({
  dateFilter: filters.dateFilter?.map((filter) => getDateFilterISO(filter)).flat(),
  exclDateFilter: filters.exclDateFilter?.map((filter) => getDateFilterISO(filter)).flat(),
});
