import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';

import { DialogueListDocument } from 'frontend/api/generated';
import { SelectDialogue } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';
import { useBotOrSkill } from 'frontend/hooks';
import type { PartialDialogue } from 'frontend/types/dialogue';

import ButtonField from './ButtonField';

type Props = {
  name: string;
  disabled: boolean;
  localErrors: Record<string, string>;
  localSubmitFailed: boolean;
  onKeyDown: (event: React.KeyboardEvent) => void;
  isNew: boolean;
  validators: Record<string, (value: unknown) => string | undefined>;
  currentLanguage: string;
};
const DialogueTrigger = ({
  name,
  disabled,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  isNew,
  validators,
  currentLanguage,
}: Props) => {
  const { buildIdObject } = useBotOrSkill();
  const { data, loading } = useQuery(DialogueListDocument, {
    variables: { ...buildIdObject, includeSubscribedSkills: true, excludeGreetings: true },
    fetchPolicy: 'network-only',
  });

  const { registerField, change } = useForm();

  useEffect(() => {
    if (!data) {
      return undefined;
    }

    const unsubscribe = registerField(
      `${name}.value`,
      (fieldState) => {
        const value = fieldState.value;
        const dialogue = data?.dialogueList.find(({ id }) => id === value);
        if (dialogue) {
          change(`${name}.dialogueLink.dialogue.isActive`, dialogue.isActive);
        }
      },
      { value: true },
    );
    return () => unsubscribe();
  }, [registerField, change, name, data]);

  const dialogues: PartialDialogue[] = data?.dialogueList ?? [];
  return (
    <>
      <Field
        component={ButtonField}
        name={`${name}.label`}
        placeholder="Button text"
        autoFocus={!isNew}
        validate={isNew ? undefined : validators.label}
        readOnly={disabled}
        ariaLabel="Button text"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
        currentLanguage={currentLanguage}
      />
      <Field
        component={SelectDialogue}
        readOnly={disabled}
        placeholder="Dialogue"
        name={`${name}.value`}
        dialogues={dialogues}
        loading={loading}
        activeOnly={false}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
    </>
  );
};

export default DialogueTrigger;
