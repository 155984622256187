import { Button } from 'frontend/components';

function LoadingButton() {
  return (
    <Button type="button" color="warning" className="m-t-md" isSubmitting>
      Disconnect
    </Button>
  );
}

export default LoadingButton;
