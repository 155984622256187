import { formatISO } from 'date-fns/formatISO';
import React, { useMemo } from 'react';
import type { Range } from 'react-date-range';
import { Field, useField, useForm } from 'react-final-form';

import { DatePicker } from 'frontend/components';

interface DateFilterComponentProps {
  input: {
    name: string;
    onChange: (ranges: Range[]) => void;
  };
  shouldSubmitOnToggle: boolean;
}

const DateFilterComponent = ({ input: { name }, shouldSubmitOnToggle }: DateFilterComponentProps) => {
  const {
    input: { value },
  } = useField(name);

  const { change, submit } = useForm();

  const [startDate, endDate] = useMemo(
    () => value[0]?.split('|').map((val) => formatISO(val, { representation: 'date' })) || [undefined, undefined],
    [value],
  );

  return (
    <DatePicker
      initialEndDate={endDate}
      initialStartDate={startDate}
      showSelect
      name={name}
      onChange={(data) => {
        const [{ endDate: changeEndDate, startDate: changeStartDate }] = data;
        change(name, [
          `${formatISO(changeStartDate, { representation: 'date' })}|${formatISO(changeEndDate, { representation: 'date' })}`,
        ]);
        if (shouldSubmitOnToggle) {
          submit();
        }
      }}
    />
  );
};

const DateFilter = ({ filterKey, shouldSubmitOnToggle }: { filterKey: string; shouldSubmitOnToggle: boolean }) => (
  <Field
    render={(props) => <DateFilterComponent {...props} shouldSubmitOnToggle={shouldSubmitOnToggle} />}
    name={filterKey}
  />
);

export default DateFilter;
