import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';

import type { DialogueType, MessageType } from 'frontend/api/generated';
import { Info, Trash } from 'frontend/assets/icons';
import { Dropdown, Icon, Input } from 'frontend/components';
import type { DropdownRefType } from 'frontend/components/Dropdown/Dropdown';
import InfoModal from 'frontend/components/InfoModal';
import { FIELD_COLOR } from 'frontend/constants';
import { systemDialogueMessageTypes } from 'frontend/features/Library/constants';
import { useModal } from 'frontend/features/Modals';

import styles from './EventMessage.scss';
import { DEFAULT_RULE_ID, EVENT_MESSAGES_SYSTEM_TYPES } from '../../constants';
import BuildItemTitle from '../BuildItemTitle/BuildItemTitle';

const agentAliasKey = 'agent_name';
const botNameKey = 'bot_name';

interface EventMessageProps {
  args: {
    currentLanguage: string;
    currentRuleId: string | null;
    isModDialogue?: boolean;
    initialValues?: DialogueType;
  };
}

type SystemMessageTypePlaceholderMapping = {
  [key in typeof agentAliasKey | typeof botNameKey]: {
    title: string;
    messageTypes: MessageType[];
  };
};

const SYSTEM_MESSAGE_TYPE_PLACEHOLDER_MAPPING: SystemMessageTypePlaceholderMapping = {
  [agentAliasKey]: {
    title: 'Agent name',
    messageTypes: EVENT_MESSAGES_SYSTEM_TYPES.filter((type) => ['START', 'AGENT_ASSIGNED', 'END'].includes(type)),
  },
  [botNameKey]: {
    title: 'Bot name',
    messageTypes: EVENT_MESSAGES_SYSTEM_TYPES,
  },
};

const EVENT_MESSAGE_TEXTS = {
  [systemDialogueMessageTypes.REQUEST]: {
    title: 'User requests a handover',
    description:
      'This system message break point triggers and informs the user that a request to connect with a human agent has been made.',
  },
  [systemDialogueMessageTypes.CANCEL]: {
    title: 'User cancels a handover',
    description:
      'This system message break point informs the user that the request to connect with a human agent has been cancelled.',
  },
  [systemDialogueMessageTypes.CLOSED]: {
    title: 'Handover is closed',
    description: 'This system message break point informs the user that the handover is closed.',
  },
  [systemDialogueMessageTypes.WAITING_FALLBACK]: {
    title: 'Fallback to agent triggered',
    description: 'This system message break point informs the user that the fallback to an agent has been triggered.',
  },
  [systemDialogueMessageTypes.START]: {
    title: 'An agent has joined the conversation',
    description: 'This system message break point informs the user that an agent has joined the conversation.',
  },
  [systemDialogueMessageTypes.END]: {
    title: 'An agent have left the conversation',
    description: 'This system message break point informs that the agent has left the conversation.',
  },
  [systemDialogueMessageTypes.USER_LEFT]: {
    title: 'The user have left the conversation',
    description: 'This system message break point informs that the user has left the conversation.',
  },
  [systemDialogueMessageTypes.AGENT_ASSIGNED]: {
    title: 'An agent is assigned',
    description: 'This system message break point informs the user that an agent has been assigned.',
  },
};

const EventMessage = ({ args: { currentLanguage, currentRuleId, initialValues } }: EventMessageProps) => {
  const form = useForm();
  const dropdownRef = useRef<DropdownRefType>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showInfoModal] = useModal(InfoModal);

  const key = `eventMessage.${currentLanguage}.${currentRuleId || DEFAULT_RULE_ID}`;
  const handleSuggestionClick = useCallback(
    (_, { key: textKey }) => {
      const value = inputRef.current?.value || '';
      const atIndex = value.lastIndexOf('@');
      const beforeAt = atIndex !== -1 ? value.substring(0, atIndex + 1) : value;

      const val = `${beforeAt}${textKey}`;
      form.change(key, val);
    },
    [form, key],
  );

  const defaultOptions = useMemo(
    () =>
      Object.entries(SYSTEM_MESSAGE_TYPE_PLACEHOLDER_MAPPING)
        .filter(
          ([_, { messageTypes }]) => initialValues?.messageType && messageTypes.includes(initialValues?.messageType),
        )
        .map(([smKey, { title }]) => ({ text: title, key: smKey, onClick: handleSuggestionClick })),
    [handleSuggestionClick, initialValues?.messageType],
  );

  const [options, setOptions] = useState(defaultOptions);

  const onChange = (value: string) => {
    const atIndex = value.lastIndexOf('@');
    const lastPart = atIndex !== -1 ? value.substring(atIndex) : '';

    const optionsInValue =
      lastPart &&
      defaultOptions.filter(({ text }) => text.toLowerCase().startsWith(lastPart.replace('@', '').toLowerCase()));

    if (lastPart && optionsInValue.length && dropdownRef.current) {
      setOptions(optionsInValue || defaultOptions);
      dropdownRef.current.openDropdown();
    } else if (dropdownRef.current?.isActive && !optionsInValue.length) {
      dropdownRef.current.closeDropdown();
      setOptions(defaultOptions);
    }
  };

  return (
    <div className={styles.eventMessageWrapper}>
      <BuildItemTitle
        titleTextSize="large"
        title={EVENT_MESSAGE_TEXTS[initialValues?.messageType as string].title}
        description={EVENT_MESSAGE_TEXTS[initialValues?.messageType as string].description}
      />
      <div className={styles.eventMessageRow}>
        <Dropdown
          mode="manual"
          ref={dropdownRef}
          wrapperClassName={styles.eventMessageWrapper}
          triggerClassName={styles.eventMessageTrigger}
          position="bottom"
          title="Event message"
          overlay={
            <Dropdown.MenuOverlay
              onKeyPress={(e: React.KeyboardEvent) => {
                if (!['ArrowDown', 'ArrowUp'].includes(e.code)) {
                  inputRef.current?.focus();
                }
                if (e.code === 'Enter') {
                  (e.target as HTMLElement)?.click();
                  e.preventDefault();
                }
              }}
              options={options}
            />
          }
        >
          <Field
            component={Input}
            ref={inputRef}
            label={
              <>
                {EVENT_MESSAGE_TEXTS[initialValues?.messageType as string].title} text{' '}
                {initialValues?.messageType && (
                  <Icon
                    hoverColor="lighten"
                    onClick={(e) => {
                      showInfoModal({
                        title: EVENT_MESSAGE_TEXTS[initialValues?.messageType as string].title,
                        text: `${EVENT_MESSAGE_TEXTS[initialValues?.messageType as string].title} text accepts the following variables: ${options
                          .map((option) => `@${option.key}`)
                          .join(', ')}.`,
                      });
                      e?.preventDefault();
                      e?.stopPropagation();
                    }}
                    component={Info}
                  />
                )}
              </>
            }
            fieldColor={FIELD_COLOR.MISCHKA}
            className={styles.eventMessageInput}
            name={key}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (inputRef.current && e.code === 'ArrowDown') {
                inputRef.current.blur();
              }
            }}
            placeholder="Event message"
            parse={(value: string) => {
              onChange(value);
              return value;
            }}
          />
        </Dropdown>
        <Icon
          className={styles.removeIcon}
          component={Trash}
          onClick={() => form.change(`${key}`, undefined)}
          hoverColor="warning"
        />
      </div>
    </div>
  );
};

export default EventMessage;
