import { get, isEmpty } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Button, Select, ToggleSwitch } from 'frontend/components';
import { addScheme, stripScheme } from 'frontend/components/UrlInput';
import { FIELD_COLOR, REQUIRED_ALTERNATIVES, URL_SCHEMES } from 'frontend/constants';
import type { ButtonWithDialogueLink } from 'frontend/features/Build/components/Buttons/types';
import { usePrevious } from 'frontend/hooks';

import styles from './ButtonForm.scss';
import ContactDetails from './ContactDetails';
import DefaultButtonInputs from './DefaultButtonInputs';
import DialogueTrigger from './DialogueTrigger';
import Email from './Email';
import Slider from './Slider';
import { useLocalErrors, useValidation } from './hooks';
import { buttonTypeUsesValue } from './utils';
import { BUTTON_TYPES, BUTTON_TYPE_NAMES } from '../../constants';
import { emptyButton } from '../../utils';

const {
  QUICK_REPLY,
  EXTERNAL_LINK,
  EMAIL,
  PHONE,
  HANDOVER_REQUEST,
  PRIVACY_EXPORT,
  PRIVACY_DELETE,
  DIALOGUE_TRIGGER,
  CONTACT_DETAILS,
  ABORT_FOLLOWUP,
  CHECKBOX,
  SUBMIT,
  UPLOAD_ATTACHMENT,
  SLIDER,
} = BUTTON_TYPES;

type Props = {
  input: {
    name: string;
    value: ButtonWithDialogueLink;
  };
  create: (newButtonFields: any) => void;
  onEditFinished: () => void;
  isNew: boolean;
  ownerId: string;
  currentLanguage: string;
};

const getButtonInputs = (buttonType: string) => {
  if (buttonType === DIALOGUE_TRIGGER || buttonType === SUBMIT || buttonType === UPLOAD_ATTACHMENT) {
    return DialogueTrigger;
  }
  if (buttonType === CONTACT_DETAILS) {
    return ContactDetails;
  }
  if (buttonType === EMAIL) {
    return Email;
  }
  if (buttonType === SLIDER) {
    return Slider;
  }
  return DefaultButtonInputs;
};

const ButtonForm = ({
  input: { name, value: button },
  isNew,
  create,
  onEditFinished,
  ownerId,
  currentLanguage,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { change, getState } = useForm();
  const { submitting: disabled } = useFormState();

  const isActive = button?.isActive;
  const currentType = button?.buttonType;
  const isLink = currentType === EXTERNAL_LINK;
  const prevIsLink = usePrevious(isLink);

  const { validators, getButtonErrors } = useValidation({ buttonType: currentType, isActive });
  // New buttons are also connected to the same final-form state, but cannot use the regular
  // validation because they are invalid when empty. Therefore we instead use a local error.
  const { localSubmitFailed, localErrors, updateLocalErrors } = useLocalErrors({
    button,
    getButtonErrors,
    ownerId,
    currentLanguage,
  });

  // Add / remove scheme as the type switches between link and other
  useEffect(() => {
    const buttonValue = button?.value;

    if (isLink && !prevIsLink && !buttonValue) {
      change(`${name}.value`, addScheme(stripScheme(buttonValue), URL_SCHEMES.HTTPS));
    } else if (!isLink && prevIsLink) {
      change(`${name}.value`, stripScheme(buttonValue));
    }
  }, [button, change, isLink, name, prevIsLink]);

  useEffect(() => {
    if (button?.value && buttonTypeUsesValue[currentType] === REQUIRED_ALTERNATIVES.RESTRICTED) {
      change(`${name}.value`, '');
    }
  }, [button?.value, change, currentType, name]);

  const validateButton = useCallback(() => {
    const updatedButton = get(getState(), `values.${name}`);
    const errors = getButtonErrors(updatedButton);
    updateLocalErrors(errors);
    return !isEmpty(errors);
  }, [getState, getButtonErrors, name, updateLocalErrors]);

  const onSubmit = useCallback(() => {
    const hasError = validateButton();
    if (hasError) {
      return;
    }

    if (!isNew) {
      onEditFinished();
      return;
    }

    create(getState().values.newButton);
    change(name, emptyButton);
  }, [change, create, getState, isNew, name, onEditFinished, validateButton]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onSubmit();
        return;
      }

      if (isNew) {
        return;
      }

      const hasError = validateButton();
      if (hasError) {
        return;
      }

      if (event.key === 'Escape') {
        onEditFinished();
      } else if (event.key === 's' && event.metaKey) {
        onEditFinished();
      }
    },
    [isNew, onEditFinished, onSubmit, validateButton],
  );

  const ButtonInputs = getButtonInputs(currentType);

  return (
    <div className={styles.buttonPanel} ref={ref}>
      <Field
        component={Select}
        name={`${name}.buttonType`}
        data-testid="dialogue-button-type"
        disabled={disabled}
        fieldColor={FIELD_COLOR.MISCHKA}
      >
        <optgroup label="Basic buttons">
          <option value={EXTERNAL_LINK}>{BUTTON_TYPE_NAMES[EXTERNAL_LINK]}</option>
          <option value={DIALOGUE_TRIGGER}>{BUTTON_TYPE_NAMES[DIALOGUE_TRIGGER]}</option>
          <option value={EMAIL}>{BUTTON_TYPE_NAMES[EMAIL]}</option>
          <option value={PHONE}>{BUTTON_TYPE_NAMES[PHONE]}</option>
          <option value={HANDOVER_REQUEST}>{BUTTON_TYPE_NAMES[HANDOVER_REQUEST]}</option>
          <option value={PRIVACY_EXPORT}>{BUTTON_TYPE_NAMES[PRIVACY_EXPORT]}</option>
          <option value={PRIVACY_DELETE}>{BUTTON_TYPE_NAMES[PRIVACY_DELETE]}</option>
          <option value={CONTACT_DETAILS}>{BUTTON_TYPE_NAMES[CONTACT_DETAILS]}</option>
        </optgroup>
        <optgroup label="Advanced buttons">
          <option value={ABORT_FOLLOWUP}>{BUTTON_TYPE_NAMES[ABORT_FOLLOWUP]}</option>
          <option value={CHECKBOX}>{BUTTON_TYPE_NAMES[CHECKBOX]}</option>
          <option value={SUBMIT}>{BUTTON_TYPE_NAMES[SUBMIT]}</option>
          <option value={UPLOAD_ATTACHMENT}>{BUTTON_TYPE_NAMES[UPLOAD_ATTACHMENT]}</option>
          <option value={SLIDER}>{BUTTON_TYPE_NAMES[SLIDER]}</option>
          <option value={QUICK_REPLY}>{BUTTON_TYPE_NAMES[QUICK_REPLY]}</option>
        </optgroup>
      </Field>
      <ButtonInputs
        buttonType={currentType}
        name={name}
        disabled={disabled}
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
        isNew={isNew}
        validators={validators}
        currentLanguage={currentLanguage}
      />
      <div className={styles.buttonPanelFlex}>
        <ToggleSwitch
          name={`${name}.isActive`}
          status={['Enable button']}
          data-testid="dialogue-button-enabled"
          disabled={disabled}
        />
        <Button size="small" onClick={onSubmit} disabled={disabled}>
          {isNew ? 'Add button' : 'Done'}
        </Button>
      </div>
    </div>
  );
};

export default ButtonForm;
