import { useMemo } from 'react';

import type { UserSearchQuery } from 'frontend/api/generated';

import LinkList from './LinkList';

type User = UserSearchQuery['userSearch']['objects'][number];

export default function OrganizationList({ rowData: user }: { rowData: User }) {
  const collection = useMemo(
    () => user.organizationMemberships.map((om) => om.organization),
    [user.organizationMemberships],
  );
  return <LinkList type="organization" collection={collection} size="small" />;
}
