import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import type { FilterKeyType } from './components/ButtonTitle/ButtonTitle';
import FiltersWrapper from './components/FiltersWrapper/FiltersWrapper';
import FilterContextProvider from './context/FilterContext';
import { convertFormValuesToQueryParams, convertQueryParamsToPersistedValues } from './utils/helpers';

export type FiltersPathType = 'INSIGHTS_CHATS' | 'INSIGHTS_OVERVIEW' | 'ANALYTICS';

export interface FiltersProps {
  path: FiltersPathType;
  submitOn?: 'toggle' | 'close';
  canIncludeExclude?: boolean;
  canAddMoreThanOne?: boolean;
  filters?: FilterKeyType[];
  pinnedFilters?: FilterKeyType[];
  clearButtonTitle?: string;
  onClear?: (filters: any, reset: (initialValues?: Partial<Record<string, any>> | undefined) => void) => void;
  className?: string;
}

const Filters = ({
  submitOn = 'close',
  canIncludeExclude = true,
  canAddMoreThanOne = true,
  filters,
  pinnedFilters,
  onClear,
  clearButtonTitle,
  className,
  path,
}: FiltersProps) => {
  const navigate = useNavigate();

  const putFormValuesInQueryParams = useCallback(
    (paramsString) => {
      navigate(
        { search: paramsString },
        {
          replace: true,
        },
      );
    },
    [navigate],
  );

  const onSubmit = (values) => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchP = searchParams.get('search');
    const savedFilter = searchParams.get('savedFilter');
    const queryParamsFromFormValues = convertFormValuesToQueryParams(values, searchP, savedFilter);

    putFormValuesInQueryParams(queryParamsFromFormValues);
  };

  const initialValues = useMemo(() => convertQueryParamsToPersistedValues(), []);

  return (
    <Form
      initialValuesEqual={isEqual}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(props) => (
        <FilterContextProvider>
          <FiltersWrapper
            {...props}
            onClear={onClear}
            submitOn={submitOn}
            canIncludeExclude={canIncludeExclude}
            canAddMoreThanOne={canAddMoreThanOne}
            pinnedFilters={pinnedFilters}
            clearButtonTitle={clearButtonTitle}
            filters={filters}
            className={className}
            path={path}
          />
        </FilterContextProvider>
      )}
      canIncludeExclude={canIncludeExclude}
    />
  );
};

export default Filters;
