import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTabTitle } from 'frontend/hooks';
import { ChildrenType } from 'frontend/propTypes';
import { selectNavbarHeight } from 'frontend/state/dux/navbar';

import styles from './InboxLayout.scss';

const InboxLayout = ({ children }) => {
  const { search } = useLocation();
  const isMetaScreen = new URLSearchParams(search).get('screen') === 'meta';
  const isChatlistScreen = new URLSearchParams(search).get('screen') === 'chat-list';
  const isViewsScreen = new URLSearchParams(search).get('screen') === 'views';
  const isConversationScreen = !isChatlistScreen && !isMetaScreen && !isViewsScreen;

  const [isDeprecationBannerVisible, setIsDeprecationBannerVisible] = useState(() => {
    const hiddenUntil = localStorage.getItem('deprecation_banner_inbox_hidden_until');
    return !hiddenUntil || new Date(hiddenUntil) < new Date();
  });

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'deprecation_banner_inbox_hidden_until') {
        const hiddenUntil = e.newValue;
        const shouldShow = !hiddenUntil || new Date(hiddenUntil) < new Date();
        setIsDeprecationBannerVisible(shouldShow);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const height = useSelector(selectNavbarHeight);
  const style = useMemo(
    () => ({ top: `${height + (isDeprecationBannerVisible ? 40 : 0)}px` }),
    [height, isDeprecationBannerVisible],
  );

  useTabTitle('Inbox');

  return (
    <div
      className={cx(styles.grid, {
        [styles.mobileScreenViews]: isViewsScreen,
        [styles.mobileScreenList]: isChatlistScreen,
        [styles.mobileScreenConversation]: isConversationScreen,
        [styles.mobileScreenMeta]: isMetaScreen,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

InboxLayout.propTypes = {
  children: ChildrenType.isRequired,
};

export default InboxLayout;
