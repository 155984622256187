import type { FacebookPageType } from 'frontend/api/generated';
import { Button, Panel } from 'frontend/components';

import DisconnectButton from './DisconnectButton';
import RetryDisconnect from './RetryDisconnect';
import styles from '../FacebookMessenger.scss';
import useMessengerConnection from '../useMessengerConnection';

type Props = { facebookPage: Partial<FacebookPageType> };

function ConnectedView({ facebookPage }: Props) {
  const {
    retryDisconnect,
    setRetryDisconnect,
    languageNameMap,
    choosePreferredHandoverId,
    removePreferredHandoverId,
    chooseSelectedLanguage,
    removeSelectedLanguage,
    botId,
    languages,
  } = useMessengerConnection(facebookPage);

  return (
    <Panel>
      <p className="m-t-0">
        🔗 Currently connected to{' '}
        <a className={styles.FBPageLink} href={`https://www.facebook.com/${facebookPage.pageId}`}>
          <strong>{facebookPage.name}</strong>
        </a>
      </p>
      <p>
        {facebookPage.isHealthy
          ? '✅ All required permissions have been granted'
          : '❌ Missing some required permissions'}
      </p>
      <div className={styles.updateWrapper}>
        <p>
          Preferred secondary handover app id: <span>{facebookPage.handoverPreferredSecondaryAppId ?? 'None'}</span>
        </p>
        {Number.isInteger(facebookPage.handoverPreferredSecondaryAppId) && (
          <Button onClick={removePreferredHandoverId} size="small" color="warning">
            Remove
          </Button>
        )}
        <Button onClick={choosePreferredHandoverId} size="small">
          Change
        </Button>
      </div>
      {languages?.length > 1 && (
        <div className={styles.updateWrapper}>
          <p>
            Selected language:{' '}
            <span>
              {languageNameMap && facebookPage?.selectedLanguage
                ? languageNameMap[facebookPage.selectedLanguage]
                : 'No default language'}
            </span>
          </p>
          {facebookPage.selectedLanguage && (
            <Button onClick={removeSelectedLanguage} size="small" color="warning">
              Remove
            </Button>
          )}
          <Button onClick={chooseSelectedLanguage} size="small">
            Change
          </Button>
        </div>
      )}
      <br />
      {retryDisconnect ? (
        <RetryDisconnect botId={botId!} facebookPage={facebookPage} setRetryDisconnect={setRetryDisconnect} />
      ) : (
        <DisconnectButton botId={botId!} facebookPage={facebookPage} setRetryDisconnect={setRetryDisconnect} />
      )}
    </Panel>
  );
}

export default ConnectedView;
