import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Exclamation, Search } from 'frontend/assets/icons';
import { EmptyState, InfoButton, LoaderSwitch, Pagination } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useCurrentLanguage, usePagination } from 'frontend/hooks';
import { selectLibraryFilters } from 'frontend/state/dux/library';

import styles from './IncompleteDialogues.scss';
import { DialogueRow } from './components';
import { useIncompleteDialogues } from './hooks';
import sharedStyles from '../bot-improvements.scss';
import { Section } from '../components';
import filterById from '../filterById';
import { AboutFeature } from '../modals';

const AboutFeatureText = (
  <>
    <strong>How to use Incomplete Dialogues</strong>
    <p>Incomplete Dialogues lists all active dialogues that contains less then 20 samples.</p>
  </>
);

const stateInfo = {
  noResults: {
    icon: Search,
    header: 'No results',
    text: 'There are no incomplete dialogues for the current filter. Change or reset the filter to load results.',
  },
  noTasks: {
    icon: Exclamation,
    header: 'No tasks',
    text: 'There are currently no incomplete dialogues.',
  },
};

const IncompleteDialogues = () => {
  const { botId } = useParams();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { filters } = useSelector(selectLibraryFilters);
  const [ignoredDialogues, setIgnoredDialogues] = useState<string[]>([]);
  const { incompleteDialogues, loading } = useIncompleteDialogues(currentLanguage, filters, botId);
  const [showAboutFeature] = useModal(AboutFeature);

  const [currentData, page, pages, setPage] = usePagination({
    objects: filterById(incompleteDialogues, ignoredDialogues),
    pageSize: 7,
  });
  const InfoBox = (
    <InfoButton
      className={styles.infoButton}
      onClick={() => showAboutFeature({ title: 'About Incomplete Dialogues', text: AboutFeatureText })}
    />
  );

  const emptyState = isEmpty(filters) ? 'noTasks' : 'noResults';

  return (
    <LoaderSwitch loading={loading} size="large">
      <Section
        InfoBox={InfoBox}
        title="Incomplete dialogues"
        listHeader={['Dialogue', 'Samples']}
        hasData={!!incompleteDialogues?.length}
      >
        {!incompleteDialogues?.length ? (
          <EmptyState
            className={sharedStyles.emptyState}
            color="blue"
            description={stateInfo[emptyState].text}
            icon={stateInfo[emptyState].icon}
            title={stateInfo[emptyState].header}
          />
        ) : (
          <>
            {currentData.map((dialogue) => (
              <DialogueRow
                key={`dialogue-${dialogue.id}`}
                currentLanguage={currentLanguage}
                dialogue={dialogue}
                setIgnoredDialogues={setIgnoredDialogues}
                ignoredDialogues={ignoredDialogues}
              />
            ))}
            {pages > 1 && (
              <div className={styles.pagination}>
                <Pagination currentPage={page} pages={pages} setPage={setPage} />
              </div>
            )}
          </>
        )}
      </Section>
    </LoaderSwitch>
  );
};
export default IncompleteDialogues;
