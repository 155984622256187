import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  type DeleteTopicMutation,
  type FolderType,
  type MutationTransferFolderArgs,
  TransferFolderDocument,
} from 'frontend/api/generated';
import { dragAndDropTypes } from 'frontend/constants';
import { updateLibraryTopics } from 'frontend/features/Library/cacheHelpers';
import { useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import TransferForm, { NONE_SELECTED } from '../TransferForm';

export default function TransferFolder({
  hide,
  args,
}: {
  hide: (...args: unknown[]) => void;
  args: {
    topic: FolderType;
    botOrSkillParams: BotOrSkill;
  };
}) {
  const toast = useToast();
  const {
    topic,
    botOrSkillParams,
    botOrSkillParams: { buildIdObject },
  } = args;
  const [transfer] = useMutation(TransferFolderDocument);
  const client = useApolloClient();

  const onSubmit = useCallback(
    async (values: Pick<NonNullable<MutationTransferFolderArgs>, 'deleteOriginal' | 'botId' | 'skillId'>) => {
      const variables: NonNullable<MutationTransferFolderArgs> = {
        folderId: topic.id,
        botId: values.botId === NONE_SELECTED ? null : values.botId,
        skillId: values.skillId === NONE_SELECTED ? null : values.skillId,
        deleteOriginal: values.deleteOriginal,
        transferLabels: true, // TODO: make some UI for the user to pick this
      };
      const { data } = await transfer({
        variables,
      });

      toast.success('The folder is being transferred. This may take a moment...');

      if (!data?.transferFolder?.deletedOriginal) {
        return;
      }

      const { parentFolderId } = topic;
      const updateVariables = { ...buildIdObject, ...(parentFolderId ? { parentFolderId } : {}) };
      updateLibraryTopics({
        variables: updateVariables,
        client,
        removedTopic: topic as NonNullable<DeleteTopicMutation['deleteTopic']>,
      });
    },
    [buildIdObject, client, toast, topic, transfer],
  );

  const initialValues = useMemo(
    () => ({
      name: topic.name,
      deleteOriginal: false,
      botId: NONE_SELECTED,
      skillId: NONE_SELECTED,
    }),
    [topic.name],
  );

  return (
    <TransferForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={initialValues}
      type={dragAndDropTypes.FOLDER}
      botOrSkillParams={botOrSkillParams}
    />
  );
}
