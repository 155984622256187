import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EmojiSad } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import type { LibraryDialogue } from 'frontend/features/Library/components/DialogueItem/DialogueItem';
import {
  DEFAULT_DIALOGUE_LABEL_COLOR,
  DIALOGUE_LABEL_COLORS,
  type LabelColor,
} from 'frontend/features/Library/constants';

import { DialogueActions } from '..';
import styles from './DialogueRow.scss';
import { SectionRow } from '../../../components';

function getColor(currentLanguage: string, colorLabel?: LabelColor) {
  if (!colorLabel || !colorLabel[currentLanguage]) {
    return DEFAULT_DIALOGUE_LABEL_COLOR;
  }

  return DIALOGUE_LABEL_COLORS.get(colorLabel[currentLanguage]) ?? DEFAULT_DIALOGUE_LABEL_COLOR;
}

function DialogueRow({
  currentLanguage,
  dialogue,
  setIgnoredDialogues,
  ignoredDialogues,
}: {
  currentLanguage: string;
  dialogue: LibraryDialogue;
  setIgnoredDialogues: (ignoredDialogues: string[]) => void;
  ignoredDialogues: string[];
}) {
  const { botId } = useParams();
  const { id, title, samplesCount, colorLabel } = dialogue;

  const setIsIgnored = useCallback(() => {
    setIgnoredDialogues([...ignoredDialogues, id]);
  }, [setIgnoredDialogues, ignoredDialogues, id]);

  const progressPercentage = useMemo(() => (samplesCount[currentLanguage] / 20) * 100, [currentLanguage, samplesCount]);

  const color = getColor(currentLanguage, colorLabel);

  return (
    <SectionRow>
      <div className={styles.wrapper}>
        <div className={styles.dialogueRow}>
          <div className={styles.icon}>
            <Icon component={EmojiSad} color="primary" />
          </div>
          <div className={styles.dialogue}>
            <div style={{ backgroundColor: color }} className={styles.icon} />
            <div className={styles.title}>{title[currentLanguage]}</div>
          </div>
        </div>
        <div className={styles.progressBar}>
          <div>
            <span className={styles.total} />
            <span className={styles.overlay} style={{ width: `${progressPercentage}%` }} />
          </div>
          <span className={styles.stats}>{samplesCount[currentLanguage]}/20</span>
        </div>
      </div>
      <DialogueActions dialogueId={id} botId={botId} setIgnored={setIsIgnored} />
    </SectionRow>
  );
}

export default DialogueRow;
