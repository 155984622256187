import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { Loader, PageContent } from 'frontend/components';
import { Entities, NotFound, NudgeEditor } from 'frontend/features';
import { DialogueConfusion, IncompleteDialogues, SampleCandidate } from 'frontend/features/BotImprovements';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { BannerMessage, GlobalIcons, PushGreetings } from 'frontend/features/Build/components';
import {
  CreateDialogue,
  CreateSpecialDialogue,
  UpdateDialogue,
  UpdateSpecialDialogue,
} from 'frontend/features/Build/views';
import BuildDashboard from 'frontend/features/BuildDashboard';
import styles from 'frontend/features/BuildDashboard/BuildDashboard.scss';
import Library from 'frontend/features/Library';
import BrokenLinks from 'frontend/features/Library/components/BrokenLinks/BrokenLinks';
import DialogueCandidates from 'frontend/features/Library/components/IntentSuggestion/DialogueCandidates';
import LibraryFilters from 'frontend/features/Library/components/LibraryFilters';
import {
  CustomNudge,
  EditNudge,
  FormNudge,
  MultipleChoiceNudge,
  ProductNudge,
  TextNudge,
} from 'frontend/features/NudgeEditor/components';
import { useTabTitle } from 'frontend/hooks';

export type FiltersType = {
  fromDate?: Date;
  toDate?: Date;
  dialogueId?: string;
};

const BuildRoutes = ({ isSkill }) => {
  const { loading, botData, skillData } = useTabTitle('Build');
  const { botId, skillId } = useParams();

  const { loading: botSettingsLoading, botSettings } = useBotSettings(botId);

  if (loading || botSettingsLoading) return <Loader />;
  if (!isSkill && !botData?.bot) return <NotFound text="Workspace not found" />;
  if (isSkill && !skillData?.skill) return <NotFound text="Skill not found" />;

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
        <Library />
        {!isSkill && <LibraryFilters />}
        <PageContent className={styles.buildContentContainer}>
          <Routes>
            {!botSettings?.intentMatchEnabled ? (
              <>
                <Route path="entities" element={<Entities />} />
                <Route path="bot-improvements" element={<Navigate to="sample-candidates" replace />} />
                <Route path="bot-improvements/sample-candidates" element={<SampleCandidate />} />
                <Route path="bot-improvements/dialogue-confusion" element={<DialogueConfusion />} />
                <Route path="bot-improvements/incomplete-dialogues" element={<IncompleteDialogues />} />
              </>
            ) : (
              <>
                <Route path="bot-improvements" element={<Navigate to="broken-links" replace />} />
                <Route path="bot-improvements/dialogue-candidates" element={<DialogueCandidates />} />
              </>
            )}
            <Route path="bot-improvements/broken-links" element={<BrokenLinks />} />
            {!!skillId && <Route path="entities" element={<Entities />} />}
            <Route path="dialogue/new" element={<CreateDialogue />} />
            <Route path="dialogue/:dialogueId" element={<UpdateDialogue />} />
            <Route path="greeting/new" element={<CreateSpecialDialogue />} />
            <Route path="greeting/:dialogueId" element={<UpdateSpecialDialogue />} />
            <Route path="fallback/new" element={<CreateSpecialDialogue />} />
            <Route path="fallback/:dialogueId" element={<UpdateSpecialDialogue />} />
            <Route path="proactivity" element={<Navigate to="nudge" replace />} />
            <Route path="proactivity/nudge/new-form" element={<FormNudge />} />
            <Route path="proactivity/nudge/new-product" element={<ProductNudge />} />
            <Route path="proactivity/nudge/new-text" element={<TextNudge />} />
            <Route path="proactivity/nudge/new-custom" element={<CustomNudge />} />
            <Route path="proactivity/nudge/new-multiple-choice" element={<MultipleChoiceNudge />} />
            <Route path="proactivity/nudge/:slug" element={<EditNudge />} />
            <Route path="proactivity/nudge" element={<NudgeEditor />} />
            <Route path="proactivity/global-icons" element={<GlobalIcons />} />
            <Route path="proactivity/global-icon/:globalIconId" element={<GlobalIcons />} />
            <Route path="proactivity/push-greetings" element={<PushGreetings />} />
            <Route path="proactivity/push-greeting/:pushGreetingId" element={<PushGreetings />} />
            <Route path="proactivity/banner-message" element={<BannerMessage />} />
            <Route path="system-dialogue/:dialogueId" element={<UpdateSpecialDialogue />} />
            <Route path="dialogues/*" element={<BuildDashboard isSkill={isSkill} />} />
            <Route path="system-dialogues/*" element={<BuildDashboard isSkill={isSkill} />} />
            <Route path="/*" element={<BuildDashboard isSkill={isSkill} />} />
            <Route element={<NotFound />} />
          </Routes>
        </PageContent>
      </div>
    </DndProvider>
  );
};

BuildRoutes.propTypes = {
  isSkill: PropTypes.bool,
};

export default BuildRoutes;
