import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { Field } from 'react-final-form';

import {
  type MutationTransferDialogueArgs,
  type MutationTransferFolderArgs,
  MyBotsDocument,
  MySkillsDocument,
} from 'frontend/api/generated';
import { Checkbox, Input, LoaderSwitch, Select } from 'frontend/components';
import { dragAndDropTypes } from 'frontend/constants';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import { capitalize } from 'frontend/utils';

import { NONE_SELECTED } from './constants';
import validator from './validator';

function typeToHeadline(type: keyof typeof dragAndDropTypes): string {
  return type === dragAndDropTypes.DIALOGUE ? 'Transfer dialogue' : 'Transfer folder';
}

type Props = {
  onSubmit:
    | ((values: Pick<NonNullable<MutationTransferDialogueArgs>, 'deleteOriginal' | 'transferLabels'>) => void)
    | ((values: NonNullable<MutationTransferFolderArgs>) => void);
  hide: (...args: unknown[]) => void;
  initialValues: {
    title?: string;
    name?: string;
    deleteOriginal?: boolean;
    botId?: string;
    skillId?: string;
  };
  type: keyof typeof dragAndDropTypes;
  botOrSkillParams: BotOrSkill;
};
const TransferDialogue = ({ onSubmit, hide, initialValues, type, botOrSkillParams }: Props) => {
  const { isBot, buildId, isSkill } = botOrSkillParams;
  const { botSettings } = useBotSettings(buildId);
  const intentMatchEnabled = botSettings?.intentMatchEnabled ?? false;

  const { loading: botsLoading, data: botData } = useQuery(MyBotsDocument, { variables: { includeArchived: false } });
  const { loading: skillsLoading, data: skillsData } = useQuery(MySkillsDocument);
  const nameType = type === dragAndDropTypes.DIALOGUE ? 'title' : 'name';

  const sortedBots = useMemo(
    () =>
      orderBy(
        botData?.me?.bots?.filter(({ intentMatchEnabled: enabled }) => enabled === intentMatchEnabled) ?? [],
        ({ name = '' }) => name.trim().toLowerCase(),
      ),
    [botData, intentMatchEnabled],
  );
  const sortedSkills = useMemo(
    () => orderBy(skillsData?.me?.skills ?? [], ({ name = '' }) => name.trim().toLowerCase()),
    [skillsData],
  );

  return (
    <ModalForm
      title={typeToHeadline(type)}
      onOkText="Transfer"
      onSubmit={onSubmit}
      hide={hide}
      initialValues={initialValues}
      validate={validator}
      showFormErrors
    >
      <LoaderSwitch loading={botsLoading || skillsLoading} size="large" noMargin>
        <br />
        <h4>New {nameType}</h4>
        <Field
          component={Input}
          className="m-b-md"
          placeholder={capitalize(nameType)}
          name={nameType}
          validate={required}
          aria-label={capitalize(nameType)}
        />
        <br />
        {sortedBots.length > 0 && (
          <>
            <h4>To bot</h4>
            <Field component={Select} name="botId">
              <option value={NONE_SELECTED}>-- select a bot --</option>
              {sortedBots.map(({ id, name, projectName }) => {
                // Can't transfer to where it already is at
                if (isBot && id === buildId) {
                  return null;
                }

                let displayName = name;
                if (projectName && projectName !== name) {
                  displayName = `${name} (${projectName})`;
                }

                return (
                  <option key={`transfer-to-bot-${id}`} value={id}>
                    {`${displayName} [${id}]`}
                  </option>
                );
              })}
            </Field>
            <br />
            <br />
          </>
        )}
        {sortedSkills.length > 0 && (
          <>
            <h4>To skill</h4>
            <Field component={Select} name="skillId">
              <option value={NONE_SELECTED}>-- select a skill --</option>
              {sortedSkills.map(({ id, name }) => {
                // Can't transfer to where it already is at
                if (isSkill && id === buildId) {
                  return null;
                }

                return (
                  <option key={`transfer-to-skill-${id}`} value={id}>
                    {name}
                  </option>
                );
              })}
            </Field>
            <br />
            <br />
          </>
        )}
        <Field
          component={Checkbox}
          type="checkbox"
          name="deleteOriginal"
          label={`Remove original ${type.toLowerCase()}`}
        />
      </LoaderSwitch>
    </ModalForm>
  );
};

export default TransferDialogue;
