import { useField } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';

import type { DialogueRuleType } from 'frontend/api/generated';
import {
  Button as ButtonIcon,
  Form,
  Image,
  Plus,
  Settings,
  Slot,
  Sparkles,
  SpeechMessage,
  TextCapitalize,
  Video,
} from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';
import Tabs, { type Tab } from 'frontend/components/Tabs/Tabs';
import { DIALOGUE_TYPES } from 'frontend/constants';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import hasLanguage from 'frontend/utils/hasLanguage';

import styles from './DialogueFormOutput.scss';
import { EVENT_MESSAGES_SYSTEM_TYPES } from '../../constants';
import type { TabName, TabsShown } from '../../hooks/useDialogueOutputs';
import type { DialogueData } from '../DialogueForm/DialogueFormContents';
import RuleSelector from '../RuleSelector/RuleSelector';

interface Props {
  dialogueData: Partial<DialogueData>;
  tabsShownMap: TabsShown;
  setTabsToShowExtra: React.Dispatch<React.SetStateAction<TabName[]>>;
}

export default function DialogueFormOutput({
  dialogueData: { isModDialogue, initialValues, selectedLanguage, currentRuleId, buildFormType, dialogueId },
  tabsShownMap,
  setTabsToShowExtra,
}: Props) {
  const currentDialogueRules = (useField('dialogueRules').input.value || [])
    .filter(hasLanguage(selectedLanguage!))
    .filter(({ id }) => id) as Partial<DialogueRuleType>[];

  const tabs = Object.values(tabsShownMap).filter(Boolean) as Tab[];

  const isFeatureEnabled = useFeatureFlags();
  const [searchParams, setSearchParams] = useSearchParams();

  const slotsEnabled = isFeatureEnabled('slots');
  const isFallbackOrGreeting = ['fallback', 'greeting'].includes(buildFormType as string);
  const isSampleDialogue = initialValues?.dialogueType === 'SAMPLES';
  const isIntentDialogue = initialValues?.dialogueType === 'INTENT';

  const updateQueryParams = (tabName: string): void => {
    searchParams.set('formContent', 'output');
    searchParams.set('tab', tabName.toLowerCase());
    setSearchParams(searchParams);
  };

  const showTab = (tabName: TabName) => (): void => {
    setTabsToShowExtra((prevTabsShown) => [...prevTabsShown, tabName]);
    updateQueryParams(tabName);
  };

  const actions: React.ComponentProps<typeof Dropdown.MenuOverlay>['options'] = [
    ...(initialValues?.dialogueType === DIALOGUE_TYPES.SYSTEM_DIALOGUE &&
    EVENT_MESSAGES_SYSTEM_TYPES.includes(initialValues.messageType)
      ? [
          {
            text: 'Event message',
            icon: Plus,
            disabled: !!tabsShownMap.EVENT_MESSAGE,
            onClick: showTab('event message'),
          },
        ]
      : []),
    {
      text: 'Reply',
      icon: TextCapitalize,
      disabled: !!tabsShownMap.REPLIES,
      onClick: showTab('reply'),
    },
    ...(isIntentDialogue
      ? [
          {
            text: 'Smart Reply',
            icon: Sparkles,
            disabled: !!tabsShownMap.SMART_REPLIES,
            onClick: showTab('smart reply'),
          },
        ]
      : []),

    {
      text: 'Button',
      icon: ButtonIcon,
      disabled: !!tabsShownMap.BUTTONS,
      onClick: showTab('button'),
    },
    {
      text: 'Image',
      icon: Image,
      disabled: !!tabsShownMap.IMAGES,
      onClick: showTab('image'),
    },
    {
      text: 'Video',
      icon: Video,
      disabled: !!tabsShownMap.VIDEOS,
      onClick: showTab('video'),
    },
    ...(isSampleDialogue && !isModDialogue && !isFallbackOrGreeting
      ? [
          {
            text: 'Suggestion',
            icon: SpeechMessage,
            disabled: !!tabsShownMap.SUGGESTION,
            onClick: showTab('suggestion'),
          },
        ]
      : []),
    ...(!isModDialogue && !isFallbackOrGreeting && slotsEnabled && !currentRuleId
      ? [
          {
            text: 'Slot',
            icon: Slot,
            disabled: !!tabsShownMap.SLOTS,
            onClick: showTab('slot'),
          },
        ]
      : []),
    ...(!isModDialogue
      ? [
          {
            text: 'Form',
            icon: Form,
            disabled: !!tabsShownMap.FORMS,
            onClick: showTab('form'),
          },
        ]
      : []),
    {
      text: 'Advanced Options',
      icon: Settings,
      disabled: !!tabsShownMap.ADVANCED,
      onClick: showTab('advanced options'),
    },
  ];

  return (
    <>
      {currentDialogueRules.length > 0 && <RuleSelector dialogueRules={currentDialogueRules} />}
      <Tabs
        key={currentRuleId}
        tabs={tabs}
        onTabClick={updateQueryParams}
        args={{
          currentLanguage: selectedLanguage,
          currentRuleId,
          isModDialogue,
          buildFormType,
          initialValues,
          dialogueId,
        }}
        componentBeforeTitles={
          <Dropdown
            overlay={<Dropdown.MenuOverlay options={actions} />}
            position="right"
            title="Add Output"
            triggerClassName={styles.dropdownButton}
          >
            <Icon component={Plus} />
          </Dropdown>
        }
      />
    </>
  );
}
