import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { DisconnectFacebookPageDocument, type FacebookPageType } from 'frontend/api/generated';
import { Button } from 'frontend/components';
import { useToast } from 'frontend/hooks';

import { removePageFromCache } from '../utils';

type Props = {
  facebookPage: Partial<FacebookPageType>;
  botId: string;
  setRetryDisconnect: React.Dispatch<React.SetStateAction<boolean>>;
};

function DisconnectButton({ facebookPage, botId, setRetryDisconnect }: Props) {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const toast = useToast();
  const [disconnect] = useMutation(DisconnectFacebookPageDocument);

  const onClick = useCallback(async () => {
    setLoading(true);
    const { data } = await disconnect({ variables: { pageId: facebookPage.id!, botId } });

    if (!data || !data.disconnectFacebookPage) {
      return;
    }
    const { shouldRetry, error } = data.disconnectFacebookPage;

    if (shouldRetry) {
      setRetryDisconnect(true);
    } else if (error) {
      toast.error(error);
    } else {
      removePageFromCache(client, botId, facebookPage.id!);
      toast.success('Facebook Messenger disconnected');
    }

    setLoading(false);
  }, [botId, client, disconnect, facebookPage, setRetryDisconnect, toast]);

  return <Button text="Disconnect" onClick={onClick} color="warning" isSubmitting={loading} />;
}

export default DisconnectButton;
