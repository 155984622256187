/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Copy, DoubleChatBubble, GoTo } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import ButtonGroup, { type ButtonGroupButtonType } from 'frontend/components/ButtonGroup/ButtonGroup';
import ImageCarouselPreview from 'frontend/components/ImageCarouselPreview/ImageCarouselPreview';
import MessageClickable from 'frontend/components/MessageClickable/MessageClickable';
import VideoEmbedPreview from 'frontend/components/VideoEmbedPreview/VideoEmbedPreview';
import { useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { camelCaseKeys } from 'frontend/utils';

import styles from './MessageGroup.scss';
import { IS_REVIEW } from '../../hooks/useChatMessages';
import { SENDER_TYPES } from '../../utils/constants';
import type { CompleteChatMessageType } from '../../utils/types';
import Attachment from '../Attachment/Attachment';
import BubbleHeader from '../BubbleHeader/BubbleHeader';
import ChatButton from '../ChatButton/ChatButton';
import Feedback from '../Feedback/Feedback';
import LogForm from '../LogForm/LogForm';
import MessageReply from '../MessageReply/MessageReply';
import MessageReview from '../MessageReview/MessageReview';
import ReplyCandidates from '../ReplyCandidates/ReplyCandidates';

function exchangeTypeInBuild(exchangeType: string | undefined | null): boolean {
  if (!exchangeType) {
    return false;
  }

  return ['usersays', 'greeting', 'fallback', 'system-dialogue'].includes(exchangeType);
}

const goToDialogue = (chatMessage: CompleteChatMessageType, botId: string) => {
  const { fromBot, exchangeType, fromWebhook, exchangeId } = chatMessage;
  if (!exchangeId || fromWebhook || !fromBot || !exchangeTypeInBuild(exchangeType)) return null;

  let url = '';
  if (exchangeType === 'system-dialogue') url = `/workspace/${botId}/build/system-dialogue/${exchangeId}`;
  else if (exchangeType === 'usersays') url = `/workspace/${botId}/build/dialogue/${exchangeId}`;
  else if (exchangeType === 'fallback') url = `/workspace/${botId}/build/fallback/${exchangeId}`;
  else if (exchangeType === 'greeting') url = `/workspace/${botId}/build/greeting/${exchangeId}`;

  return url;
};

interface MessageGroupProps {
  messageGroup: CompleteChatMessageType[];
  onClick: () => void;
  active: boolean;
  hash?: string;
}

const MessageGroup = ({ messageGroup, onClick, active, hash }: MessageGroupProps) => {
  const { botId } = useParams();

  const [showML, setShowML] = useState(false);

  useEffect(() => {
    if (hash) {
      const el = window.document.querySelector(`[data-messageid='${hash}']`);
      if (el) {
        el.scrollIntoView(true);
      }
    }
  }, [hash]);

  const toast = useToast();

  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  const firstMessage = messageGroup[0];

  const replyCandidates = firstMessage?.replyCandidates?.filter((item) => item.selected === false);
  const hasCandidates = replyCandidates && replyCandidates.length > 0;

  const url = goToDialogue(firstMessage!, botId!);

  const getChatMessageHoverActions = useMemo(() => {
    const actions: ButtonGroupButtonType[] = [
      {
        key: 'copy-to-clipboard',
        type: 'button',
        children: <Icon color="gray" component={Copy} width={16} height={16} />,
        props: {
          color: 'white',
          title: 'Copy link to clipboard',
          className: styles.hoverActionButton,
          'data-testid': 'insights-copy-message-link',
          onClick: () => {
            navigator.clipboard.writeText(`${window.env.SITE_URL}${window.location.pathname}#${firstMessage?.id}`);
            toast.success('A link to the message was copied to the clipboard');
          },
        },
      },
    ];

    if (!!url && canViewDialogues) {
      actions.push({
        key: 'go-to-dialogue',
        type: 'button',
        children: <Icon color="gray" component={GoTo} width={16} height={16} />,
        props: {
          color: 'white',
          title: 'Go to dialogue',
          className: styles.hoverActionButton,
          'data-testid': 'insights-go-to-dialogue',
          onClick: () => {
            window.open(url as string, '_blank');
          },
        },
      });
    }

    if (hasCandidates) {
      actions.unshift({
        key: 'potential-candidates',
        type: 'button',
        children: <Icon color="gray" component={DoubleChatBubble} width={16} height={16} />,
        props: {
          color: 'white',
          className: styles.hoverActionButton,
          title: 'Potential candidates',
          onClick: () => {
            setShowML((curr) => !curr);
          },
        },
      });
    }

    return actions;
  }, [url, hasCandidates, canViewDialogues, firstMessage, toast]);

  if (firstMessage?.__typename === 'ChatFeedbackType') {
    return (
      <div className={styles.chatMessageFeedback}>
        <Feedback feedback={firstMessage} />
      </div>
    );
  }

  return (
    <MessageClickable
      dataMessageId={firstMessage?.id}
      onClick={onClick}
      active={active}
      className={styles.chatMessageClickable}
      key={firstMessage?.id}
    >
      <div className={styles.chatMessageHoverActionsWrapper}>
        <ButtonGroup buttons={getChatMessageHoverActions} />
      </div>
      {messageGroup.map((message) => (
        <Fragment key={`chat_message_${message.id}`}>
          <div
            className={cx(styles.chatBubbleWrapper, {
              [styles.fromUser]: message.sender === SENDER_TYPES.USER,
            })}
          >
            <BubbleHeader firstMessage={firstMessage} message={message} />
            <MessageReply message={message} />
          </div>
          {message.videoSource && <VideoEmbedPreview source={message.videoSource} />}
          {message.imageCarousel && (
            <ImageCarouselPreview
              imageCarousel={{
                images: message.imageCarousel.map((image) => ({
                  ...image,
                  buttons: image.buttons?.map((btn) => camelCaseKeys(btn)),
                })),
                size: message.imageCarouselSize as number,
              }}
            />
          )}
          {message.form && message.form !== '{}' && (
            <LogForm formSubmission={message.formSubmission} attachments={message.attachments} form={message.form} />
          )}
          {message.form && isEmpty(JSON.parse(message.form)) && message.attachments.length > 0 && (
            <div
              className={cx(styles.attachments, {
                [styles.attachmentsFromUser]: message.sender === SENDER_TYPES.USER,
              })}
            >
              {message.attachments.map((attachment) => (
                <Attachment key={attachment.id} attachment={attachment} />
              ))}
            </div>
          )}
          {message.buttons?.length > 0 && (
            <div
              className={cx(styles.chatButtonWrapper, {
                [styles.chatButtonWrapperHorizontal]: message.buttonsDisplayOrientation === 'HORIZONTAL',
              })}
            >
              {message.buttons.map((button) => (
                <ChatButton
                  key={button.id}
                  label={button.label}
                  buttonType={button.button_type}
                  contactDetails={button.contact_details}
                  title={button.title}
                  isButtonClick={button.isButtonClick}
                />
              ))}
            </div>
          )}
        </Fragment>
      ))}
      {hasCandidates && showML && (
        <div className={styles.replyCandidate}>
          <ReplyCandidates
            messageId={firstMessage?.id as string}
            languageCode={firstMessage?.chatLanguageCode as string}
            botId={botId!}
          />
        </div>
      )}
      {IS_REVIEW && active && <MessageReview />}
    </MessageClickable>
  );
};

export default MessageGroup;
