import { capitalize, get } from 'lodash';
import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Radio } from 'frontend/components';

import ButtonField from './ButtonField';
import styles from './ButtonForm.scss';
import { BUTTON_CONTACT_DETAILS_TYPES } from '../../constants';

interface Props {
  name: string;
  disabled: boolean;
  localErrors: Record<string, string>;
  localSubmitFailed: boolean;
  onKeyDown: () => void;
  isNew: boolean;
  validators: Record<string, () => string | undefined>;
}

const formatDetailsType = (type) => capitalize(type).replace(/_/g, ' ');

export default function ContactDetails({
  name,
  disabled,
  localErrors,
  localSubmitFailed,
  onKeyDown,
  isNew,
  validators,
}: Props) {
  const { values } = useFormState();
  const { change } = useForm();
  const contactTypeName = `${name}.contactDetails.contactType`;

  useEffect(() => {
    if (!get(values, contactTypeName)) change(contactTypeName, BUTTON_CONTACT_DETAILS_TYPES.EMAIL);
  }, [change, contactTypeName, values]);

  return (
    <div className={styles.contactDetails}>
      <div className={styles.contactDetailsSection}>
        <div>
          <Field
            component={ButtonField}
            name={`${name}.label`}
            placeholder="Contact me by email"
            autoFocus={!isNew}
            validate={isNew ? undefined : validators.label}
            readOnly={disabled}
            label="Button text"
            ariaLabel="Button text"
            localErrors={localErrors}
            localSubmitFailed={localSubmitFailed}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.contactDetailsType}>
          <Field
            name={contactTypeName}
            type="radio"
            component={Radio}
            label={formatDetailsType(BUTTON_CONTACT_DETAILS_TYPES.EMAIL)}
            value={BUTTON_CONTACT_DETAILS_TYPES.EMAIL}
          />
          <Field
            name={contactTypeName}
            type="radio"
            component={Radio}
            label={formatDetailsType(BUTTON_CONTACT_DETAILS_TYPES.PHONE)}
            value={BUTTON_CONTACT_DETAILS_TYPES.PHONE}
          />
        </div>
      </div>
      <Field
        component={ButtonField}
        name={`${name}.contactDetails.label`}
        label="Input field text"
        placeholder="Your email:"
        validate={isNew ? undefined : validators.label}
        readOnly={disabled}
        ariaLabel="Input field text"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
      />
      <Field
        component={ButtonField}
        name={`${name}.contactDetails.placeholder`}
        label="Placeholder"
        placeholder="me@example.com"
        validate={isNew ? undefined : validators.placeholder}
        readOnly={disabled}
        ariaLabel="Placeholder"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
      />
      <Field
        component={ButtonField}
        name={`${name}.contactDetails.editText`}
        label="Edit details button text"
        placeholder="Change"
        validate={isNew ? undefined : validators.editText}
        readOnly={disabled}
        ariaLabel="Edit details button text"
        localErrors={localErrors}
        localSubmitFailed={localSubmitFailed}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
