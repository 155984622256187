import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { useState } from 'react';
import { useFormState } from 'react-final-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { CopyDialogueOutputDocument, DialogueListDocument } from 'frontend/api/generated';
import { Copy } from 'frontend/assets/icons';
import { Select, SelectDialogue } from 'frontend/components';
import Modal from 'frontend/features/Modals/components/Modal/Modal';
import { useBotOrSkill, useLanguages, useMixpanel } from 'frontend/hooks';
import useCurrentLanguage from 'frontend/hooks/useCurrentLanguage';
import { useDialogueRules } from 'frontend/hooks/useDialogueRules';
import type { PartialDialogue } from 'frontend/types/dialogue';

import styles from './CopyOutputModal.scss';
import { GetDialogue } from '../../graphql';

interface Props {
  hide: () => void;
}

export default function CopyOutputModal({ hide }: Props): React.JSX.Element | null {
  const client = useApolloClient();
  const { values } = useFormState();
  const { mixpanel } = useMixpanel();

  const [toDialogueId, setToDialogueId] = useState<string | null>(null);
  const [toRuleId, setToRuleId] = useState<string | null>(null);
  const [toLanguageCode, setToLanguageCode] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const outputType = searchParams.get('tab') || 'reply';
  const { botId = '', dialogueId: fromDialogueId = '' } = useParams();
  const fromRuleId = searchParams.get('rule') || 'defaultRule';
  const [{ selectedLanguage: fromLanguageCode }] = useCurrentLanguage();
  const [fromButtonId, setFromButtonId] = useState(null);

  const filteredButtonsByLanguageAndRule =
    values?.buttons?.filter(
      (button) =>
        button.languageCode === fromLanguageCode && button.rule === (fromRuleId === 'defaultRule' ? null : fromRuleId),
    ) || [];

  const { loading, data } = useQuery(DialogueListDocument, { variables: { botId } });
  const [copyDialogueOutput] = useMutation(CopyDialogueOutputDocument);

  const { dialogueRules: fromDialogueRules } = useDialogueRules({
    botId,
    dialogueId: fromDialogueId,
    language: fromLanguageCode,
  });
  const { dialogueRules: toDialogueRules } = useDialogueRules({
    botId,
    dialogueId: toDialogueId!,
    language: fromLanguageCode,
  });

  const botOrSkillParams = useBotOrSkill();
  const { languagesAndVariants } = useLanguages(botOrSkillParams);

  const mutationVariables = {
    outputType,

    fromDialogueId,
    fromLanguageCode,
    fromRuleId,
    fromButtonId,

    toDialogueId,
    toLanguageCode: toLanguageCode!,
    toRuleId: toRuleId!,
  };

  const handleCopyOutput = async () => {
    mixpanel.track('Copy dialogue output', {
      outputType,
      isAcrossLanguages: fromLanguageCode !== toLanguageCode,
      isAcrossRules: fromRuleId !== toRuleId,
      fromLanguageCode,
      toLanguageCode,
    });

    await copyDialogueOutput({
      variables: mutationVariables,

      refetchQueries: [
        {
          query: GetDialogue,
          variables: { botId, dialogueId: toDialogueId },
        },
      ],
    });
    hide?.();
    setToDialogueId(null);

    client.cache.evict({ id: `DialogueType:${toDialogueId}` });
    client.cache.gc(); // Optionally, run garbage collection to remove any dangling references
  };

  const hasDialogueContext = values?.context && Object.keys(values.context[fromLanguageCode] || {}).length > 0;
  const isOnAdvancedOptions = outputType === 'advanced options';

  if (loading) return null;

  const dialogues: PartialDialogue[] = data?.dialogueList ?? [];
  return (
    <Modal
      icon={Copy}
      title={`Copy ${outputType} to another dialogue`}
      hide={hide}
      onOk={handleCopyOutput}
      onOkText="Copy"
      disabled={!toDialogueId || !toLanguageCode || !toRuleId || (outputType === 'button' && !fromButtonId)}
    >
      <Modal.Content>
        {isOnAdvancedOptions && !hasDialogueContext ? (
          <p>There are no context fields to copy!</p>
        ) : (
          <>
            <p>
              You are going to copy <b>{isOnAdvancedOptions ? 'these context fields' : `this ${outputType}`}</b>{' '}
              (language: <b>{fromLanguageCode}</b>, rule:{' '}
              <b>{fromDialogueRules.find((rule) => rule.id === fromRuleId)?.name || 'Default rule'}</b>) output.
            </p>

            {outputType === 'button' ? (
              <>
                <p>Select which button you want to copy (this won&apos;t delete any button in the target dialogue):</p>
                <div className={styles.fakeButtons}>
                  {filteredButtonsByLanguageAndRule.map((button) => (
                    <button
                      className={cx(styles.fakeButton, {
                        [styles.active]: fromButtonId === button.id,
                      })}
                      type="button"
                      key={button.id}
                      onClick={() => setFromButtonId(fromButtonId === button.id ? null : button.id)}
                    >
                      {button.label}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <p>
                {isOnAdvancedOptions
                  ? "NB: existing context fields on the target dialogue won't be replaced."
                  : `NB: any existing ${outputType} in the target dialogue will be replaced.`}
              </p>
            )}
            <p className="m-t-2">Select a dialogue to copy the output to:</p>
            <SelectDialogue
              meta={{}}
              placeholder="Select a dialogue"
              input={{
                value: toDialogueId!,
                onChange: setToDialogueId,
                name: 'dialogueId',
                onBlur: () => null,
                onFocus: () => null,
              }}
              dialogues={dialogues}
            />
            <p>Select a language to copy the output to:</p>
            <Select
              meta={{}}
              input={{
                value: toLanguageCode!,
                onChange: (e) => setToLanguageCode(e.target.value),
                name: 'languageCode',
                onBlur: () => null,
                onFocus: () => null,
              }}
              fieldColor="mischka"
            >
              <Select.Option key="none" value="" label="Select a language" />
              {languagesAndVariants.map((language) => (
                <Select.Option key={language.code} value={language.code} label={language.name} />
              ))}
            </Select>
            <p>Select a rule to copy the output to:</p>
            <Select
              placeholder="Select a rule"
              meta={{}}
              input={{
                value: toRuleId!,
                onChange: (e) => setToRuleId(e.target.value),
                name: 'ruleId',
                onBlur: () => null,
                onFocus: () => null,
              }}
              fieldColor="mischka"
            >
              <Select.Option key="none" value="" label="Select a rule" />
              <Select.Option key="defaultRule" value="defaultRule" label="Default rule" />
              {toDialogueRules.map((rule) => (
                <Select.Option key={rule.id} value={rule.id!} label={rule.name!} />
              ))}
            </Select>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}
