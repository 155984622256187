import { useQuery } from '@apollo/client';
import { noop, sortBy } from 'lodash';
import { useEffect } from 'react';

import { LibraryDialoguesDocument, LibraryFoldersDocument } from 'frontend/api/generated';
import { isRegularDialogueType } from 'frontend/constants';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { BOTTOM_LEVEL } from '../../constants';
import DialogueList from '../DialogueList';
import FolderItem from '../TopicItem';

function LibraryItemContents({
  selectedLanguage,
  buildIdObject,
  skillId,
  parentDisabled,
  folderId,
  dialogueId,
  dialogueModId,
  dialogueType,
  indentationLevel,
  setShowLoader = noop,
}: {
  selectedLanguage: string;
  buildIdObject: BuildIdObject;
  skillId?: string;
  parentDisabled?: boolean;
  folderId?: string | typeof BOTTOM_LEVEL;
  dialogueId?: string | typeof BOTTOM_LEVEL;
  dialogueType?: DialogueType;
  dialogueModId?: string;
  indentationLevel: number;
  setShowLoader?: (loading: boolean) => void;
}) {
  const buildVariables = { skillId, ...buildIdObject };
  const regular = isRegularDialogueType(dialogueType);

  const parentFolderId = folderId === BOTTOM_LEVEL ? undefined : folderId;
  const { data: subFoldersData, loading: subFoldersLoading } = useQuery(LibraryFoldersDocument, {
    variables: {
      ...buildVariables,
      parentFolderId,
    },
    skip: !regular || !folderId,
  });

  const dialogueTypeVariables = regular ? { regular: true } : { regular: false, dialogueType };
  const parentId = dialogueId === BOTTOM_LEVEL ? undefined : dialogueId;
  const dialogueFolderId = folderId === BOTTOM_LEVEL ? undefined : folderId;
  const { data: dialogueData, loading: dialogueLoading } = useQuery(LibraryDialoguesDocument, {
    variables: { ...buildVariables, ...dialogueTypeVariables, parentId, topicId: dialogueFolderId },
  });

  const modFollowupsConfig = {
    variables: { ...buildIdObject, dialogueModParentId: dialogueModId, regular: true },
    skip: !dialogueModId,
  };
  const { data: modFollowupsData, loading: modFollowupsLoading } = useQuery(
    LibraryDialoguesDocument,
    modFollowupsConfig,
  );

  const loading = subFoldersLoading || dialogueLoading || modFollowupsLoading;
  useEffect(() => {
    setShowLoader(loading);
  }, [loading, setShowLoader]);

  const folders = sortBy(subFoldersData?.folders ?? [], [
    (o) => {
      let name = 'Missing folder';
      if (o.name) {
        name = o.name.toLowerCase();
      }
      return name;
    },
  ]);
  return (
    <>
      {folders.map((folder) => (
        <FolderItem
          key={`library-folder-${folder.id}`}
          topic={folder}
          selectedLanguage={selectedLanguage}
          skillId={skillId}
          parentDisabled={parentDisabled ?? false}
          indentationLevel={indentationLevel}
        />
      ))}
      <DialogueList
        dialogues={modFollowupsData?.dialogues ?? []}
        selectedLanguage={selectedLanguage}
        buildIdObject={buildIdObject}
        parentDisabled={parentDisabled}
        indentationLevel={indentationLevel}
      />
      <DialogueList
        dialogues={dialogueData?.dialogues ?? []}
        selectedLanguage={selectedLanguage}
        buildIdObject={buildIdObject}
        skillId={skillId}
        parentDisabled={parentDisabled}
        parentId={dialogueId}
        indentationLevel={indentationLevel}
      />
    </>
  );
}

export default LibraryItemContents;
