import type { ApolloClient } from '@apollo/client';

import { updateArrayInCache } from 'frontend/api/cacheHelpers';
import {
  type CreateTopicMutation,
  type DeleteTopicMutation,
  type DropFolderInFolderMutation,
  type FolderType,
  LibraryFoldersDocument,
} from 'frontend/api/generated';

type Props = {
  variables: Record<string, unknown>;
  client: ApolloClient<object>;
} & (
  | {
      addedTopic: NonNullable<CreateTopicMutation['createTopic'] | DropFolderInFolderMutation['dropFolderInFolder']>;
      removedTopic?: never;
    }
  | {
      addedTopic?: never;
      removedTopic: NonNullable<DeleteTopicMutation['deleteTopic'] | DropFolderInFolderMutation['dropFolderInFolder']>;
    }
);
export default ({ variables, client, removedTopic, addedTopic }: Props) =>
  updateArrayInCache({
    variables,
    pathToArrayInCache: 'topics',
    query: LibraryFoldersDocument,
    cache: client,
    arrayUpdate: (topics: FolderType[]) =>
      addedTopic ? [...topics, addedTopic] : topics.filter(({ id: topicId }) => topicId !== removedTopic.id),
  });
