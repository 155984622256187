import { FORM_ERROR } from 'final-form';
import { orderBy, set, uniqWith } from 'lodash';
import { isEqual } from 'lodash/fp';

import type { DialogueOutputSlotType } from 'frontend/api/generated';
import { DIALOGUE_TYPES, entityLimits } from 'frontend/constants';
import { handleEditorState, hasSameId } from 'frontend/features/Build/utils';
import { countExpandedSamples } from 'frontend/features/Samples/utils';
import { SCOPE } from 'frontend/features/Slots/constants';
import { allReplySlotsHavePrompts } from 'frontend/features/Slots/utils';
import { hasLanguage } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';

import { BUTTON_TYPES, DEFAULT_RULE_ID } from '../constants';

const { MAX_SAMPLES_PER_DIALOGUE } = entityLimits;

const MAX_PATTERNS = parseInt(window.env?.BUILD_MAX_PATTERNS || '300', 10);

const moreThanOneScopeLastMessage =
  (outputSlots: DialogueOutputSlotType[] = []) =>
  ({ instances }) =>
    instances.filter(({ slot }) => {
      const currentOutputSlot = outputSlots.find(({ slot: outputSlot }) => hasSameId(slot, outputSlot));
      return currentOutputSlot && currentOutputSlot.scope === SCOPE.LAST_MESSAGE && currentOutputSlot.prompt;
    }).length > 1;

// Note: when we set an error that is not directly on a field (or a subfield) we use another name
// (capital; e.g. Sample instead of sample). That way the error is displayed correctly by the
// FormError component but we don't run into issues with final form accessing nonexistent fields

export default ({
    selectedLanguage,
    currentLanguage,
    isModDialogue,
    numberOfPatternsInOtherDialogues = 0,
    isIntentDialogue,
  }) =>
  (values) => {
    const errors: Record<string, unknown> = {};
    const webhookUrlName = isModDialogue ? 'modWebhookUrls' : 'webhookUrls';

    const currentValues = {
      // input - base language dependant
      samples: (values?.samples || []).filter(hasLanguage(currentLanguage)),
      intent: values?.intent || '',
      keywords: values?.keywords?.[currentLanguage] || [],
      patterns: values?.patterns?.[currentLanguage] || [],

      // output - language variant dependant
      isActive: values?.isActive?.[selectedLanguage] || false,
      buttons: (values?.buttons || []).filter(hasLanguage(selectedLanguage)),
      replies: (values?.replies || [])
        .filter(hasLanguage(selectedLanguage))
        .map((reply) => handleEditorState(reply, isIntentDialogue)),
      smartReplies: (values?.smartReplies || [])
        .filter(hasLanguage(selectedLanguage))
        .map((smartReply) => handleEditorState(smartReply, isIntentDialogue)),
      imageCarousels: (values?.imageCarousels || []).filter(hasLanguage(selectedLanguage)),
      video: (values?.videoSources || {})[selectedLanguage],
      outputSlots: (values?.outputSlots || []).filter(
        ({ slot: { languageCode } }) => languageCode === selectedLanguage,
      ),
      [webhookUrlName]: values?.[webhookUrlName]?.[selectedLanguage],
      buildForms: (values?.buildForms || []).filter(hasLanguage(selectedLanguage)),
      dialogueRules: (values?.dialogueRules || []).filter(hasLanguage(selectedLanguage)),
      // eventMessage: (values?.eventMessage || []).filter(hasLanguage(selectedLanguage)),
    };

    const isSamples = values.dialogueType === DIALOGUE_TYPES.SAMPLES;
    const isIntent = values.dialogueType === DIALOGUE_TYPES.INTENT;
    const isKeywords = values.dialogueType === DIALOGUE_TYPES.KEYWORDS;
    const isPatterns = values.dialogueType === DIALOGUE_TYPES.PATTERNS;
    const hasRulesInLanguage = currentValues.dialogueRules.length > 0;

    /* Rule-related stuff validation */
    [undefined, ...currentValues.dialogueRules].forEach((rule) => {
      const ruleButtons = currentValues.buttons.filter(({ rule: r }) => r?.id === rule?.id);

      const submitButtons = ruleButtons.filter((item) => item.buttonType === BUTTON_TYPES.SUBMIT);

      const hasCheckboxOrSliderButton = ruleButtons.find(
        (item) => item.buttonType === BUTTON_TYPES.CHECKBOX || item.buttonType === BUTTON_TYPES.SLIDER,
      );

      const errorButtonKey = rule?.id ? `Buttons.${rule.id}` : `Buttons.${DEFAULT_RULE_ID}`;

      if (hasCheckboxOrSliderButton && submitButtons.length === 0) {
        set(errors, errorButtonKey, 'You must add a button of type Submit when using checkboxes or slider');
      }
      if (!hasCheckboxOrSliderButton && submitButtons.length === 1) {
        set(errors, errorButtonKey, 'You must add at least one checkbox or slider when using button of type Submit');
      }
      if (submitButtons.length > 1 && submitButtons.length !== 0) {
        set(errors, errorButtonKey, 'You can only use one button of type Submit per dialogue');
      }
      if (uniqWith(ruleButtons, isEqual).length !== ruleButtons.length) {
        set(errors, errorButtonKey, 'Two buttons cannot be identical');
      }
      if (hasCheckboxOrSliderButton && submitButtons.length === 1) {
        let foundCheckboxOrSubmit = false;
        let foundSubmit = false;
        const buttons = orderBy(currentValues.buttons, ['index']);
        buttons.forEach((item) => {
          const isCheckboxOrSubmit =
            item.buttonType === BUTTON_TYPES.CHECKBOX || item.buttonType === BUTTON_TYPES.SLIDER;
          const isSubmit = item.buttonType === BUTTON_TYPES.SUBMIT;

          if (isCheckboxOrSubmit) foundCheckboxOrSubmit = true;
          if (!isCheckboxOrSubmit && !isSubmit && foundCheckboxOrSubmit && !foundSubmit) {
            set(
              errors,
              errorButtonKey,
              'Submit, Slider and Checkbox buttons must be grouped together. Other buttons must be placed above or below',
            );
          }
          if (foundSubmit && isCheckboxOrSubmit) {
            set(errors, errorButtonKey, 'Submit button must be placed after the Checkbox or Slider buttons');
          }
          if (!foundSubmit && isSubmit) foundSubmit = true;
        });
      }

      const currentBuildForm = (currentValues.buildForms || [])
        .filter(hasLanguage(selectedLanguage))
        .find(hasRule(rule?.id));

      const errorBuildFormKey = rule?.id ? `BuildForms.${rule.id}` : `BuildForms.${DEFAULT_RULE_ID}`;
      if (currentBuildForm) {
        if (currentBuildForm.fields?.length < 1) {
          set(errors, errorBuildFormKey, 'You need to have at least 1 form field.');
        } else if (
          !currentBuildForm.texts ||
          !currentBuildForm.texts.submitButtonText ||
          !currentBuildForm.texts.cancelButtonText ||
          !currentBuildForm.texts.unansweredText ||
          !currentBuildForm.texts.errorText ||
          !currentBuildForm.texts.cancelText ||
          !currentBuildForm.cancelDialogueId ||
          !currentBuildForm.submitDialogueId
        ) {
          set(errors, errorBuildFormKey, 'You need to fill in all form settings.');
        }
      }
    });

    if (!values.dialogueType) errors.dialogueType = 'Please select a dialogue type';

    if (isSamples && countExpandedSamples(currentValues.samples) > MAX_SAMPLES_PER_DIALOGUE) {
      set(errors, 'Samples', `Each dialogue can have up to ${MAX_SAMPLES_PER_DIALOGUE} expanded samples`);
    }

    if (isPatterns && numberOfPatternsInOtherDialogues + currentValues.patterns.length > MAX_PATTERNS) {
      set(errors, 'Patterns', `Your bot can have up to ${MAX_PATTERNS} patterns per language`);
    }

    if (isIntent && currentValues?.intent?.trim() === '') {
      set(errors, 'Intent', `Please enter an intent`);
    }

    if (!currentValues.isActive) return errors;
    // The errors below are only relevant for active dialogues

    [undefined, ...currentValues.dialogueRules].forEach((rule) => {
      const hasReplies = currentValues.replies.filter((reply) => reply.rule?.id === rule?.id).length > 0;
      const hasSmartReplies =
        currentValues.smartReplies.filter((smartReply) => smartReply.rule?.id === rule?.id).length > 0;
      const hasButtons = currentValues.buttons.filter((button) => button.rule?.id === rule?.id).length > 0;
      const hasImages = currentValues.imageCarousels.filter((image) => image.rule?.id === rule?.id).length > 0;
      const hasBuildForms = currentValues.buildForms.filter((buildForm) => buildForm.rule?.id === rule?.id).length > 0;
      const hasWebhook = Boolean(currentValues[webhookUrlName]?.[rule?.id || DEFAULT_RULE_ID]);
      const hasVideo = Boolean(currentValues.video?.[rule?.id || DEFAULT_RULE_ID]);
      const hasEventMessage = Boolean(currentValues.eventMessage?.[rule?.id || DEFAULT_RULE_ID]);

      const hasAtLeastOneOutput =
        hasReplies ||
        hasSmartReplies ||
        hasButtons ||
        hasImages ||
        hasBuildForms ||
        hasWebhook ||
        hasVideo ||
        hasEventMessage;

      if (!hasAtLeastOneOutput) {
        set(
          errors,
          `${FORM_ERROR}[${rule?.id || DEFAULT_RULE_ID}]`,
          `You need at least one output for this dialogue (${selectedLanguage})${
            hasRulesInLanguage ? ' and its rules' : ''
          }`,
        );
      }
    });

    const hasReplies = currentValues.replies.length > 0;

    if (hasReplies && currentValues.replies.filter(allReplySlotsHavePrompts(currentValues.outputSlots)).length < 1) {
      set(errors, `Slots.${DEFAULT_RULE_ID}`, 'You need a reply without slots or where all slots have prompts');
      set(errors, `Replies.${DEFAULT_RULE_ID}`, 'You need a reply without slots or where all slots have prompts');
    }

    if (currentValues.outputSlots.some(({ required, prompt }) => required && !prompt)) {
      set(errors, `Slots.${DEFAULT_RULE_ID}`, 'Required slots must have a prompt');
    }

    const repliesWithSeveralSlots = currentValues.replies.filter(({ instances = [] }) => instances.length > 1);

    if (repliesWithSeveralSlots.some(moreThanOneScopeLastMessage(currentValues.outputSlots))) {
      const message =
        "There can only be one slot with scope 'Last message' per reply, to avoid an infinite prompt loop. Could the scope 'Followups' work instead?";
      set(errors, `Slots.${DEFAULT_RULE_ID}`, message);
    }

    if (isKeywords && currentValues.keywords.length < 1) {
      set(errors, 'Keywords', 'You need at least one keyword');
    }

    if (isPatterns && currentValues.patterns.length < 1) {
      set(errors, 'Patterns', 'You need at least one regex pattern');
    }

    return errors;
  };
