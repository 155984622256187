import { FORM_ERROR } from 'final-form';
import { capitalize, isEqual, isNil, orderBy, set, uniqWith } from 'lodash';

import { hasLanguage } from 'frontend/utils';
import hasRule from 'frontend/utils/hasRule';

import { findFormByLanguageAndRule } from '../components/Forms/utils/helpers';
import { BUTTON_TYPES, DEFAULT_RULE_ID } from '../constants';

// Note: when we set an error that is not directly on a field (or a subfield) we use another name
// (capital; e.g. Reply instead of reply). That way the error is displayed correctly by the
// FormError component but we don't run into issues with final form accessing nonexistent fields

export default (selectedLanguage: string, currentRuleId?: string) => (values) => {
  const errors = {};

  const currentValues = {
    buttons: (values?.buttons || []).filter(hasLanguage(selectedLanguage)),
    replies: (values?.replies || []).filter(hasLanguage(selectedLanguage)),
    smartReplies: (values?.smartReplies || []).filter(hasLanguage(selectedLanguage)),
    imageCarousels: (values?.imageCarousels || []).filter(hasLanguage(selectedLanguage)),
    video: (values?.videoSources || {})[selectedLanguage],
    webhookUrls: values.webhookUrls?.[selectedLanguage],
    isActive: values?.isActive?.[selectedLanguage] || false,
    buildForms: (values?.buildForms || []).filter(hasLanguage(selectedLanguage)),
    dialogueRules: (values?.dialogueRules || []).filter(hasLanguage(selectedLanguage)),
    eventMessage: (values?.eventMessage || {})[selectedLanguage],
  };

  [undefined, ...currentValues.dialogueRules].forEach((condition) => {
    const conditionButtons = currentValues.buttons.filter(({ conditionId }) => conditionId === condition?.id);

    const submitButtons = conditionButtons.filter((item) => item.buttonType === BUTTON_TYPES.SUBMIT);

    const hasCheckboxOrSliderButton = conditionButtons.find(
      (item) => item.buttonType === BUTTON_TYPES.CHECKBOX || item.buttonType === BUTTON_TYPES.SLIDER,
    );

    const errorButtonKey = condition?.id ? `Buttons.${condition.id}` : `Buttons.${DEFAULT_RULE_ID}`;

    if (hasCheckboxOrSliderButton && submitButtons.length === 0) {
      set(errors, errorButtonKey, 'You must add a button of type Submit when using checkboxes or slider');
    }
    if (!hasCheckboxOrSliderButton && submitButtons.length === 1) {
      set(errors, errorButtonKey, 'You must add at least one checkbox or slider when using button of type Submit');
    }
    if (submitButtons.length > 1 && submitButtons.length !== 0) {
      set(errors, errorButtonKey, 'You can only use one button of type Submit per dialogue');
    }
    // TODO: Is this necessary? The index should always be different.
    if (uniqWith(conditionButtons, isEqual).length !== conditionButtons.length) {
      set(errors, errorButtonKey, 'Two buttons cannot be identical');
    }
    if (hasCheckboxOrSliderButton && submitButtons.length === 1) {
      let foundCheckboxOrSubmit = false;
      let foundSubmit = false;
      const buttons = orderBy(currentValues.buttons, ['index']);
      buttons.forEach((item) => {
        const isCheckboxOrSubmit = item.buttonType === BUTTON_TYPES.CHECKBOX || item.buttonType === BUTTON_TYPES.SLIDER;
        const isSubmit = item.buttonType === BUTTON_TYPES.SUBMIT;

        if (isCheckboxOrSubmit) foundCheckboxOrSubmit = true;
        if (!isCheckboxOrSubmit && !isSubmit && foundCheckboxOrSubmit && !foundSubmit) {
          set(
            errors,
            errorButtonKey,
            'Submit, Slider and Checkbox buttons must be grouped together. Other buttons must be placed above or below',
          );
        }
        if (foundSubmit && isCheckboxOrSubmit) {
          set(errors, errorButtonKey, 'Submit button must be placed after the Checkbox or Slider buttons');
        }
        if (!foundSubmit && isSubmit) foundSubmit = true;
      });
    }

    const currentBuildForm = (currentValues.buildForms || [])
      .filter(hasLanguage(selectedLanguage))
      .find(hasRule(condition?.id));

    const errorBuildFormKey = condition?.id ? `BuildForms.${condition.id}` : `BuildForms.${DEFAULT_RULE_ID}`;
    if (currentBuildForm) {
      if (currentBuildForm.fields.length < 1) {
        set(errors, errorBuildFormKey, 'You need to have at least 1 form field.');
      } else if (
        !currentBuildForm.texts ||
        !currentBuildForm.texts.submitButtonText ||
        !currentBuildForm.texts.cancelButtonText ||
        !currentBuildForm.texts.unansweredText ||
        !currentBuildForm.texts.errorText ||
        !currentBuildForm.texts.cancelText ||
        !currentBuildForm.cancelDialogueId ||
        !currentBuildForm.submitDialogueId
      ) {
        set(errors, errorBuildFormKey, 'You need to fill in all form settings.');
      }
    }
  });

  if (currentValues.buildForms?.length > 0) {
    const currentForm = findFormByLanguageAndRule(currentValues.buildForms, selectedLanguage, currentRuleId);

    if (currentForm) {
      if (currentForm.fields.length < 1) {
        set(errors, 'Form', 'You need to have at least 1 form field.');
      } else if (
        !currentForm.texts ||
        !currentForm.texts.submitButtonText ||
        !currentForm.texts.cancelButtonText ||
        !currentForm.texts.unansweredText ||
        !currentForm.texts.errorText ||
        !currentForm.texts.cancelText ||
        !currentForm.cancelDialogueId ||
        !currentForm.submitDialogueId
      ) {
        set(errors, 'Form', 'You need to fill in all form settings.');
      }

      let hasPromptedError = false;

      currentForm.fields.forEach((field) => {
        const minimumValidator = field.validators.find((validator) => !isNil(validator.minimum))?.minimum;
        const maximumValidator = field.validators.find((validator) => !isNil(validator.maximum))?.maximum;

        if (minimumValidator && maximumValidator && minimumValidator >= maximumValidator && !hasPromptedError) {
          set(errors, 'Form', `Minimum cannot be greater than maximum in your ${capitalize(field.inputType)} field.`);
          hasPromptedError = true;
        }

        if (
          field.attributes?.defaultValue &&
          maximumValidator &&
          parseInt(field.attributes?.defaultValue, 10) > maximumValidator &&
          hasPromptedError
        ) {
          set(
            errors,
            'Form',
            `Default value cannot be greater than maximum in your ${capitalize(field.inputType)} field.`,
          );
          hasPromptedError = true;
        }

        if (
          field.attributes?.defaultValue &&
          maximumValidator &&
          field.attributes.defaultValue &&
          parseInt(field.attributes?.defaultValue, 10) < maximumValidator &&
          hasPromptedError
        ) {
          set(
            errors,
            'Form',
            `Default value cannot be less than maximum in your ${capitalize(field.inputType)} field.`,
          );
          hasPromptedError = true;
        }

        if (
          maximumValidator &&
          field.attributes?.step &&
          field.attributes.step > maximumValidator &&
          hasPromptedError
        ) {
          set(errors, 'Form', `Step cannot be greater than maximum in your ${capitalize(field.inputType)} field.`);
          hasPromptedError = true;
        }

        if (
          maximumValidator &&
          field.attributes?.step &&
          field.attributes.step < maximumValidator &&
          hasPromptedError
        ) {
          set(errors, 'Form', `Step cannot be less than maximum in your ${capitalize(field.inputType)} field.`);
          hasPromptedError = true;
        }

        if (
          field.attributes?.defaultValue &&
          field.attributes?.step &&
          parseInt(field.attributes?.defaultValue, 10) % field.attributes.step &&
          hasPromptedError
        ) {
          set(
            errors,
            'Form',
            `Default value should be dividable to step in your ${capitalize(field.inputType)} field.`,
          );
        }
      });
    }
  }

  if (!currentValues.isActive) return errors;

  /* The errors below are only relevant for active dialogues */

  [undefined, ...currentValues.dialogueRules].forEach((rule) => {
    const hasReplies = currentValues.replies.filter((reply) => reply.rule?.id === rule?.id).length > 0;
    const hasSmartReplies =
      currentValues.smartReplies.filter((smartReply) => smartReply.rule?.id === rule?.id).length > 0;
    const hasButtons = currentValues.buttons.filter((button) => button.rule?.id === rule?.id).length > 0;
    const hasImages = currentValues.imageCarousels.filter((image) => image.rule?.id === rule?.id).length > 0;
    const hasBuildForms = currentValues.buildForms.filter((buildForm) => buildForm.rule?.id === rule?.id).length > 0;
    const hasWebhook = Boolean(currentValues.webhookUrls?.[rule?.id || DEFAULT_RULE_ID]);
    const hasVideo = Boolean(currentValues.video?.[rule?.id || DEFAULT_RULE_ID]);
    const hasEventMessage = Boolean(currentValues.eventMessage?.[rule?.id || DEFAULT_RULE_ID]);

    const hasAtLeastOneOutput =
      hasReplies ||
      hasSmartReplies ||
      hasButtons ||
      hasImages ||
      hasBuildForms ||
      hasWebhook ||
      hasVideo ||
      hasEventMessage;

    if (!hasAtLeastOneOutput) {
      set(
        errors,
        `${FORM_ERROR}[${rule?.id || DEFAULT_RULE_ID}]`,
        `You need at least one output for this dialogue (${selectedLanguage})`,
      );
    }
  });

  return errors;
};
