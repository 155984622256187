import { useApolloClient, useMutation } from '@apollo/client';
import { capitalize } from 'lodash';

import { Button } from 'frontend/components';
import { GetDialogue } from 'frontend/features/Build/graphql';
import type { LibraryDialogue } from 'frontend/features/Library/components/DialogueItem/DialogueItem';
import { DIALOGUE_LABEL_COLORS } from 'frontend/features/Library/constants';
import { UPDATE_COLOR_LABEL } from 'frontend/features/Library/mutations';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import styles from './UpdateColorLabel.scss';

type Props = {
  dialogue: LibraryDialogue;
  isSubscription: boolean;
  skillId?: string;
  close?: () => void;
};

function UpdateColorLabel({ dialogue, isSubscription, skillId, close: closeOverlay }: Props) {
  const client = useApolloClient();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { buildIdObject }: BotOrSkill = useBotOrSkill();
  const [updateColorLabel] = useMutation(UPDATE_COLOR_LABEL);

  const onClick = async (color) => {
    const variables: Record<string, unknown> = {
      ...buildIdObject,
      languageCode: currentLanguage,
      color,
      dialogueId: dialogue.id,
    };

    if (isSubscription) {
      variables.skillId = skillId;
      await updateColorLabel({ variables });

      client.query({
        query: GetDialogue,
        variables: { ...buildIdObject, skillId, dialogueId: dialogue.id },
        fetchPolicy: 'network-only',
      });

      return;
    }

    const colorLabel = { ...dialogue.colorLabel, [currentLanguage]: color };
    const optimisticResponse = {
      updateColorLabel: { id: dialogue.id, colorLabel, __typename: dialogue.__typename },
    };

    await updateColorLabel({ variables, optimisticResponse });
    closeOverlay?.();
  };

  return (
    <div className={styles.container}>
      {Array.from(DIALOGUE_LABEL_COLORS).map(([colorName, colorCode]) => (
        <Button
          key={colorName}
          className={styles.colorButton}
          style={{ background: colorCode }}
          flat
          onClick={() => onClick(colorName)}
          aria-label={`${capitalize(colorName)} button`}
        />
      ))}
    </div>
  );
}

export default UpdateColorLabel;
