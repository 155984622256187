import { useEffect } from 'react';

import LoadingButton from './LoadingButton';

type Props = { retryDisconnect: () => void };

function RetryButton({ retryDisconnect }: Props) {
  useEffect(() => {
    retryDisconnect();
  }, [retryDisconnect]);

  return <LoadingButton />;
}

export default RetryButton;
